import React, { useEffect, useState } from "react";
import "./menuComponent.scss";
import {
  IMGLogo,
  ImgChatIcon,
  ImgFilesSm,
  IMGDatabaseIconBlue,
  IMGSmallWooCommerceIcon,
  IMGDropdownArrow,
  ImgWooSm,
  IMGCreateIcon,
  IMGSearchIcon,
  IMGRightArrow,
  ImgUploadFile,
  IMGGorillaLogo,
  IMGNewText,
  IMGGorillaMenuIcon,
  IMGAuthorizeNetMenuIcon,
  IMGGreenCrossIcon,
  IMGDisconnectWooIcon,
  IMGDisconnectAuthorizeIcon,
  IMGDisconnectgorrilaIcon,
  IMGDisconnectFileIcon,
} from "../images";
import ChatScreen from "../query/chatScreen";
import useQuery from "../hooks/useQuery";
import useFile from "../hooks/useFile";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  dashboard,
  dataSources,
  query,
  uploadedFiles,
  chatHistory,
} from "../routes/routes";
import AccountMenu from "../commonComponent/accountMenu";
import DataSources from "../query/dataSources";
import PendingTransactionPopup from "../query/pendingTransactionPopup";
// import DateTimeSelectComponent from "../query/connectWithDataSources/dateTimeSelectComponent";
import "../query/connectWithDataSources/connectWithComponent.scss";
import useWooCommerce from "../hooks/useWooCommerce";
import NewChatScreen from "../commonComponent/newChatScreen/NewChatScreen";
import UploadedFile from "../query/uploadedFileV2";
import LandingPage from "../query/landingPage";
import { useDispatch, useSelector } from "react-redux";
import DateFilterWooCommerce from "../query/connectWithDataSourcesV2/dateFilterWooCommerce";
import SyncWooCommercePopup from "../query/connectWithDataSourcesV2/syncWooCommercePopup";
import GpModal from "../commonComponent/gpModal/GpModal";
import NewUserModal from "../commonComponent/gpModal/newUser/NewUserModal";
import GpLinkedChatFilterEmbedScreen from "./gorrilapay/GpLinkedChatFilterEmbedScreen";
import AuthorizeDotNet from "./authorizeDotNet/AuthorizeDotNet";
import LinkedAuthorizeDotEmbedScreen from "./authorizeDotNet/LinkedAuthorizeDotEmbedScreen";
import {
  setDataSourceName,
  setTotalChat,
  SetAuthorise_net_integration_id,
  setFirstChat,
  setAllChat,
} from "../utils/dataSourceSlice";
import { setFileList } from "../utils/fileSlice";
import { Alert, Snackbar } from "@mui/material";
import ChatHistory from "../query/chatHistory";
import ChatViaFileEmbedScreen from "./chatViaFile/chatViaFileEmbedScreen";
import {
  PRODUCT_TOUR_KEY,
  UploadFirstFileTourScreen,
  gp_redirection_url,
  introTourScreen,
  searchchatTourScreen,
  sideBarchatTourScreen,
  startNewchatTourScreen,
  uploadTourScreen,
} from "../config";
import DashboardTour from "./dashboardTour";

const MenuComponent = ({
  showDateTimeComponent = false,
  setSyncDays,
  syncDays = "",
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
  const [isDataSourceOpen, setIsDataSourceOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [chatList, setChatList] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
  const [selectedChat, setSelectedChat] = useState();
  const [fileList, setFilesList] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
  const [isHovered, setIsHovered] = useState(false);
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isChatsDropdownOpen, setIsChatsDropdownOpen] = useState(true);
  const [fileData, setFileData] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [menuItemSelected, setMenuItemSelected] = useState("");
  const [open, setOpen] = useState(false);
  const [showSyncComponent, setShowSyncComponent] = useState(true);
  const [isChatScreen, setIsChatScreen] = useState(false);
  const [showDateFliter, setShowDateFliter] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [input, setInput] = useState("");
  const [isRefreshChat, setIsRefreshChat] = useState(false);
  const [filteredChatList, setFilteredChatList] = useState(
    chatList?.results || []
  );
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorSnackbarMsg, setErrorSnackbarMsg] = useState("");
  const [isNavigating, setIsNavigating] = useState(false);
  const [snackbarErrorSeverity, setSnackbarErrorSeverity] = useState("error");
  const [isChatCreated, setIsChatCreated] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(-1); // -1 for intro, 0 for the first step
  const [currentStepIndexforUploadFiles, setCurrentStepIndexforUploadFiles] =
    useState(0);
  const [currentStepForDatasource, setCurrentStepForDataSource] = useState(0);
  const [currentStepForDeleteFile, setCurrentStepForDeleteFile] = useState(0);
  const [currentStepForChatHistory, setCurrentStepForChatHistory] = useState(0);
  const { getAllChats, getChatById } = useQuery();
  const { getAllFiles } = useFile();
  // const datasourceId = useParams();
  const dispatch = useDispatch();
  const { storeurl } = useParams();
  const { gorrilapayintegrationId } = useParams();
  const { authorizedotneintegrationId } = useParams();
  const dataSourceName = useSelector(
    (state) => state.dataSourceSlice.first_chat
  );

  const resetProductTour = () => {
    setCurrentStepIndex(-1);
    setCurrentStepIndexforUploadFiles(0);
    setCurrentStepForDataSource(0);
    setCurrentStepForDeleteFile(0);
    setCurrentStepForChatHistory(0);
  };

  const allChats = useSelector((state) => state.dataSourceSlice.total_chat);

  useEffect(() => {
    filterChats(input);
  }, [input, chatList?.results, allChats, isChatsDropdownOpen]);

  const uncheckAll = () => {
    setIsChatOpen(false);
    setIsUploadOpen(false);
    setShowPopup(false);
    setIsChatHistoryOpen(false);
  };

  useEffect(() => {
    if (currentPath === "/uploadedFiles") {
      setMenuItemSelected("upload");
    } else if (currentPath === "/chatHistory") {
      setMenuItemSelected("chatHistory");
    } else {
      setMenuItemSelected("chat");
    }
  }, [currentPath]);

  const getSteps = (info) => {
    switch (info) {
      case "currentStepIndex":
        return 3;
      case "currentStepForChatHistory":
        return 2;
      case "currentStepForDatasource":
        return 6;
      default:
        return 0;
    }
  };

  const handleMenuClick = (fieldName) => {
    switch (fieldName) {
      case "chat":
        uncheckAll();
        setIsChatOpen(true);
        navigate(query);
        break;
      case "upload":
        uncheckAll();
        setIsUploadOpen(true);
        navigate(uploadedFiles);
        break;
      case "datasource":
        // uncheckAll();
        // setIsDataSourceOpen(true);
        // setShowPopup(!showPopup);
        navigate(dataSources);
        break;
      case "chatHistory":
        uncheckAll();
        setIsChatHistoryOpen(true);
        navigate(chatHistory);
        break;
      default:
        return null;
    }
  };

  const handlePopoverOpen = (event, index, item) => {
    setAnchorEl(event.currentTarget);
    setHoveredIndex(index);
    setFileData(item);
  };

  const showErrorSnackbar = (message, severity) => {
    setErrorSnackbarMsg(message);
    setSnackbarErrorSeverity(severity);
    setOpenErrorSnackbar(true);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredIndex(null);
  };

  const onChatClick = (item) => {
    dispatch(setAllChat(item));
    setSelectedChat(item);
    if (!item?.datasource_integration_id) {
      navigate(`${query}/${item.id}`);
    } else {
      navigate(
        `/${"queries"}${`/${item?.datasource_integration_id}`}/${item.id}`
      );
    }
  };

  const onChatCreated = (item) => {
    setChatList({ ...chatList, results: [item, ...chatList?.results] });
  };

  const onSelectChat = async (chatId) => {
    // console.log("chatId", chatId);
    // const response = await getChatById(chatId);
    // if (response === "error") {
    //   setOpen(true);
    // } else if (response) {
    //   setSelectedChat(response.data);
    //   setOpen(false);
    // }
  };

  const getSuperScriptIcon = (item) => {
    const { datasource_status } = item;
    // const { files=[] } = item;
    // const [{status}] = files;
    // const { files: [{ status }] } = item;
    // const status = statusss;
    const { files } = item;
    const status = files[0]?.status;

    try {
      if (item?.datasource_identifier) {
        switch (item?.datasource_identifier) {
          case "GORILLAPAY":
            return (
              <img
                src={
                  datasource_status === "DELETED"
                    ? IMGDisconnectgorrilaIcon
                    : IMGGorillaMenuIcon
                }
                style={{
                  top: "-3px",
                  height: "50px",
                  width: "41px",
                  left: "0px",
                }}
                alt=""
              />
            );
          case "AUTHORIZE_NET":
            return (
              <img
                src={
                  datasource_status === "DELETED"
                    ? IMGDisconnectAuthorizeIcon
                    : IMGAuthorizeNetMenuIcon
                }
                style={{ top: "6px", left: "3px" }}
                alt=""
              />
            );
          case "WOOCOMMERCE":
            return (
              <img
                src={
                  datasource_status === "DELETED"
                    ? IMGDisconnectWooIcon
                    : ImgWooSm
                }
                style={{
                  top: "14px",
                  height: "19px",
                  width: "28px",
                  left: "5px",
                }}
                alt=""
              />
            );
        }
      } else {
        return (
          <>
            {console.log("Invalid", )}
            <img src={status === "DELETED"?IMGDisconnectFileIcon:ImgChatIcon} style={{ top: "6px", left: "3px" }} alt="" />
          </>
        );
      }
    } catch {
      // Eating up here
    }
  };

  useEffect(() => {
    const getChatListData = async () => {
      try {
        const chatResponse = await getAllChats();
        setChatList(chatResponse?.data);
        dispatch(setTotalChat(chatResponse?.data?.results));
        const index = chatResponse?.data?.results?.findIndex(
          (item) => item.datasource_identifier === "AUTHORIZE_NET"
        );
        dispatch(
          SetAuthorise_net_integration_id(
            chatResponse?.data?.results[index]?.datasource_integration_id
          )
        );
        if (chatResponse?.data?.results.length > 0) {
          dispatch(setFirstChat(chatResponse?.data?.results[0]));
        }
        const fileResponse = await getAllFiles();
        setFilesList(fileResponse?.data);
        dispatch(setFileList(fileResponse?.data?.results));
      } catch (error) {
        showErrorSnackbar(
          "Unexpected error occurred. Please try again after sometime.",
          "error"
        );
      }
    };
    getChatListData();
    // const intervalId = setInterval(getChatListData, 8000);
    // return () => clearInterval(intervalId);
  }, [dispatch, isRefreshChat]);

  useEffect(() => {
    uncheckAll();
    if (currentPath === uploadedFiles) {
      setIsUploadOpen(true);
    } else if (currentPath === chatHistory) {
      setIsChatHistoryOpen(true);
    } else {
      setIsChatOpen(true);
    }
  }, [currentPath]);

  const chatsClickHandler = () => {
    if (allChats?.length > 0) {
      setIsChatsDropdownOpen(!isChatsDropdownOpen);
    }
  };

  const handleChangeDataSource = () => {
    if (dataSourceName) {
      switch (dataSourceName?.datasource_identifier) {
        case "GORILLAPAY":
          return navigate(
            `/gorrilapay/${dataSourceName?.datasource_integration_id}`
          );
        case "WOOCOMMERCE":
          return navigate(
            `/woocommerce-sync/${dataSourceName?.datasource_integration_id}`
          );
        case "AUTHORIZE_NET":
          return navigate(
            `/authorizedotne/${dataSourceName?.datasource_integration_id}`
          );
        default:
          return navigate("/newChat");
      }
    }
  };

  const handleSearchIconClick = () => {
    setSearchMode(true);
  };
  const clearSearchInput = () => {
    setInput("");
    setSearchMode(false);
  };
  const filterChats = (input) => {
    if (!input) {
      setFilteredChatList(allChats || []);
    } else {
      const lowercasedInput = input.toLowerCase();
      const filtered = (chatList?.results || []).filter((item) =>
        item?.title.toLowerCase().includes(lowercasedInput)
      );
      setFilteredChatList(filtered);
    }
  };
  const highlightText = (text, searchTerm) => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    let parts = text.split(regex);

    return parts.map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <strong key={i} style={{ color: "black" }}>
          {part}
        </strong>
      ) : (
        part
      )
    );
  };
  const handleSearchAction = () => {
    if (searchMode) {
      clearSearchInput();
    } else {
      handleChangeDataSource();
    }
  };
  const getMenuItemStyle = () => {
    return searchMode ? { backgroundColor: "rgba(62, 105, 105, 0.13)" } : {};
  };
  const renderComponent = () => {
    if (currentPath === "/newChat") {
      return <ChatViaFileEmbedScreen />;
    } else if (
      storeurl &&
      currentPath.startsWith("/dashboard") &&
      showSyncComponent
    ) {
      return (
        <SyncWooCommercePopup
          storeurl={storeurl}
          handleClose={() => setShowSyncComponent(false)}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          steps={getSteps("currentStepIndex")}
        />
      );
    } else if (isChatOpen && !showDateTimeComponent) {
      return (
        <ChatScreen
          chatInfo={selectedChat}
          onChatCreated={onChatCreated}
          onDeselectChat={() => setSelectedChat(null)}
          onSelectChat={onSelectChat}
          isHovered={isHovered}
          fileData={fileData}
          setIsChatCreated={isChatCreated}
          // datasourceId={datasourceId}
          syncDays={syncDays}
          handleMenuClick={() => handleMenuClick("datasource")}
          showDateFliter={showDateFliter}
          setShowDateFliter={setShowDateFliter}
          isNavigating={isNavigating}
          setIsNavigating={setIsNavigating}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          currentStepForDatasource={currentStepForDatasource}
          setCurrentStepForDataSource={setCurrentStepForDataSource}
        />
      );
    } else if (currentPath === "/uploadedFiles") {
      return (
        <UploadedFile
          files={fileList}
          setIsRefreshChat={setIsRefreshChat}
          currentStepIndexforUploadFiles={currentStepIndexforUploadFiles}
          setCurrentStepIndexforUploadFiles={setCurrentStepIndexforUploadFiles}
          currentStepForDeleteFile={currentStepForDeleteFile}
          setCurrentStepForDeleteFile={setCurrentStepForDeleteFile}
        />
      );
    } else if (currentPath === "/chatHistory") {
      return (
        <ChatHistory
          chatList={chatList}
          setIsChatsDropdownOpen={setIsChatsDropdownOpen}
          isChatsDropdownOpen={isChatsDropdownOpen}
          currentStepForChatHistory={currentStepForChatHistory}
          setCurrentStepForChatHistory={setCurrentStepForChatHistory}
          steps={getSteps("currentStepForChatHistory")}
        />
      );
    } else if (currentPath === "/dataSources") {
      return <DataSources />;
    } else if (
      showDateTimeComponent &&
      currentPath.startsWith("/woocommerce-sync")
    ) {
      return (
        <div className="connect-with-container">
          <DateFilterWooCommerce />
        </div>
      );
    } else if (gorrilapayintegrationId && gorrilapayintegrationId.length > 10) {
      return (
        <GpLinkedChatFilterEmbedScreen
          gorrilapayintegrationId={gorrilapayintegrationId}
        />
      );
    } else if (authorizedotneintegrationId) {
      return (
        <LinkedAuthorizeDotEmbedScreen
          authorizedotneintegrationId={authorizedotneintegrationId}
        />
      );
    } else {
      return null;
    }
  };
  const handleGpRedirection = () => {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    const email = localStorage.getItem("USER_EMAIL");
    const is_merchant_exist = localStorage.getItem("is_merchant_exist");
    const user_role = localStorage.getItem("user_role");
    const data = {
      accessToken,
      refreshToken,
      email,
      is_merchant_exist,
      user_role,
    };
    const encodeObjectToBase64 = (obj) => {
      const jsonString = JSON.stringify(obj);
      const base64String = btoa(jsonString);
      return encodeURIComponent(base64String);
    };
    const encodedParams = encodeObjectToBase64(data);
    const redirectionUrlWithParams = `${gp_redirection_url}?data=${encodedParams}`;
    window.open(redirectionUrlWithParams, "_blank");
  };

  return (
    <>
      {/* {currentStepIndex === -1 && (
        <DashboardTour
          currentStepInfo={introTourScreen}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          steps={getSteps("currentStepIndex")}
          flowName={PRODUCT_TOUR_KEY.show_product_tour}
        />
      )} */}
      <div className="query-component-container">
        <div className="menu">
          <div
            className="logo-container"
            onClick={() => {
              setIsNavigating(true);
              navigate("/dashboard");
            }}
          >
            <img src={IMGLogo} alt="xails-logo" className="logo" />
          </div>
          <div className="menu-item-container">
            <div
              className={
                menuItemSelected === "chat" ? "menu-item-select" : "menu-item "
              }
              style={getMenuItemStyle()}
            >
              <div className="chats-header">
                {searchMode ? (
                  <input
                    type="text"
                    className="search-input"
                    autoFocus
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                  />
                ) : (
                  <>
                    <div
                      className={
                        allChats?.length > 0 ? "chats-number" : "chats"
                      }
                      onClick={chatsClickHandler}
                    >
                      {allChats &&
                        Array.isArray(allChats) &&
                        allChats?.length > 0 && (
                          <img
                            src={IMGDropdownArrow}
                            className={
                              isChatsDropdownOpen
                                ? "dropdown-arrow"
                                : "dropdown-arrow-open"
                            }
                          />
                        )}
                      Chats
                    </div>
                    {allChats &&
                      Array.isArray(allChats) &&
                      allChats?.length > 0 && (
                        <>
                          {" "}
                          <span className="chat-content">
                            ({allChats.slice(0, 20)?.length})
                          </span>
                        </>
                      )}
                  </>
                )}
                <div className="search">
                  {allChats &&
                  Array.isArray(allChats) &&
                  allChats?.length > 0 ? (
                    <>
                      <span
                        className="search-icon"
                        onClick={handleSearchIconClick}
                      >
                        <img src={IMGSearchIcon} />
                      </span>
                      <span onClick={handleSearchAction}>
                        <img
                          src={searchMode ? IMGGreenCrossIcon : IMGCreateIcon}
                        />
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            {(isChatsDropdownOpen || searchMode) && (
              <div className="chat-list">
                <ul>
                  {filteredChatList &&
                    Array.isArray(filteredChatList) &&
                    filteredChatList?.length > 0 &&
                    filteredChatList.slice(0, 20).map((item, index) => {
                      return (
                        <>
                          <li
                            key={item.id}
                            onClick={() => onChatClick(item)}
                            aria-owns={
                              hoveredIndex === index
                                ? "mouse-over-popover"
                                : undefined
                            }
                            aria-haspopup="true"
                            onMouseEnter={(e) =>
                              handlePopoverOpen(e, index, item)
                            }
                            className={`${
                              hoveredIndex === index ? "hovered" : ""
                            } 
                          ${
                            selectedChat && selectedChat.id === item.id
                              ? "selected"
                              : ""
                          }`}
                            onMouseLeave={handlePopoverClose}
                          >
                            {getSuperScriptIcon(item)}
                            <span style={{ paddingLeft: "2.8em" }}>
                              {highlightText(
                                item?.title.length > 30
                                  ? `${item?.title.slice(0, 30)}...`
                                  : item?.title,
                                input
                              )}
                            </span>
                          </li>
                          {hoveredIndex === index && (
                            <PendingTransactionPopup
                              fileData={fileData}
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={handlePopoverClose}
                              fileList={fileList}
                              item={item}
                            />
                          )}
                        </>
                      );
                    })}
                </ul>
                {filteredChatList && filteredChatList.length > 0 && (
                  <div
                    className="view-all"
                    onClick={() => handleMenuClick("chatHistory")}
                  >
                    <span className="view-text">View all</span>
                    <img src={IMGRightArrow} className="view-img" />
                  </div>
                )}
              </div>
            )}
            <div className="divider"></div>
            <div
              onClick={() => handleMenuClick("upload")}
              className={
                isUploadOpen ? "menu-upload-item-select" : "menu-upload-item"
              }
            >
              <img src={ImgUploadFile} />
              Uploaded Files
            </div>
            <div className="divider"></div>
            <div
              style={{ position: "fixed", bottom: "30px" }}
              onClick={handleGpRedirection}
            >
              <img src={IMGGorillaLogo} />
              <img
                style={{ position: "fixed", bottom: "60px", left: "56px" }}
                src={IMGNewText}
              />
            </div>
          </div>
          {currentStepForDatasource === 2 && (
            <div style={{ position: "absolute", top: "-135px", left: "480px" }}>
              <DashboardTour
                currentStepInfo={sideBarchatTourScreen}
                currentStepIndex={currentStepForDatasource}
                setCurrentStepIndex={setCurrentStepForDataSource}
                steps={getSteps("currentStepForDatasource")}
                flowName={PRODUCT_TOUR_KEY.show_product_tour}
              />
            </div>
          )}
          {currentStepForDatasource === 3 && (
            <div style={{ position: "absolute", top: "-135px", left: "480px" }}>
              <DashboardTour
                currentStepInfo={startNewchatTourScreen}
                currentStepIndex={currentStepForDatasource}
                setCurrentStepIndex={setCurrentStepForDataSource}
                steps={getSteps("currentStepForDatasource")}
                flowName={PRODUCT_TOUR_KEY.show_product_tour}
              />
            </div>
          )}
          {currentStepForDatasource === 4 && (
            <div style={{ position: "absolute", top: "-135px", left: "480px" }}>
              <DashboardTour
                currentStepInfo={searchchatTourScreen}
                currentStepIndex={currentStepForDatasource}
                setCurrentStepIndex={setCurrentStepForDataSource}
                steps={getSteps("currentStepForDatasource")}
                flowName={PRODUCT_TOUR_KEY.show_product_tour}
              />
            </div>
          )}
          {currentStepForDatasource === 5 && (
            <div style={{ position: "absolute", left: "480px" }}>
              <DashboardTour
                currentStepInfo={uploadTourScreen}
                currentStepIndex={currentStepForDatasource}
                setCurrentStepIndex={setCurrentStepForDataSource}
                steps={getSteps("currentStepForDatasource")}
                flowName={PRODUCT_TOUR_KEY.show_product_tour}
              />
            </div>
          )}
        </div>
        <div className="content">
          <div className="content-header">
            <AccountMenu
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              currentStepIndexforUploadFiles={currentStepIndexforUploadFiles}
              setCurrentStepIndexforUploadFiles={
                setCurrentStepIndexforUploadFiles
              }
              currentStepForDatasource={currentStepForDatasource}
              setCurrentStepForDataSource={setCurrentStepForDataSource}
              currentStepIndex={currentStepIndex}
              setCurrentStepIndex={setCurrentStepIndex}
              handleOpen={() => {
                setShowDateFliter(true);
              }}
              setToggleChatDropdown={setIsChatsDropdownOpen}
              resetProductTour={resetProductTour}
            />
          </div>
          {renderComponent()}

          {/* 
         previous implements of datasource popup */}

          {/* {isChatOpen && !showDateTimeComponent && (
          <ChatScreen
            chatInfo={selectedChat}
            onChatCreated={onChatCreated}
            onDeselectChat={() => setSelectedChat(null)}
            onSelectChat={onSelectChat}
            isHovered={isHovered}
            fileData={fileData}
            // datasourceId={datasourceId}
            syncDays={syncDays}
            handleMenuClick={() => handleMenuClick("datasource")}
            showDateFliter={showDateFliter}
            setShowDateFliter={setShowDateFliter}
          />
        )}
        {currentPath === "/uploadedFiles" && <UploadedFile files={fileList} />}
        {currentPath === "/dataSources" && <DataSources />}
        {currentPath === "/chatHistory" && <ChatHistory files={chatList} />}
        {showDateTimeComponent &&
          currentPath.startsWith("/woocommerce-sync") && (
            <div className="connect-with-container">
              {" "}
              <DateFilterWooCommerce />
            </div>
          )}
        {gorrilapayintegrationId && gorrilapayintegrationId.length > 10 && (
          <GpLinkedChatFilterEmbedScreen
            gorrilapayintegrationId={gorrilapayintegrationId}
          />
        )}
        {storeurl &&
          currentPath.startsWith("/dashboard") &&
          showSyncComponent && (
            <SyncWooCommercePopup
              storeurl={storeurl}
              handleClose={() => setShowSyncComponent(false)}
            />
          )}
        {authorizedotneintegrationId && (
          <LinkedAuthorizeDotEmbedScreen
            authorizedotneintegrationId={authorizedotneintegrationId}
          />
        )} */}
        </div>
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseErrorSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseErrorSnackbar}
            severity={snackbarErrorSeverity}
            variant="filled"
            sx={{
              width: "100%",
              bgcolor: "#FFECEF",
              color: "#3B3939",
              "& .MuiAlert-icon": {
                color: "#CA6069",
              },
              fontFamily: "Chivo",
              fontWeight: 400,
              fontSize: "16px",
            }}
          >
            {errorSnackbarMsg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default MenuComponent;
