import { useEffect } from "react";

const useInsideHover = (ref, isVisible, setIsVisible) => {
  useEffect(() => {
    const handleMouseOver = (event) => {
      if (!isVisible && ref.current && ref.current.contains(event.target)) {
        setIsVisible(true);
      }
    };

    document.addEventListener("mouseover", handleMouseOver);
    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
    };
  }, [isVisible, ref, setIsVisible]);
};

export default useInsideHover;
