import { IMGQuickTourImage } from "../images";
import { accountVerification, forgetPassword, registrationSuccess, resend, signIn, signUp, verification } from "../routes/routes";

export const DOCUMENT_ROOT = '/';

// const { REACT_APP_API_URL } = process.env;
export const API_URL = 'https://app-qa.xailes.ai/auth-svc';
export const SSO_API_URL = `https://sso-qa.gorillapay.ai`;
export const merchant_onboarding_url = 'https://merchant-qa.gorillapay.ai/merchant-onboarding'
export const gp_redirection_url = 'https://merchant-qa.gorillapay.ai/sign-in'

export const PUBLIC_PATHS = [
  DOCUMENT_ROOT,
  signIn,
  signUp,
  accountVerification,
  forgetPassword,
  registrationSuccess,
  resend,
  '/successful',
  '/failed'
]
export const timeOptions =["Last 7 Days","Last 15 Days","Last 30 Days"]
export const API_ERRORS = {
  '400': 'Oops! Something went wrong.\nPlease try again.',
  '401': 'You are unauthorized.\nPlease try logging in again.',
  '403': 'Forbidden access.\nPlease try logging in again.',
  '404': 'The resource you are trying to access cannot be found.\nPlease contact support.',
  '500': 'Oops! Something went wrong.\nPlease try again.'
};

export const STORAGE_KEY = {
  rememberMe: 'rememberMe',
  access_token: 'access_token',
  refresh_token: 'refresh_token',
  user_info: 'user_info',
  chat_id: 'chat_id',
  is_merchant_exist :'is_merchant_exist',
  user_role: "user_role",
}

export const PRODUCT_TOUR_KEY = {
  show_product_tour: 'show_product_tour',
  show_upload_with_file_tour: 'show_upload_with_file_tour',
  show_upload_without_file_tour: 'show_upload_without_file_tour',
  show_all_chat_tour: 'show_all_chat_tour',
  show_date_filter_tour: 'show_date_fliter_tour',
}

export const USER_CREDENTIALS = {
  USER_EMAIL: "USER_EMAIL",
};

export const MERCHANT_TITLE_TYPE = [
  { label: 'Account Manager', value: 'ACCOUNTING_MANAGER' },
  { label: 'Authorized Signer', value: 'AUTHORIZED_SIGNER' },
  { label: 'Controller', value: 'CONTROLLER' },
  { label: 'Cheif Executive Officer', value: 'CHIEF_EXECUTIVE_OFFICER' },
  { label: 'Chief Financial Officer', value: 'CHIEF_FINANCIAL_OFFICER' },
  { label: 'Cheif Operating Officer', value: 'CHIEF_OPERATING_OFFICER' },
  { label: 'Chairman', value: 'CHAIRMAN' },
  { label: 'Cosigner', value: 'COSIGNER' },
  { label: 'Director', value: 'DIRECTOR' },
  { label: 'General Manager', value: 'GENERAL_MANAGER' },
  { label: 'General Partner', value: 'GENERAL_PARTNER' },
  { label: 'Guarantor', value: 'GUARANTOR' },
  { label: 'Managing Member', value: 'MANAGING_MEMBER' },
  { label: 'Officer', value: 'OFFICER' },
  { label: 'Proprietor or Owner', value: 'PROPRIETOR_OR_OWNER' },
  { label: 'President', value: 'PRESIDENT' },
  { label: 'Partner or Principal', value: 'PARTNER_OR_PRINCIPAL' },
  { label: 'Secretary', value: 'SECRETARY' },
  { label: 'Treasurer', value: 'TREASURER' },
  { label: 'Trustee', value: 'TRUSTEE' },
  { label: 'Vice President', value: 'VICE_PRESIDENT' },
  { label: 'Others', value: 'OTHER' }
]

export const MERCHANT_POSITIONS = [
  { label: 'Benificial Owner', value: 'BENEFICIAL_OWNER' },
  { label: 'Authorized Signer', value: 'AUTHORIZED_SIGNER' },
  { label: 'Sole Proprietor', value: 'SOLE_PROP' }
];

// export const GORILLAPAY_TEXT = "GORILLAPAY";
// export const GORILLAPAY_WITH_SPACE_TEXT = "Gorilla Pay";
// export const SIGN_IN_TEXT = "Sign In";
// export const SIGN_UP_TEXT = "Sign Up";
// export const SUBSCRIBE_TO_OUR_NEWSLETTER_TEXT = "Subscribe to our Newsletter";
// export const PRIVACY_POILICY_TEXT = "I agree to Privacy Policy*";
// export const SUBMIT_TEXT = "Sumbit";
// export const ENTER_EMAIL_TEXT = "Enter your email...";

export const language = [
  { label: "Danish", value: "da" },
  { label: "Dutch", value: "nl" },
  { label: "English", value: "en" },
  { label: "Finnish", value: "fi" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Italian", value: "it" },
  { label: "Norwegian", value: "no" },
  { label: "Polish", value: "pl" },
  { label: "Spanish", value: "es" },
  { label: "Swedish", value: "sv" },
];

export const country = [
  "ABW",
  "AFG",
  "AGO",
  "AIA",
  "ALA",
  "ALB",
  "AND",
  "ARE",
  "ARG",
  "ARM",
  "ASM",
  "ATA",
  "ATF",
  "ATG",
  "AUS",
  "AUT",
  "AZE",
  "BDI",
  "BEL",
  "BEN",
  "BES",
  "BFA",
  "BGD",
  "BGR",
  "BHR",
  "BHS",
  "BIH",
  "BLM",
  "BLR",
  "BLZ",
  "BMU",
  "BOL",
  "BRA",
  "BRB",
  "BRN",
  "BTN",
  "BVT",
  "BWA",
  "CAF",
  "CAN",
  "CCK",
  "CHE",
  "CHL",
  "CHN",
  "CIV",
  "CMR",
  "COD",
  "COG",
  "COK",
  "COL",
  "COM",
  "CPV",
  "CRI",
  "CUB",
  "CUW",
  "CXR",
  "CYM",
  "CYP",
  "CZE",
  "DEU",
  "DJI",
  "DMA",
  "DNK",
  "DOM",
  "DZA",
  "ECU",
  "EGY",
  "ERI",
  "ESH",
  "ESP",
  "EST",
  "ETH",
  "FIN",
  "FJI",
  "FLK",
  "FRA",
  "FRO",
  "FSM",
  "GAB",
  "GBR",
  "GEO",
  "GGY",
  "GHA",
  "GIB",
  "GIN",
  "GLP",
  "GMB",
  "GNB",
  "GNQ",
  "GRC",
  "GRD",
  "GRL",
  "GTM",
  "GUF",
  "GUM",
  "GUY",
  "HKG",
  "HMD",
  "HND",
  "HRV",
  "HTI",
  "HUN",
  "IDN",
  "IMN",
  "IND",
  "IOT",
  "IRL",
  "IRN",
  "IRQ",
  "ISL",
  "ISR",
  "ITA",
  "JAM",
  "JEY",
  "JOR",
  "JPN",
  "KAZ",
  "KEN",
  "KGZ",
  "KHM",
  "KIR",
  "KNA",
  "KOR",
  "KWT",
  "LAO",
  "LBN",
  "LBR",
  "LBY",
  "LCA",
  "LIE",
  "LKA",
  "LSO",
  "LTU",
  "LUX",
  "LVA",
  "MAC",
  "MAF",
  "MAR",
  "MCO",
  "MDA",
  "MDG",
  "MDV",
  "MEX",
  "MHL",
  "MKD",
  "MLI",
  "MLT",
  "MMR",
  "MNE",
  "MNG",
  "MNP",
  "MOZ",
  "MRT",
  "MSR",
  "MTQ",
  "MUS",
  "MWI",
  "MYS",
  "MYT",
  "NAM",
  "NCL",
  "NER",
  "NFK",
  "NGA",
  "NIC",
  "NIU",
  "NLD",
  "NOR",
  "NPL",
  "NRU",
  "NZL",
  "OMN",
  "PAK",
  "PAN",
  "PCN",
  "PER",
  "PHL",
  "PLW",
  "PNG",
  "POL",
  "PRI",
  "PRK",
  "PRT",
  "PRY",
  "PSE",
  "PYF",
  "QAT",
  "REU",
  "ROU",
  "RUS",
  "RWA",
  "SAU",
  "SDN",
  "SEN",
  "SGP",
  "SGS",
  "SHN",
  "SJM",
  "SLB",
  "SLE",
  "SLV",
  "SMR",
  "SOM",
  "SPM",
  "SRB",
  "SSD",
  "STP",
  "SUR",
  "SVK",
  "SVN",
  "SWE",
  "SWZ",
  "SXM",
  "SYC",
  "SYR",
  "TCA",
  "TCD",
  "TGO",
  "THA",
  "TJK",
  "TKL",
  "TKM",
  "TLS",
  "TON",
  "TTO",
  "TUN",
  "TUR",
  "TUV",
  "TWN",
  "TZA",
  "UGA",
  "UKR",
  "UMI",
  "URY",
  "USA",
  "UZB",
  "VAT",
  "VCT",
  "VEN",
  "VGB",
  "VIR",
  "VNM",
  "VUT",
  "WLF",
  "WSM",
  "YEM",
  "ZAF",
  "ZMB",
  "ZWE",
];

// export const titleType = [
//   "ACCOUNTING_MANAGER",
//   "AUTHORIZED_SIGNER",
//   "CONTROLLER",
//   "CHIEF_EXECUTIVE_OFFICER",
//   "CHIEF_FINANCIAL_OFFICER",
//   "CHIEF_OPERATING_OFFICER",
//   "CHAIRMAN",
//   "COSIGNER",
//   "DIRECTOR",
//   "GENERAL_MANAGER",
//   "GENERAL_PARTNER",
//   "GUARANTOR",
//   "MANAGING_MEMBER",
//   "OFFICER",
//   "PROPRIETOR_OR_OWNER",
//   "PRESIDENT",
//   "PARTNER_OR_PRINCIPAL",
//   "SECRETARY",
//   "TREASURER",
//   "TRUSTEE",
//   "VICE_PRESIDENT",
//   "OTHER",
// ];

export const INTL_CODES = [
  "1",
  "7",
  "20",
  "27",
  "30",
  "31",
  "32",
  "33",
  "34",
  "36",
  "39",
  "40",
  "41",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "81",
  "82",
  "84",
  "86",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
  "98",
  "212",
  "213",
  "216",
  "218",
  "220",
  "221",
  "222",
  "223",
  "224",
  "225",
  "226",
  "227",
  "229",
  "230",
  "231",
  "232",
  "233",
  "234",
  "236",
  "237",
  "238",
  "239",
  "241",
  "242",
  "244",
  "245",
  "246",
  "248",
  "249",
  "250",
  "251",
  "252",
  "253",
  "254",
  "255",
  "256",
  "257",
  "258",
  "260",
  "261",
  "262",
  "263",
  "264",
  "265",
  "266",
  "267",
  "268",
  "269",
  "284",
  "290",
  "291",
  "297",
  "298",
  "345",
  "350",
  "351",
  "352",
  "353",
  "354",
  "355",
  "356",
  "357",
  "358",
  "359",
  "370",
  "371",
  "372",
  "373",
  "374",
  "375",
  "376",
  "377",
  "378",
  "380",
  "381",
  "385",
  "386",
  "387",
  "389",
  "420",
  "421",
  "423",
  "441",
  "472",
  "500",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
  "508",
  "509",
  "534",
  "590",
  "591",
  "592",
  "593",
  "594",
  "595",
  "596",
  "597",
  "598",
  "599",
  "649",
  "664",
  "670",
  "671",
  "672",
  "673",
  "674",
  "675",
  "676",
  "677",
  "678",
  "679",
  "680",
  "682",
  "683",
  "684",
  "685",
  "686",
  "687",
  "689",
  "691",
  "692",
  "758",
  "767",
  "787",
  "809",
  "850",
  "852",
  "853",
  "855",
  "856",
  "868",
  "869",
  "875",
  "880",
  "886",
  "960",
  "961",
  "962",
  "963",
  "964",
  "965",
  "966",
  "967",
  "968",
  "970",
  "971",
  "972",
  "973",
  "974",
  "975",
  "976",
  "977",
  "992",
  "993",
  "994",
  "995",
  "996",
  "998",
];

export const STATES = [
  "USA_AL",
  "USA_AK",
  "USA_AZ",
  "USA_AR",
  "USA_CA",
  "USA_CO",
  "USA_CT",
  "USA_DE",
  "USA_DC",
  "USA_FL",
  "USA_GA",
  "USA_HI",
  "USA_ID",
  "USA_IL",
  "USA_IN",
  "USA_IA",
  "USA_KS",
  "USA_KY",
  "USA_LA",
  "USA_ME",
  "USA_MD",
  "USA_MA",
  "USA_MI",
  "USA_MN",
  "USA_MS",
  "USA_MO",
  "USA_MT",
  "USA_NE",
  "USA_NV",
  "USA_NH",
  "USA_NJ",
  "USA_NM",
  "USA_NY",
  "USA_NC",
  "USA_ND",
  "USA_OH",
  "USA_OK",
  "USA_OR",
  "USA_PA",
  "USA_RI",
  "USA_SC",
  "USA_SD",
  "USA_TN",
  "USA_TX",
  "USA_UT",
  "USA_VT",
  "USA_VA",
  "USA_WA",
  "USA_WV",
  "USA_WI",
  "USA_WY",
  "MEX_AG",
  "MEX_BC",
  "MEX_BS",
  "MEX_CH",
  "MEX_CL",
  "MEX_CM",
  "MEX_CO",
  "MEX_CS",
  "MEX_DF",
  "MEX_DG",
  "MEX_GR",
  "MEX_GT",
  "MEX_HG",
  "MEX_JA",
  "MEX_ME",
  "MEX_MI",
  "MEX_MO",
  "MEX_NA",
  "MEX_NL",
  "MEX_OA",
  "MEX_PB",
  "MEX_QE",
  "MEX_QR",
  "MEX_SI",
  "MEX_SL",
  "MEX_SO",
  "MEX_TB",
  "MEX_TL",
  "MEX_TM",
  "MEX_VE",
  "MEX_YU",
  "MEX_ZA",
  "CAN_AB",
  "CAN_BC",
  "CAN_MB",
  "CAN_NB",
  "CAN_NL",
  "CAN_NS",
  "CAN_NT",
  "CAN_NU",
  "CAN_ON",
  "CAN_PE",
  "CAN_QC",
  "CAN_SK",
  "CAN_YT",
  "ITA_AG",
  "ITA_AL",
  "ITA_AN",
  "ITA_AO",
  "ITA_AR",
  "ITA_AP",
  "ITA_AT",
  "ITA_AV",
  "ITA_BA",
  "ITA_BL",
  "ITA_BN",
  "ITA_BG",
  "ITA_BI",
  "ITA_BO",
  "ITA_BZ",
  "ITA_BS",
  "ITA_BR",
  "ITA_CA",
  "ITA_CL",
  "ITA_CB",
  "ITA_CE",
  "ITA_CT",
  "ITA_CZ",
  "ITA_CH",
  "ITA_CO",
  "ITA_CS",
  "ITA_CR",
  "ITA_KR",
  "ITA_CN",
  "ITA_EN",
  "ITA_FE",
  "ITA_FI",
  "ITA_FG",
  "ITA_FC",
  "ITA_FO",
  "ITA_FR",
  "ITA_GE",
  "ITA_GO",
  "ITA_GR",
  "ITA_IM",
  "ITA_IS",
  "ITA_SP",
  "ITA_AQ",
  "ITA_LT",
  "ITA_LE",
  "ITA_LC",
  "ITA_LI",
  "ITA_LO",
  "ITA_LU",
  "ITA_MC",
  "ITA_MN",
  "ITA_MS",
  "ITA_MT",
  "ITA_ME",
  "ITA_MI",
  "ITA_MO",
  "ITA_NA",
  "ITA_NO",
  "ITA_NU",
  "ITA_OR",
  "ITA_PD",
  "ITA_PA",
  "ITA_PR",
  "ITA_PV",
  "ITA_PG",
  "ITA_PS",
  "ITA_PU",
  "ITA_PE",
  "ITA_PC",
  "ITA_PI",
  "ITA_PT",
  "ITA_PN",
  "ITA_PZ",
  "ITA_PO",
  "ITA_RG",
  "ITA_RA",
  "ITA_RC",
  "ITA_RE",
  "ITA_RI",
  "ITA_RN",
  "ITA_RM",
  "ITA_RO",
  "ITA_SA",
  "ITA_SM",
  "ITA_SS",
  "ITA_SV",
  "ITA_SI",
  "ITA_SR",
  "ITA_SO",
  "ITA_TA",
  "ITA_TE",
  "ITA_TR",
  "ITA_TO",
  "ITA_TP",
  "ITA_TN",
  "ITA_TV",
  "ITA_TS",
  "ITA_UD",
  "ITA_VA",
  "ITA_VE",
  "ITA_VB",
  "ITA_VC",
  "ITA_VR",
  "ITA_VV",
  "ITA_VI",
  "ITA_VT",
  "ITA_OT",
];

export const classification = [
  "PHYSICAL_RESIDENTIAL_ADDRESS",
  "BUSINESS_STREET_ADDRESS",
];


export const id_type = [
  { label: "ID Card", value: "ID_CARD" },
  { label: "Canadian Passport", value: "CANADIAN_PASSPORT" },
  { label: "Social Insurance Number (SIN)", value: "SIN" },
  { label: "Individual Taxpayer Identification Number (ITIN)", value: "ITIN" },
  { label: "Government-Issued ID", value: "GOVERNMENT_ISSUED_ID" },
  { label: "Resident Alien Card", value: "RESIDENT_ALIEN_CARD" },
  { label: "Foreign Passport", value: "FOREIGN_PASSPORT" },
];

export const ownership_type = [
  { label: "Sole Trader", value: "SOLE_TRADER" },
  { label: "Partnership", value: "PARTNERSHIP" },
  { label: "Limited Liability Partnership", value: "LIMITED_LIBABILITY_PARTNERSHIP" },
  { label: "Limited Company", value: "LIMITED_COMPANY" },
  { label: "Public Limited Company", value: "PUBLIC_LIMITED_COMPANY" },
  { label: "Charity", value: "CHARITY" },
  { label: "Club", value: "CLUB" },
  { label: "Trust", value: "TRUST" },
  { label: "Other", value: "OTHER" },
  { label: "C Corporation Private Company", value: "C_CORPORATION_PRIVATE_COMPANY" },
  { label: "C Corporation Public Company", value: "C_CORPORATION_PUBLIC_COMPANY" },
  { label: "Government", value: "GOVERNMENT" },
  { label: "Sub S Corporation", value: "SUB_S_CORPORATION" },
  { label: "General Partnership", value: "GENERAL_PARTNERSHIP" },
  { label: "SP Affiliates", value: "SP_AFFILIATES" },
  { label: "Limited Partnership", value: "LIMITED_PARTNERSHIP" },
  { label: "Partnership Limited by Shares", value: "PARTNERSHIP_LIMITED_BY_SHARES" },
  { label: "Joint Stock Company", value: "JOINT_STOCK_COMPANY" },
  { label: "Co-operatives", value: "CO_OPERATIVES" },
  { label: "Estate", value: "ESTATE" },
  { label: "Listed Company", value: "LISTED_COMPANY" },
  { label: "Registered Foreign Enterprise", value: "REGISTERED_FOREIGN_ENTERPRISE" },
  { label: "Foundation", value: "FOUNDATION" },
];


export const tax_class_type = [
  { label: "Corporation", value: "CORPORATION" },
  { label: "Partnership", value: "PARTNERSHIP" },
  { label: "Disregarded Entity", value: "DISREGARDED_ENTITY" },
];


export const owner_exemption_type = [
  { label: "Bank Advised Pooled", value: "BANK_ADVISED_POOLED" },
  { label: "Bank Advised Pooled RP", value: "BANK_ADVISED_POOLED_RP" },
  { label: "Bank Holding Company Act", value: "BANK_HOLDING_COMPANY_ACT" },
  { label: "CFR", value: "CFR" },
  { label: "Commodity Exchange Act", value: "COMMODITY_EXCHANGE_ACT" },
  { label: "Financial Market Utility", value: "FINANCIAL_MARKET_UTILITY" },
  { label: "Foreign Bank", value: "FOREIGN_BANK" },
  { label: "Foreign Government", value: "FOREIGN_GOVERNMENT" },
  { label: "Investment Company Act Advisor", value: "INVESTMENT_COMPANY_ACT_ADVISOR" },
  { label: "Investment Company Act Company", value: "INVESTMENT_COMPANY_ACT_COMPANY" },
  { label: "Non-Profit", value: "NON_PROFIT" },
  { label: "Public Accounting Firm", value: "PUBLIC_ACCOUNTING_FIRM" },
  { label: "Securities Exchange Act Clearing", value: "SECURITIES_EXCHANGE_ACT_CLEARING" },
  { label: "Securities Exchange Act Issuer", value: "SECURITIES_EXCHANGE_ACT_ISSUER" },
  { label: "Securities Exchange Act Registered", value: "SECURITIES_EXCHANGE_ACT_REGISTERED" },
  { label: "State Regulated Insurance", value: "STATE_REGULATED_INSURANCE" },
  { label: "Subsidiary of Public Trade", value: "SUBSIDIARY_OF_PUBLIC_TRADE" },
  { label: "None", value: "NONE" },
];


// export const address_type = [
//   "BUSINESS",
//   "LEGAL",
//   "SHIPPING",
//   "MAILING",
//   "PRINCIPAL",
//   "PREVIOUS",
//   "STATEMENT",
// ];

export const verfication_key = [
  { label: "Statement", value: "STATEMENT" },
  { label: "Legal", value: "LEGAL" },
  { label: "Mailing", value: "MAILING" },
  { label: "Principal", value: "PRINCIPAL" },
  { label: "Business", value: "BUSINESS" },
  { label: "Shipping", value: "SHIPPING" },
  { label: "Previous", value: "PREVIOUS" },
];

export const verfication_document_type = [
  { label: "Foreign Entity Cert Org Document", value: "FOREIGN_ENTITY_CERT_ORG_DOCUMENT" },
  { label: "Certificate of Incorporation", value: "CERTIFICATE_OF_INCORPORATION" },
  { label: "Articles of Amendment", value: "ARTICLES_OF_AMENDMENT" },
  { label: "CRA Summary of Accounts", value: "CRA_SUMMARY_OF_ACCOUNTS" },
  { label: "CRA HST Remittance Stub", value: "CRA_HST_REMITTANCE_STUB" },
  { label: "Deed of Trust", value: "DEED_OF_TRUST" },
  { label: "Incorporation Documents Filed with Government", value: "INCORPORATION_DOCUMENTS_FILED_WITH_GOVERNMENT" },
  { label: "CRA Corporation Remittance Voucher", value: "CRA_CORPORATION_REMITTANCE_VOUCHER" },
  { label: "Certificate of Status", value: "CERTIFICATE_OF_STATUS" },
  { label: "Form 990", value: "FORM_990" },
  { label: "Business Registration", value: "BUSINESS_REGISTRATION" },
  { label: "Articles of Organization", value: "ARTICLES_OF_ORGANIZATION" },
  { label: "Annual Securities Filing", value: "ANNUAL_SECURITIES_FILING" },
  { label: "Signed Independent Corporate Annual Audit", value: "SIGNED_INDEPENDENT_CORPORATE_ANNUAL_AUDIT" },
  { label: "Signed Association Articles", value: "SIGNED_ASSOCIATION_ARTICLES" },
  { label: "Foreign Entity Annual Report", value: "FOREIGN_ENTITY_ANNUAL_REPORT" },
  { label: "Partnership Agreements", value: "PARTNERSHIP_AGREEMENTS" },
  { label: "Signed Testamentary Letter", value: "SIGNED_TESTAMENTARY_LETTER" },
  { label: "Bylaws", value: "BYLAWS" },
  { label: "Signed Operating Agreement", value: "SIGNED_OPERATING_AGREEMENT" },
  { label: "Corporate Profile Report by Government Sites", value: "CORPORATE_PROFILE_REPORT_BY_GOVERNMENT_SITES" },
  { label: "Signed Partnership Agreement", value: "SIGNED_PARTNERSHIP_AGREEMENT" },
  { label: "Signed Limited Liability Agreement", value: "SIGNED_LIMITED_LIABILITY_AGREEMENT" },
  { label: "Notice of Change", value: "NOTICE_OF_CHANGE" },
  { label: "Signed Executorship Letter", value: "SIGNED_EXECUTORSHIP_LETTER" },
  { label: "Federal Certificate of Amalgamation", value: "FEDERAL_CERTIFICATE_OF_AMALGAMATION" },
  { label: "Letter of Notice of Assessment from Government", value: "LETTER_OF_NOTICE_OF_ASSESSMENT_FROM_GOVERNMENT" },
  { label: "Letters Patent", value: "LETTERS_PATENT" },
  { label: "Articles of Incorporation", value: "ARTICLES_OF_INCORPORATION" },
  { label: "Foreign Gov Business License", value: "FOREIGN_GOV_BUSINESS_LICENSE" },
  { label: "Secretary of State Website", value: "SECRETARY_OF_STATE_WEBSITE" },
  { label: "Foreign Gov Cert Good Standing", value: "FOREIGN_GOV_CERT_GOOD_STANDING" },
  { label: "Certified Incorporation Articles", value: "CERTIFIED_INCORPORATION_ARTICLES" },
  { label: "Signed Limited Partnership Agreement", value: "SIGNED_LIMITED_PARTNERSHIP_AGREEMENT" },
  { label: "Business Name Report", value: "BUSINESS_NAME_REPORT" },
  { label: "Government Business License", value: "GOVERNMENT_BUSINESS_LICENSE" },
  { label: "CRA Tax Credit Receipt", value: "CRA_TAX_CREDIT_RECEIPT" },
  { label: "Name Change Documents Filed with Government", value: "NAME_CHANGE_DOCUMENTS_FILED_WITH_GOVERNMENT" },
  { label: "Government", value: "GOVERNMENT" },
];

export const pricing_method = [
  { label: "Association", value: "ASSOCIATION" },
  { label: "Fixed C4 Pricing", value: "FIXED_C4_PRICING" },
  { label: "Fixed C4 Pricing Merchant Managed", value: "FIXED_C4_PRICING_MERCHANT_MANAGED" },
  { label: "Enhanced Interchange Plus", value: "ENHANCED_INTERCHANGE_PLUS" },
  { label: "Fixed C4 Pricing Elavon Managed", value: "FIXED_C4_PRICING_ELAVON_MANAGED" },
  { label: "Tiered Pricing", value: "TIERED_PRICING" },
  { label: "Surcharge Pricing", value: "SURCHARGE_PRICING" },
  { label: "Clear and Simple", value: "CLEAR_AND_SIMPLE" },
  { label: "Interchange Plus", value: "INTERCHANGE_PLUS" },
  { label: "Interchange Differential", value: "INTERCHANGE_DIFFERENTIAL" },
  { label: "Fixed", value: "FIXED" },
];

export const pricing_category = [
  { label: "Internet", value: "INTERNET" },
  { label: "Retail", value: "RETAIL" },
  { label: "Omnicommerce", value: "OMNICOMMERCE" },
  { label: "Supermarket", value: "SUPERMARKET" },
  { label: "Lodging", value: "LODGING" },
  { label: "Restaurant", value: "RESTAURANT" },
  { label: "MOTO", value: "MOTO" },
];

export const pricing_tier_types = [
  { label: "Swipe", value: "SWIPE" },
  { label: "Foreign Markup Assessment", value: "FOREIGN_MARKUP_ASSESSMENT" },
  { label: "Rewards", value: "REWARDS" },
  { label: "Check Card", value: "CHECK_CARD" },
  { label: "Premium", value: "PREMIUM" },
  { label: "Qualified", value: "QUALIFIED" },
  { label: "Supermarket", value: "SUPERMARKET" },
  { label: "Quickpay", value: "QUICKPAY" },
  { label: "Ecommerce", value: "ECOMMERCE" },
  { label: "Commercial Non-Qualified", value: "COMMERCIAL_NON_QUALIFIED" },
  { label: "Special Qualified", value: "SPECIAL_QUALIFED" },
  { label: "Super Premium", value: "SUPER_PREMIUM" },
  { label: "Markup Assessment", value: "MARKUP_ASSESSMENT" },
  { label: "Non-Qualified", value: "NON_QUALIFED" },
  { label: "Partial Qualified", value: "PARTIAL_QUALIFIED" },
  { label: "Standard", value: "STANDARD" },
  { label: "International", value: "INTERNATIONAL" },
  { label: "Keyed", value: "KEYED" },
];

export const card_type = [
  { label: "Visa Electron", value: "VISA_ELECTRON" },
  { label: "Visa", value: "VISA" },
  { label: "PIN Debit", value: "PIN_DEBIT" },
  { label: "UnionPay Debit", value: "UNIONPAY_DEBIT" },
  { label: "Discover", value: "DISCOVER" },
  { label: "Domestic Debit", value: "DOMESTIC_DEBIT" },
  { label: "S4B Debit", value: "S4B_DEBIT" },
  { label: "American Express", value: "AMERICAN_EXPRESS" },
  { label: "Mastercard", value: "MASTERCARD" },
  { label: "UK Domestic Maestro", value: "UK_DOMESTIC_MAESTRO" },
  { label: "Domestic Credit", value: "DOMESTIC_CREDIT" },
  { label: "Diners", value: "DINERS" },
  { label: "Mastercard Debit", value: "MASTERCARD_DEBIT" },
  { label: "Mastercard Maestro", value: "MASTERCARD_MAESTRO" },
  { label: "JCB", value: "JCB" },
  { label: "Visa Debit", value: "VISA_DEBIT" },
  { label: "Vpay", value: "VPAY" },
  { label: "S4B Credit", value: "S4B_CREDIT" },
  { label: "UnionPay Credit", value: "UNIONPAY_CREDIT" },
  { label: "Solo", value: "SOLO" },
];


export const BANK_ACCOUNT_TYPE = [
  { label: "Deposit", value: "DEPOSIT" },
  { label: "Chargeback", value: "CHARGEBACK" },
];

export const FUNDING_METHOD = [
  { label: "Gross", value: "GROSS" },
  { label: "Net Credit", value: "NETCREDIT" },
];

export const fees_frequency = [
  { label: "Per Occurrence", value: "PER_OCCURRENCE" },
  { label: "One Time", value: "ONE_TIME" },
  { label: "Quarterly", value: "QUARTERLY" },
  { label: "Monthly", value: "MONTHLY" },
  { label: "Seasonal", value: "SEASONAL" },
  { label: "Annual", value: "ANNUAL" },
];

export const billing_statement_type = [
  { label: "Summary Basic Basic", value: "SUMMARY_BASIC_BASIC" },
  { label: "Summary Only", value: "SUMMARY_ONLY" },
  { label: "Sans Summary No Recap", value: "SANS_SUMMARY_NO_RECAP" },
  { label: "S2 Summary Detail", value: "S2_SUMMARY_DETAIL" },
  { label: "Summary Enhanced Debit Basic", value: "SUMMARY_ENHANCED_DEBIT_BASIC" },
  { label: "Detail Interchange Plus Complex", value: "DETAIL_INTERCHANGE_PLUS_COMPLEX" },
  { label: "Fixed Bank Complex With Recap", value: "FIXED_BANK_COMPLEX_WITH_RECAP" },
  { label: "Transaction Detail", value: "TRANSACTION_DETAIL" },
  { label: "Detail Complex Complex", value: "DETAIL_COMPLEX_COMPLEX" },
  { label: "Summary Interchange Plus Basic", value: "SUMMARY_INTERCHANGE_PLUS_BASIC" },
  { label: "Association Interchange With Recap", value: "ASSOCIATION_INTERCHANGE_WITH_RECAP" },
  { label: "Tiered Summary No Recap", value: "TIERED_SUMMARY_NO_RECAP" },
  { label: "Batch", value: "BATCH" },
  { label: "Surcharge Tiered", value: "SURCHARGE_TIERED" },
  { label: "CSV", value: "CSV" },
  { label: "Detail Interchange Plus Basic", value: "DETAIL_INTERCHANGE_PLUS_BASIC" },
  { label: "Invoice", value: "INVOICE" },
  { label: "Summary Qualified Basic", value: "SUMMARY_QUALIFIED_BASIC" },
  { label: "XML", value: "XML" },
  { label: "Summary Accumulation Basic", value: "SUMMARY_ACCUMULATION_BASIC" },
  { label: "Summary Enhanced Debit Int", value: "SUMMARY_ENHANCED_DEBIT_INT" },
];

export const billing_statement_media = [
  { label: "None", value: "NONE" },
  { label: "Hard Copy", value: "HARD_COPY" },
  { label: "Email", value: "EMAIL" },
  { label: "File Transfer", value: "FILE_TRANSFER" },
  { label: "Dynamic Merchant Reporting", value: "DYNAMIC_MERCHANT_REPORTING" },
];
export const purchase_type = [
  { label: "Purchase", value: "PURCHASE" },
  { label: "Exchange", value: "EXCHANGE" },
  { label: "Installment", value: "INSTALLMENT" },
  { label: "Lease", value: "LEASE" },
  { label: "Existing", value: "EXISTING" },
  { label: "Annual Rent", value: "ANNUAL_RENT" },
  { label: "Per Auth", value: "PER_AUTH" },
  { label: "Rent", value: "RENT" },
];

export const security_level_type = [
  { label: "Authenticated Security", value: "AUTHENTICATED_SECURITY" },
  { label: "SSL Shop", value: "SSL_SHOP" },
  { label: "Safe T", value: "SAFE_T" },
  { label: "Guaranteed Security", value: "GUARANTEED_SECURITY" },
  { label: "Basic", value: "BASIC" },
];

export const ConnectionType = [
  { label: "Standard Dial", value: "STANDARD_DIAL" },
  { label: "General Radio", value: "GENERAL_RADIO" },
  { label: "WiFi", value: "WIFI" },
  { label: "Bluetooth IP", value: "BLUETOOTH_IP" },
  { label: "Standard IP", value: "STANDARD_IP" },
  { label: "Unknown", value: "UNKNOWN" },
  { label: "Public Telenet", value: "PUBLIC_TELENET" },
  { label: "Wireless", value: "WIRELESS" },
  { label: "Public Telenet IP", value: "PUBLIC_TELENET_IP" },
  { label: "Bluetooth Public Telenet", value: "BLUETOOTH_PUBLIC_TELENET" },
  { label: "PWPW", value: "PWPW" },
  { label: "Bluetooth", value: "BLUETOOTH" },
];

export const terminal_type = [
  { label: "Internet", value: "INTERNET" },
  { label: "Non-Internet", value: "NON_INTERNET" },
];

export const TERMINAL_SERVICE_TYPE = [
  { label: "Tip Function Cashier", value: "TIP_FUNCTION_CASHIER" },
  { label: "DCC Quote", value: "DCC_QUOTE" },
  { label: "Prompt Tips Guideline", value: "PROMPT_TIPS_GUIDELINE" },
  { label: "Enlarge Terminal Font", value: "ENLARGE_TERMINAL_FONT" },
  { label: "Courier Install", value: "COURIER_INSTALL" },
  { label: "Quick Stay", value: "QUICK_STAY" },
  { label: "Suppress Audrpt", value: "SUPPRESS_AUDRPT" },
  { label: "Manual PAN Entry", value: "MANUAL_PAN_ENTRY" },
  { label: "Adjust Allowed", value: "ADJUST_ALLOWED" },
  { label: "Contactless", value: "CONTACTLESS" },
  { label: "No Signature", value: "NO_SIGNATURE" },
  { label: "Engineer Visit", value: "ENGINEER_VISIT" },
  { label: "No Menu", value: "NO_MENU" },
  { label: "Widen Terminal Columns", value: "WIDEN_TERMINAL_COLUMNS" },
  { label: "Store and Forward", value: "STORE_AND_FORWARD" },
  { label: "No Server Prompt", value: "NO_SERVER_PROMPT" },
  { label: "Tip Function Waiter", value: "TIP_FUNCTION_WAITER" },
  { label: "Courier Delivery", value: "COURIER_DELIVERY" },
  { label: "Remove Detail Report", value: "REMOVE_DETAIL_REPORT" },
  { label: "Enable BLIK", value: "ENABLE_BLIK" },
  { label: "Bar Tab", value: "BAR_TAB" },
  { label: "Terminal Auto Close", value: "TERMINAL_AUTO_CLOSE" },
  { label: "Clerk Prompt", value: "CLERK_PROMPT" },
  { label: "Data Candy", value: "DATA_CANDY" },
  { label: "Cashless Program", value: "CASHLESS_PROGRAM" },
  { label: "Verified by VISA", value: "VERIFIED_BY_VISA" },
  { label: "Premium Install", value: "PREMIUM_INSTALL" },
  { label: "Cash Back PIN Debit", value: "CASH_BACK_PIN_DEBIT" },
  { label: "Refund Pass Req", value: "REFUND_PASS_REQ" },
  { label: "Chip and PIN", value: "CHIP_AND_PIN" },
  { label: "No Tip", value: "NO_TIP" },
  { label: "Gateconex 2 Enabled", value: "GATECONEX_2_ENABLED" },
  { label: "Fine Dining", value: "FINE_DINING" },
  { label: "Quick Close", value: "QUICK_CLOSE" },
  { label: "Mastercard Secure Code", value: "MASTERCARD_SECURE_CODE" },
  { label: "Basic Menu", value: "BASIC_MENU" },
  { label: "Supress Reftrans", value: "SUPRESS_REFTRANS" },
  { label: "Print Decline Receipt", value: "PRINT_DECLINE_RECEIPT" },
  { label: "Custom Footer", value: "CUSTOM_FOOTER" },
  { label: "Premium Menu", value: "PREMIUM_MENU" },
  { label: "Merchant Copy PAN Truncation", value: "MERCHANT_COPY_PAN_TRUNCATION" },
];

export const training_type = [
  { label: "None", value: "NONE" },
  { label: "Training", value: "TRAINING" },
];


export const NETWORK = [
  { label: "AXIS", value: "AXIS" },
  { label: "CEKAB", value: "CEKAB" },
  { label: "Merchant Location", value: "MERCHANT_LOCATION" },
  { label: "Global NDC", value: "GLOBAL_NDC" },
  { label: "Deluxe Data", value: "DELUXE_DATA" },
  { label: "DCSI", value: "DCSI" },
  { label: "Telemoney", value: "TELEMONEY" },
  { label: "GCS", value: "GCS" },
  { label: "First Data", value: "FIRST_DATA" },
  { label: "ADS Chicago", value: "ADS_CHICAGO" },
  { label: "Redsys", value: "REDSYS" },
  { label: "MDI", value: "MDI" },
  { label: "TPS", value: "TPS" },
  { label: "TSYS", value: "TSYS" },
  { label: "Elavon", value: "ELAVON" },
];

export const mark_up_type = [
  { label: "2.00%", value: "TWO_POINT_ZERO_ZERO" },
  { label: "2.25%", value: "TWO_POINT_TWO_FIVE" },
  { label: "2.75%", value: "TWO_POINT_SEVEN_FIVE" },
  { label: "3.75%", value: "THREE_POINT_SEVEN_FIVE" },
  { label: "4.25%", value: "FOUR_POINT_TWO_FIVE" },
  { label: "4.50%", value: "FOUR_POINT_FIVE_ZERO" },
  { label: "5.00%", value: "FIVE_POINT_ZERO_ZERO" },
  { label: "5.25%", value: "FIVE_POINT_TWO_FIVE" },
  { label: "5.50%", value: "FIVE_POINT_FIVE_ZERO" },
  { label: "5.75%", value: "FIVE_POINT_SEVEN_FIVE" },
  { label: "6.25%", value: "SIX_POINT_TWO_FIVE" },
  { label: "6.50%", value: "SIX_POINT_FIVE_ZERO" },
  { label: "6.75%", value: "SIX_POINT_SEVEN_FIVE" },
  { label: "7.00%", value: "SEVEN_POINT_ZERO_ZERO" },
  { label: "7.25%", value: "SEVEN_POINT_TWO_FIVE" },
  { label: "7.50%", value: "SEVEN_POINT_FIVE_ZERO" },
  { label: "7.75%", value: "SEVEN_POINT_SEVEN_FIVE" },
  { label: "8.00%", value: "EIGHT_POINT_ZERO_ZERO" },
  { label: "2.50%", value: "TWO_POINT_FIVE_ZERO" },
  { label: "3.00%", value: "THREE_POINT_ZERO_ZERO" },
  { label: "3.25%", value: "THREE_POINT_TWO_FIVE" },
  { label: "3.50%", value: "THREE_POINT_FIVE_ZERO" },
  { label: "4.00%", value: "FOUR_POINT_ZERO_ZERO" },
  { label: "4.75%", value: "FOUR_POINT_SEVEN_FIVE" },
  { label: "6.00%", value: "SIX_POINT_ZERO_ZERO" },
];

export const currency_group = [
  { label: "Five USD DCC Currencies", value: "FIVE_USD_DCC_CURRENCIES" },
  { label: "All CAD DCC Currencies", value: "ALL_CAD_DCC_CURRENCIES" },
  { label: "All USD DCC Currencies", value: "ALL_USD_DCC_CURRENCIES" },
];


export const residing_country = [
  { label: "Aruba", value: "ABW" },
  { label: "Afghanistan", value: "AFG" },
  { label: "Angola", value: "AGO" },
  { label: "Anguilla", value: "AIA" },
  { label: "Åland Islands", value: "ALA" },
  { label: "Albania", value: "ALB" },
  { label: "Andorra", value: "AND" },
  { label: "United Arab Emirates", value: "ARE" },
  { label: "Argentina", value: "ARG" },
  { label: "Armenia", value: "ARM" },
  { label: "American Samoa", value: "ASM" },
  { label: "Antarctica", value: "ATA" },
  { label: "French Southern Territories", value: "ATF" },
  { label: "Antigua and Barbuda", value: "ATG" },
  { label: "Australia", value: "AUS" },
  { label: "Austria", value: "AUT" },
  { label: "Azerbaijan", value: "AZE" },
  { label: "Burundi", value: "BDI" },
  { label: "Belgium", value: "BEL" },
  { label: "Benin", value: "BEN" },
  { label: "Saint Barthélemy", value: "BLM" },
  { label: "Belarus", value: "BLR" },
  { label: "Belize", value: "BLZ" },
  { label: "Bermuda", value: "BMU" },
  { label: "Bolivia", value: "BOL" },
  { label: "Brazil", value: "BRA" },
  { label: "Barbados", value: "BRB" },
  { label: "Brunei", value: "BRN" },
  { label: "Bhutan", value: "BTN" },
  { label: "Bouvet Island", value: "BVT" },
  { label: "Botswana", value: "BWA" },
  { label: "Central African Republic", value: "CAF" },
  { label: "Canada", value: "CAN" },
  { label: "Cocos (Keeling) Islands", value: "CCK" },
  { label: "Switzerland", value: "CHE" },
  { label: "Chile", value: "CHL" },
  { label: "China", value: "CHN" },
  { label: "Ivory Coast", value: "CIV" },
  { label: "Cameroon", value: "CMR" },
  { label: "Congo (DRC)", value: "COD" },
  { label: "Congo", value: "COG" },
  { label: "Cook Islands", value: "COK" },
  { label: "Colombia", value: "COL" },
  { label: "Comoros", value: "COM" },
  { label: "Cape Verde", value: "CPV" },
  { label: "Costa Rica", value: "CRI" },
  { label: "Cuba", value: "CUB" },
  { label: "Curaçao", value: "CUW" },
  { label: "Christmas Island", value: "CXR" },
  { label: "Cayman Islands", value: "CYM" },
  { label: "Cyprus", value: "CYP" },
  { label: "Czech Republic", value: "CZE" },
  { label: "Germany", value: "DEU" },
  { label: "Djibouti", value: "DJI" },
  { label: "Dominica", value: "DMA" },
  { label: "Denmark", value: "DNK" },
  { label: "Dominican Republic", value: "DOM" },
  { label: "Algeria", value: "DZA" },
  { label: "Ecuador", value: "ECU" },
  { label: "Egypt", value: "EGY" },
  { label: "Eritrea", value: "ERI" },
  { label: "Western Sahara", value: "ESH" },
  { label: "Spain", value: "ESP" },
  { label: "Estonia", value: "EST" },
  { label: "Ethiopia", value: "ETH" },
  { label: "Finland", value: "FIN" },
  { label: "Fiji", value: "FJI" },
  { label: "Falkland Islands", value: "FLK" },
  { label: "France", value: "FRA" },
  { label: "Faroe Islands", value: "FRO" },
  { label: "Micronesia", value: "FSM" },
  { label: "Gabon", value: "GAB" },
  { label: "United Kingdom", value: "GBR" },
  { label: "Georgia", value: "GEO" },
  { label: "Guernsey", value: "GGY" },
  { label: "Ghana", value: "GHA" },
  { label: "Gibraltar", value: "GIB" },
  { label: "Guinea", value: "GIN" },
  { label: "Guadeloupe", value: "GLP" },
  { label: "Gambia", value: "GMB" },
  { label: "Guinea-Bissau", value: "GNB" },
  { label: "Equatorial Guinea", value: "GNQ" },
  { label: "Greece", value: "GRC" },
  { label: "Grenada", value: "GRD" },
  { label: "Greenland", value: "GRL" },
  { label: "Guatemala", value: "GTM" },
  { label: "French Guiana", value: "GUF" },
  { label: "Guam", value: "GUM" },
  { label: "Guyana", value: "GUY" },
  { label: "Hong Kong SAR", value: "HKG" },
  { label: "Heard Island and McDonald Islands", value: "HMD" },
  { label: "Honduras", value: "HND" },
  { label: "Croatia", value: "HRV" },
  { label: "Haiti", value: "HTI" },
  { label: "Hungary", value: "HUN" },
  { label: "Indonesia", value: "IDN" },
  { label: "Isle of Man", value: "IMN" },
  { label: "India", value: "IND" },
  { label: "British Indian Ocean Territory", value: "IOT" },
  { label: "Ireland", value: "IRL" },
  { label: "Iran", value: "IRN" },
  { label: "Iraq", value: "IRQ" },
  { label: "Iceland", value: "ISL" },
  { label: "Israel", value: "ISR" },
  { label: "Italy", value: "ITA" },
  { label: "Jamaica", value: "JAM" },
  { label: "Jersey", value: "JEY" },
  { label: "Jordan", value: "JOR" },
  { label: "Japan", value: "JPN" },
  { label: "Kazakhstan", value: "KAZ" },
  { label: "Kenya", value: "KEN" },
  { label: "Kyrgyzstan", value: "KGZ" },
  { label: "Cambodia", value: "KHM" },
  { label: "Kiribati", value: "KIR" },
  { label: "Saint Kitts and Nevis", value: "KNA" },
  { label: "South Korea", value: "KOR" },
  { label: "Kuwait", value: "KWT" },
  { label: "Laos", value: "LAO" },
  { label: "Lebanon", value: "LBN" },
  { label: "Liberia", value: "LBR" },
  { label: "Libya", value: "LBY" },
  { label: "Saint Lucia", value: "LCA" },
  { label: "Liechtenstein", value: "LIE" },
  { label: "Sri Lanka", value: "LKA" },
  { label: "Lesotho", value: "LSO" },
  { label: "Lithuania", value: "LTU" },
  { label: "Luxembourg", value: "LUX" },
  { label: "Latvia", value: "LVA" },
  { label: "Macao SAR", value: "MAC" },
  { label: "Saint Martin", value: "MAF" },
  { label: "Morocco", value: "MAR" },
  { label: "Monaco", value: "MCO" },
  { label: "Moldova", value: "MDA" },
  { label: "Madagascar", value: "MDG" },
  { label: "Maldives", value: "MDV" },
  { label: "Mexico", value: "MEX" },
  { label: "Marshall Islands", value: "MHL" },
  { label: "North Macedonia", value: "MKD" },
  { label: "Mali", value: "MLI" },
  { label: "Malta", value: "MLT" },
  { label: "Myanmar", value: "MMR" },
  { label: "Montenegro", value: "MNE" },
  { label: "Mongolia", value: "MNG" },
  { label: "Northern Mariana Islands", value: "MNP" },
  { label: "Mozambique", value: "MOZ" },
  { label: "Mauritania", value: "MRT" },
  { label: "Montserrat", value: "MSR" },
  { label: "Martinique", value: "MTQ" },
  { label: "Mauritius", value: "MUS" },
  { label: "Malawi", value: "MWI" },
  { label: "Malaysia", value: "MYS" },
  { label: "Mayotte", value: "MYT" },
  { label: "Namibia", value: "NAM" },
  { label: "New Caledonia", value: "NCL" },
  { label: "Niger", value: "NER" },
  { label: "Norfolk Island", value: "NFK" },
  { label: "Nigeria", value: "NGA" },
  { label: "Nicaragua", value: "NIC" },
  { label: "Niue", value: "NIU" },
  { label: "Netherlands", value: "NLD" },
  { label: "Norway", value: "NOR" },
  { label: "Nepal", value: "NPL" },
  { label: "Nauru", value: "NRU" },
  { label: "New Zealand", value: "NZL" },
  { label: "Oman", value: "OMN" },
  { label: "Pakistan", value: "PAK" },
  { label: "Panama", value: "PAN" },
  { label: "Pitcairn Islands", value: "PCN" },
  { label: "Peru", value: "PER" },
  { label: "Philippines", value: "PHL" },
  { label: "Palau", value: "PLW" },
  { label: "Papua New Guinea", value: "PNG" },
  { label: "Poland", value: "POL" },
  { label: "Puerto Rico", value: "PRI" },
  { label: "North Korea", value: "PRK" },
  { label: "Portugal", value: "PRT" },
  { label: "Paraguay", value: "PRY" },
  { label: "Palestine", value: "PSE" },
  { label: "French Polynesia", value: "PYF" },
  { label: "Qatar", value: "QAT" },
  { label: "Réunion", value: "REU" },
  { label: "Romania", value: "ROU" },
  { label: "Russia", value: "RUS" },
  { label: "Rwanda", value: "RWA" },
  { label: "Saudi Arabia", value: "SAU" },
  { label: "Sudan", value: "SDN" },
  { label: "Senegal", value: "SEN" },
  { label: "Singapore", value: "SGP" },
  { label: "South Georgia and the South Sandwich Islands", value: "SGS" },
  { label: "Saint Helena", value: "SHN" },
  { label: "Svalbard and Jan Mayen", value: "SJM" },
  { label: "Solomon Islands", value: "SLB" },
  { label: "Sierra Leone", value: "SLE" },
  { label: "El Salvador", value: "SLV" },
  { label: "San Marino", value: "SMR" },
  { label: "Somalia", value: "SOM" },
  { label: "Saint Pierre and Miquelon", value: "SPM" },
  { label: "Serbia", value: "SRB" },
  { label: "South Sudan", value: "SSD" },
  { label: "São Tomé and Príncipe", value: "STP" },
  { label: "Suriname", value: "SUR" },
  { label: "Slovakia", value: "SVK" },
  { label: "Slovenia", value: "SVN" },
  { label: "Sweden", value: "SWE" },
  { label: "Swaziland", value: "SWZ" },
  { label: "Sint Maarten", value: "SXM" },
  { label: "Seychelles", value: "SYC" },
  { label: "Syria", value: "SYR" },
  { label: "Turks and Caicos Islands", value: "TCA" },
  { label: "Chad", value: "TCD" },
  { label: "Togo", value: "TGO" },
  { label: "Thailand", value: "THA" },
  { label: "Tajikistan", value: "TJK" },
  { label: "Tokelau", value: "TKL" },
  { label: "Turkmenistan", value: "TKM" },
  { label: "Timor-Leste", value: "TLS" },
  { label: "Tonga", value: "TON" },
  { label: "Trinidad and Tobago", value: "TTO" },
  { label: "Tunisia", value: "TUN" },
  { label: "Turkey", value: "TUR" },
  { label: "Tuvalu", value: "TUV" },
  { label: "Taiwan", value: "TWN" },
  { label: "Tanzania", value: "TZA" },
  { label: "Uganda", value: "UGA" },
  { label: "Ukraine", value: "UKR" },
  { label: "United States Minor Outlying Islands", value: "UMI" },
  { label: "Uruguay", value: "URY" },
  { label: "United States", value: "USA" },
  { label: "Uzbekistan", value: "UZB" },
  { label: "Vatican City", value: "VAT" },
  { label: "Saint Vincent and the Grenadines", value: "VCT" },
  { label: "Venezuela", value: "VEN" },
  { label: "British Virgin Islands", value: "VGB" },
  { label: "U.S. Virgin Islands", value: "VIR" },
  { label: "Vietnam", value: "VNM" },
  { label: "Vanuatu", value: "VUT" },
  { label: "Wallis and Futuna", value: "WLF" },
  { label: "Samoa", value: "WSM" },
  { label: "Yemen", value: "YEM" },
  { label: "South Africa", value: "ZAF" },
  { label: "Zambia", value: "ZMB" },
  { label: "Zimbabwe", value: "ZWE" },
];

export const languageKeyValue = [
  { label: "Danish", value: "da" },
  { label: "Dutch", value: "nl" },
  { label: "English", value: "en" },
  { label: "Finnish", value: "fi" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Italian", value: "it" },
  { label: "Norwegian", value: "no" },
  { label: "Polish", value: "pl" },
  { label: "Spanish", value: "es" },
  { label: "Swedish", value: "sv" },
];

export const intlCodesKeyValueold = [
  { label: "United States", value: "1" },
  { label: "Russia", value: "7" },
  { label: "Egypt", value: "20" },
  { label: "South Africa", value: "27" },
  { label: "Greece", value: "30" },
  { label: "Netherlands", value: "31" },
  { label: "Belgium", value: "32" },
  { label: "France", value: "33" },
  { label: "Spain", value: "34" },
  { label: "Hungary", value: "36" },
  { label: "Italy", value: "39" },
  { label: "Romania", value: "40" },
  { label: "Switzerland", value: "41" },
  { label: "Austria", value: "43" },
  { label: "United Kingdom", value: "44" },
  { label: "Denmark", value: "45" },
  { label: "Sweden", value: "46" },
  { label: "Norway", value: "47" },
  { label: "Poland", value: "48" },
  { label: "Germany", value: "49" },
  { label: "Peru", value: "51" },
  { label: "Mexico", value: "52" },
  { label: "Cuba", value: "53" },
  { label: "Argentina", value: "54" },
  { label: "Brazil", value: "55" },
  { label: "Chile", value: "56" },
  { label: "Colombia", value: "57" },
  { label: "Venezuela", value: "58" },
  { label: "Malaysia", value: "60" },
  { label: "Australia", value: "61" },
  { label: "Indonesia", value: "62" },
  { label: "Philippines", value: "63" },
  { label: "New Zealand", value: "64" },
  { label: "Singapore", value: "65" },
  { label: "Thailand", value: "66" },
  { label: "Japan", value: "81" },
  { label: "South Korea", value: "82" },
  { label: "Vietnam", value: "84" },
  { label: "China", value: "86" },
  { label: "Turkey", value: "90" },
  { label: "India", value: "91" },
  { label: "Pakistan", value: "92" },
  { label: "Afghanistan", value: "93" },
  { label: "Sri Lanka", value: "94" },
  { label: "Myanmar", value: "95" },
  { label: "Iran", value: "98" },
  { label: "Morocco", value: "212" },
  { label: "Algeria", value: "213" },
  { label: "Tunisia", value: "216" },
  { label: "Libya", value: "218" },
  { label: "Gambia", value: "220" },
  { label: "Senegal", value: "221" },
  { label: "Mauritania", value: "222" },
  { label: "Mali", value: "223" },
  { label: "Guinea", value: "224" },
  { label: "Ivory Coast", value: "225" },
  { label: "Burkina Faso", value: "226" },
  { label: "Niger", value: "227" },
  { label: "Togo", value: "228" },
  { label: "Benin", value: "229" },
  { label: "Mauritius", value: "230" },
  { label: "Liberia", value: "231" },
  { label: "Sierra Leone", value: "232" },
  { label: "Ghana", value: "233" },
  { label: "Nigeria", value: "234" },
  { label: "Central African Republic", value: "236" },
  { label: "Cameroon", value: "237" },
  { label: "Cape Verde", value: "238" },
  { label: "Sao Tome and Principe", value: "239" },
  { label: "Equatorial Guinea", value: "241" },
  { label: "Gabon", value: "242" },
  { label: "Republic of the Congo", value: "243" },
  { label: "Democratic Republic of the Congo", value: "243" },
  { label: "Angola", value: "244" },
  { label: "Guinea-Bissau", value: "245" },
  { label: "Seychelles", value: "248" },
  { label: "Sudan", value: "249" },
  { label: "Rwanda", value: "250" },
  { label: "Ethiopia", value: "251" },
  { label: "Somalia", value: "252" },
  { label: "Djibouti", value: "253" },
  { label: "Kenya", value: "254" },
  { label: "Tanzania", value: "255" },
  { label: "Uganda", value: "256" },
  { label: "Burundi", value: "257" },
  { label: "Mozambique", value: "258" },
  { label: "Zambia", value: "260" },
  { label: "Madagascar", value: "261" },
  { label: "Reunion", value: "262" },
  { label: "Zimbabwe", value: "263" },
  { label: "Namibia", value: "264" },
  { label: "Malawi", value: "265" },
  { label: "Lesotho", value: "266" },
  { label: "Botswana", value: "267" },
  { label: "Swaziland", value: "268" },
  { label: "Comoros", value: "269" },
  { label: "British Virgin Islands", value: "284" },
  { label: "Saint Helena", value: "290" },
  { label: "Eritrea", value: "291" },
  { label: "Aruba", value: "297" },
  { label: "Faroe Islands", value: "298" },
  { label: "Cayman Islands", value: "345" },
  { label: "Gibraltar", value: "350" },
  { label: "Portugal", value: "351" },
  { label: "Luxembourg", value: "352" },
  { label: "Ireland", value: "353" },
  { label: "Iceland", value: "354" },
  { label: "Albania", value: "355" },
  { label: "Malta", value: "356" },
  { label: "Cyprus", value: "357" },
  { label: "Finland", value: "358" },
  { label: "Bulgaria", value: "359" },
  { label: "Lithuania", value: "370" },
  { label: "Latvia", value: "371" },
  { label: "Estonia", value: "372" },
  { label: "Moldova", value: "373" },
  { label: "Monaco", value: "377" },
  { label: "San Marino", value: "378" },
  { label: "Vatican City", value: "379" },
  { label: "Ukraine", value: "380" },
  { label: "Serbia", value: "381" },
  { label: "Montenegro", value: "382" },
  { label: "Croatia", value: "385" },
  { label: "Slovenia", value: "386" },
  { label: "Bosnia and Herzegovina", value: "387" },
  { label: "North Macedonia", value: "389" },
  { label: "Czech Republic", value: "420" },
  { label: "Slovakia", value: "421" },
  { label: "Liechtenstein", value: "423" },
  { label: "Bermuda", value: "441" },
  { label: "Guantanamo Bay", value: "472" },
  { label: "Falkland Islands", value: "500" },
  { label: "Belize", value: "501" },
  { label: "Guatemala", value: "502" },
  { label: "El Salvador", value: "503" },
  { label: "Honduras", value: "504" },
  { label: "Nicaragua", value: "505" },
  { label: "Costa Rica", value: "506" },
  { label: "Panama", value: "507" },
  { label: "Saint Pierre and Miquelon", value: "508" },
  { label: "Haiti", value: "509" },
  { label: "Sint Maarten", value: "534" },
  { label: "Saint Barthelemy", value: "590" },
  { label: "Bolivia", value: "591" },
  { label: "Guyana", value: "592" },
  { label: "Ecuador", value: "593" },
  { label: "French Guiana", value: "594" },
  { label: "Paraguay", value: "595" },
  { label: "Martinique", value: "596" },
  { label: "Suriname", value: "597" },
  { label: "Uruguay", value: "598" },
  { label: "Curacao", value: "599" },
  { label: "Turks and Caicos Islands", value: "649" },
  { label: "Montserrat", value: "664" },
  { label: "East Timor", value: "670" },
  { label: "Northern Mariana Islands", value: "671" },
  { label: "Christmas Island", value: "672" },
  { label: "Brunei", value: "673" },
  { label: "Nauru", value: "674" },
  { label: "Papua New Guinea", value: "675" },
  { label: "Tonga", value: "676" },
  { label: "Solomon Islands", value: "677" },
  { label: "Vanuatu", value: "678" },
  { label: "Fiji", value: "679" },
  { label: "Palau", value: "680" },
  { label: "Cook Islands", value: "682" },
  { label: "Niue", value: "683" },
  { label: "American Samoa", value: "684" },
  { label: "Samoa", value: "685" },
  { label: "New Caledonia", value: "687" },
  { label: "French Polynesia", value: "689" },
  { label: "Micronesia", value: "691" },
  { label: "Marshall Islands", value: "692" },
  { label: "Saint Lucia", value: "758" },
  { label: "Dominica", value: "767" },
  { label: "Puerto Rico", value: "787" },
  { label: "Dominican Republic", value: "809" },
  { label: "North Korea", value: "850" },
  { label: "Hong Kong", value: "852" },
  { label: "Macao", value: "853" },
  { label: "Cambodia", value: "855" },
  { label: "Laos", value: "856" },
  { label: "North Korea (Democratic People's Republic of Korea)", value: "868" },
  { label: "Saint Kitts and Nevis", value: "869" },
  { label: "Sint Eustatius", value: "875" },
  { label: "Bangladesh", value: "880" },
  { label: "Taiwan", value: "886" },
  { label: "Maldives", value: "960" },
  { label: "Lebanon", value: "961" },
  { label: "Jordan", value: "962" },
  { label: "Syria", value: "963" },
  { label: "Iraq", value: "964" },
  { label: "Kuwait", value: "965" },
  { label: "Saudi Arabia", value: "966" },
  { label: "Yemen", value: "967" },
  { label: "Oman", value: "968" },
  { label: "Palestine", value: "970" },
  { label: "United Arab Emirates", value: "971" },
  { label: "Israel", value: "972" },
  { label: "Bahrain", value: "973" },
  { label: "Qatar", value: "974" },
  { label: "Bhutan", value: "975" },
  { label: "Mongolia", value: "976" },
  { label: "Nepal", value: "977" },
  { label: "Tajikistan", value: "992" },
  { label: "Turkmenistan", value: "993" },
  { label: "Azerbaijan", value: "994" },
  { label: "Georgia", value: "995" },
  { label: "Kyrgyzstan", value: "996" },
  { label: "Uzbekistan", value: "998" },
];

export const statesKeyValue = [
  { label: "Alabama (AL), USA", value: "USA_AL" },
  { label: "Alaska (AK), USA", value: "USA_AK" },
  { label: "Arizona (AZ), USA", value: "USA_AZ" },
  { label: "Arkansas (AR), USA", value: "USA_AR" },
  { label: "California (CA), USA", value: "USA_CA" },
  { label: "Colorado (CO), USA", value: "USA_CO" },
  { label: "Connecticut (CT), USA", value: "USA_CT" },
  { label: "Delaware (DE), USA", value: "USA_DE" },
  { label: "District of Columbia (DC), USA", value: "USA_DC" },
  { label: "Florida (FL), USA", value: "USA_FL" },
  { label: "Georgia (GA), USA", value: "USA_GA" },
  { label: "Hawaii (HI), USA", value: "USA_HI" },
  { label: "Idaho (ID), USA", value: "USA_ID" },
  { label: "Illinois (IL), USA", value: "USA_IL" },
  { label: "Indiana (IN), USA", value: "USA_IN" },
  { label: "Iowa (IA), USA", value: "USA_IA" },
  { label: "Kansas (KS), USA", value: "USA_KS" },
  { label: "Kentucky (KY), USA", value: "USA_KY" },
  { label: "Louisiana (LA), USA", value: "USA_LA" },
  { label: "Maine (ME), USA", value: "USA_ME" },
  { label: "Maryland (MD), USA", value: "USA_MD" },
  { label: "Massachusetts (MA), USA", value: "USA_MA" },
  { label: "Michigan (MI), USA", value: "USA_MI" },
  { label: "Minnesota (MN), USA", value: "USA_MN" },
  { label: "Mississippi (MS), USA", value: "USA_MS" },
  { label: "Missouri (MO), USA", value: "USA_MO" },
  { label: "Montana (MT), USA", value: "USA_MT" },
  { label: "Nebraska (NE), USA", value: "USA_NE" },
  { label: "Nevada (NV), USA", value: "USA_NV" },
  { label: "New Hampshire (NH), USA", value: "USA_NH" },
  { label: "New Jersey (NJ), USA", value: "USA_NJ" },
  { label: "New Mexico (NM), USA", value: "USA_NM" },
  { label: "New York (NY), USA", value: "USA_NY" },
  { label: "North Carolina (NC), USA", value: "USA_NC" },
  { label: "North Dakota (ND), USA", value: "USA_ND" },
  { label: "Ohio (OH), USA", value: "USA_OH" },
  { label: "Oklahoma (OK), USA", value: "USA_OK" },
  { label: "Oregon (OR), USA", value: "USA_OR" },
  { label: "Pennsylvania (PA), USA", value: "USA_PA" },
  { label: "Rhode Island (RI), USA", value: "USA_RI" },
  { label: "South Carolina (SC), USA", value: "USA_SC" },
  { label: "South Dakota (SD), USA", value: "USA_SD" },
  { label: "Tennessee (TN), USA", value: "USA_TN" },
  { label: "Texas (TX), USA", value: "USA_TX" },
  { label: "Utah (UT), USA", value: "USA_UT" },
  { label: "Vermont (VT), USA", value: "USA_VT" },
  { label: "Virginia (VA), USA", value: "USA_VA" },
  { label: "Washington (WA), USA", value: "USA_WA" },
  { label: "West Virginia (WV), USA", value: "USA_WV" },
  { label: "Wisconsin (WI), USA", value: "USA_WI" },
  { label: "Wyoming (WY), USA", value: "USA_WY" },
  { label: "Aguascalientes (AGS), Mexico", value: "MEX_AG" },
  { label: "Baja California (BC), Mexico", value: "MEX_BC" },
  { label: "Baja California Sur (BS), Mexico", value: "MEX_BS" },
  { label: "Chihuahua (CHIH), Mexico", value: "MEX_CH" },
  { label: "Coahuila (COAH), Mexico", value: "MEX_CL" },
  { label: "Colima (COL), Mexico", value: "MEX_CM" },
  { label: "Chiapas (CHIS), Mexico", value: "MEX_CO" },
  { label: "Mexico City (CDMX), Mexico", value: "MEX_CS" },
  { label: "Durango (DGO), Mexico", value: "MEX_DF" },
  { label: "Durango (DGO), Mexico", value: "MEX_DG" },
  { label: "Guerrero (GRO), Mexico", value: "MEX_GR" },
  { label: "Guanajuato (GTO), Mexico", value: "MEX_GT" },
  { label: "Hidalgo (HGO), Mexico", value: "MEX_HG" },
  { label: "Jalisco (JAL), Mexico", value: "MEX_JA" },
  { label: "Mexico State (MEX), Mexico", value: "MEX_ME" },
  { label: "Michoacán (MICH), Mexico", value: "MEX_MI" },
  { label: "Morelos (MOR), Mexico", value: "MEX_MO" },
  { label: "Nayarit (NAY), Mexico", value: "MEX_NA" },
  { label: "Nuevo León (NL), Mexico", value: "MEX_NL" },
  { label: "Oaxaca (OAX), Mexico", value: "MEX_OA" },
  { label: "Puebla (PUE), Mexico", value: "MEX_PB" },
  { label: "Querétaro (QRO), Mexico", value: "MEX_QE" },
  { label: "Quintana Roo (QROO), Mexico", value: "MEX_QR" },
  { label: "Sinaloa (SIN), Mexico", value: "MEX_SI" },
  { label: "San Luis Potosí (SLP), Mexico", value: "MEX_SL" },
  { label: "Sonora (SON), Mexico", value: "MEX_SO" },
  { label: "Tabasco (TAB), Mexico", value: "MEX_TB" },
  { label: "Tamaulipas (TAMPS), Mexico", value: "MEX_TL" },
  { label: "Tlaxcala (TLAX), Mexico", value: "MEX_TM" },
  { label: "Veracruz (VER), Mexico", value: "MEX_VE" },
  { label: "Yucatán (YUC), Mexico", value: "MEX_YU" },
  { label: "Zacatecas (ZAC), Mexico", value: "MEX_ZA" },
  { label: "Alberta (AB), Canada", value: "CAN_AB" },
  { label: "British Columbia (BC), Canada", value: "CAN_BC" },
  { label: "Manitoba (MB), Canada", value: "CAN_MB" },
  { label: "New Brunswick (NB), Canada", value: "CAN_NB" },
  { label: "Newfoundland and Labrador (NL), Canada", value: "CAN_NL" },
  { label: "Nova Scotia (NS), Canada", value: "CAN_NS" },
  { label: "Northwest Territories (NT), Canada", value: "CAN_NT" },
  { label: "Nunavut (NU), Canada", value: "CAN_NU" },
  { label: "Ontario (ON), Canada", value: "CAN_ON" },
  { label: "Prince Edward Island (PE), Canada", value: "CAN_PE" },
  { label: "Quebec (QC), Canada", value: "CAN_QC" },
  { label: "Saskatchewan (SK), Canada", value: "CAN_SK" },
  { label: "Yukon (YT), Canada", value: "CAN_YT" },
  { label: "Agrigento (AG), Italy", value: "ITA_AG" },
  { label: "Alessandria (AL), Italy", value: "ITA_AL" },
  { label: "Ancona (AN), Italy", value: "ITA_AN" },
  { label: "Aosta (AO), Italy", value: "ITA_AO" },
  { label: "Arezzo (AR), Italy", value: "ITA_AR" },
  { label: "Ascoli Piceno (AP), Italy", value: "ITA_AP" },
  { label: "Asti (AT), Italy", value: "ITA_AT" },
  { label: "Avellino (AV), Italy", value: "ITA_AV" },
  { label: "Bari (BA), Italy", value: "ITA_BA" },
  { label: "Belluno (BL), Italy", value: "ITA_BL" },
  { label: "Benevento (BN), Italy", value: "ITA_BN" },
  { label: "Bergamo (BG), Italy", value: "ITA_BG" },
  { label: "Biella (BI), Italy", value: "ITA_BI" },
  { label: "Bologna (BO), Italy", value: "ITA_BO" },
  { label: "Bolzano (BZ), Italy", value: "ITA_BZ" },
  { label: "Brescia (BS), Italy", value: "ITA_BS" },
  { label: "Brindisi (BR), Italy", value: "ITA_BR" },
  { label: "Cagliari (CA), Italy", value: "ITA_CA" },
  { label: "Caltanissetta (CL), Italy", value: "ITA_CL" },
  { label: "Campobasso (CB), Italy", value: "ITA_CB" },
  { label: "Carbonia-Iglesias (CI), Italy", value: "ITA_CE" },
  { label: "Caserta (CE), Italy", value: "ITA_CT" },
  { label: "Catania (CT), Italy", value: "ITA_CZ" },
  { label: "Catanzaro (CZ), Italy", value: "ITA_CH" },
  { label: "Como (CO), Italy", value: "ITA_CO" },
  { label: "Cosenza (CS), Italy", value: "ITA_CS" },
  { label: "Cremona (CR), Italy", value: "ITA_CR" },
  { label: "Crotone (KR), Italy", value: "ITA_KR" },
  { label: "Cuneo (CN), Italy", value: "ITA_CN" },
  { label: "Enna (EN), Italy", value: "ITA_EN" },
  { label: "Ferrara (FE), Italy", value: "ITA_FE" },
  { label: "Florence (FI), Italy", value: "ITA_FI" },
  { label: "Foggia (FG), Italy", value: "ITA_FG" },
  { label: "Forlì-Cesena (FC), Italy", value: "ITA_FC" },
  { label: "Foggia (FO), Italy", value: "ITA_FO" },
  { label: "Frosinone (FR), Italy", value: "ITA_FR" },
  { label: "Genoa (GE), Italy", value: "ITA_GE" },
  { label: "Gorizia (GO), Italy", value: "ITA_GO" },
  { label: "Grosseto (GR), Italy", value: "ITA_GR" },
  { label: "Imperia (IM), Italy", value: "ITA_IM" },
  { label: "Isernia (IS), Italy", value: "ITA_IS" },
  { label: "La Spezia (SP), Italy", value: "ITA_SP" },
  { label: "Aquila (AQ), Italy", value: "ITA_AQ" },
  { label: "Latina (LT), Italy", value: "ITA_LT" },
  { label: "Lecce (LE), Italy", value: "ITA_LE" },
  { label: "Lecco (LC), Italy", value: "ITA_LC" },
  { label: "Livorno (LI), Italy", value: "ITA_LI" },
  { label: "Lodi (LO), Italy", value: "ITA_LO" },
  { label: "Lucca (LU), Italy", value: "ITA_LU" },
  { label: "Monza and Brianza (MB), Italy", value: "ITA_MB" },
  { label: "Mantua (MN), Italy", value: "ITA_MN" },
  { label: "Massa and Carrara (MS), Italy", value: "ITA_MS" },
  { label: "Matera (MT), Italy", value: "ITA_MT" },
  { label: "Messina (ME), Italy", value: "ITA_ME" },
  { label: "Milan (MI), Italy", value: "ITA_MI" },
  { label: "Modena (MO), Italy", value: "ITA_MO" },
  { label: "Naples (NA), Italy", value: "ITA_NA" },
  { label: "Novara (NO), Italy", value: "ITA_NO" },
  { label: "Nuwara Eliya (NU), Italy", value: "ITA_NU" },
  { label: "Oristano (OR), Italy", value: "ITA_OR" },
  { label: "Padua (PD), Italy", value: "ITA_PD" },
  { label: "Parma (PA), Italy", value: "ITA_PA" },
  { label: "Piacenza (PR), Italy", value: "ITA_PR" },
  { label: "Pavia (PV), Italy", value: "ITA_PV" },
  { label: "Perugia (PG), Italy", value: "ITA_PG" },
  { label: "Pescara (PE), Italy", value: "ITA_PS" },
  { label: "Pistoia (PT), Italy", value: "ITA_PU" },
  { label: "Pisa (PI), Italy", value: "ITA_PE" },
  { label: "Piacenza (PC), Italy", value: "ITA_PC" },
  { label: "Pisa (PI), Italy", value: "ITA_PI" },
  { label: "Pordenone (PN), Italy", value: "ITA_PT" },
  { label: "Potenza (PZ), Italy", value: "ITA_PZ" },
  { label: "Prato (PO), Italy", value: "ITA_PO" },
  { label: "Ragusa (RG), Italy", value: "ITA_RG" },
  { label: "Ravenna (RA), Italy", value: "ITA_RA" },
  { label: "Reggio Calabria (RC), Italy", value: "ITA_RC" },
  { label: "Reggio Emilia (RE), Italy", value: "ITA_RE" },
  { label: "Rieti (RI), Italy", value: "ITA_RI" },
  { label: "Rimini (RN), Italy", value: "ITA_RN" },
  { label: "Rome (RM), Italy", value: "ITA_RM" },
  { label: "Rovigo (RO), Italy", value: "ITA_RO" },
  { label: "Salerno (SA), Italy", value: "ITA_SA" },
  { label: "San Marino (SM), Italy", value: "ITA_SM" },
  { label: "Sassari (SS), Italy", value: "ITA_SS" },
  { label: "Savona (SV), Italy", value: "ITA_SV" },
  { label: "Siena (SI), Italy", value: "ITA_SI" },
  { label: "Siracusa (SR), Italy", value: "ITA_SR" },
  { label: "Sondrio (SO), Italy", value: "ITA_SO" },
  { label: "Taranto (TA), Italy", value: "ITA_TA" },
  { label: "Teramo (TE), Italy", value: "ITA_TE" },
  { label: "Terni (TR), Italy", value: "ITA_TR" },
  { label: "Turin (TO), Italy", value: "ITA_TO" },
  { label: "Trapani (TP), Italy", value: "ITA_TP" },
  { label: "Trento (TN), Italy", value: "ITA_TN" },
  { label: "Treviso (TV), Italy", value: "ITA_TV" },
  { label: "Trieste (TS), Italy", value: "ITA_TS" },
  { label: "Udine (UD), Italy", value: "ITA_UD" },
  { label: "Varese (VA), Italy", value: "ITA_VA" },
  { label: "Venice (VE), Italy", value: "ITA_VE" },
  { label: "Verbano-Cusio-Ossola (VB), Italy", value: "ITA_VB" },
  { label: "Vercelli (VC), Italy", value: "ITA_VC" },
  { label: "Verona (VR), Italy", value: "ITA_VR" },
  { label: "Vibo Valentia (VV), Italy", value: "ITA_VV" },
  { label: "Vicenza (VI), Italy", value: "ITA_VI" },
  { label: "Viterbo (VT), Italy", value: "ITA_VT" },
  { label: "Other (OT), Italy", value: "ITA_OT" },
];

export const countriesLabelValuePair = [
  { label: "United States", value: "USA" },
  { label: "Mexico", value: "MEX" },
  { label: "Canada", value: "CAN" },
  { label: "Italy", value: "ITA" },
];



export const intlCodesKeyValue = [
  { label: "United States (1)", value: "1" },
  { label: "Russia (7)", value: "7" },
  { label: "Egypt (20)", value: "20" },
  { label: "South Africa (27)", value: "27" },
  { label: "Greece (30)", value: "30" },
  { label: "Netherlands (31)", value: "31" },
  { label: "Belgium (32)", value: "32" },
  { label: "France (33)", value: "33" },
  { label: "Spain (34)", value: "34" },
  { label: "Hungary (36)", value: "36" },
  { label: "Italy (39)", value: "39" },
  { label: "Romania (40)", value: "40" },
  { label: "Switzerland (41)", value: "41" },
  { label: "Austria (43)", value: "43" },
  { label: "United Kingdom (44)", value: "44" },
  { label: "Denmark (45)", value: "45" },
  { label: "Sweden (46)", value: "46" },
  { label: "Norway (47)", value: "47" },
  { label: "Poland (48)", value: "48" },
  { label: "Germany (49)", value: "49" },
  { label: "Peru (51)", value: "51" },
  { label: "Mexico (52)", value: "52" },
  { label: "Cuba (53)", value: "53" },
  { label: "Argentina (54)", value: "54" },
  { label: "Brazil (55)", value: "55" },
  { label: "Chile (56)", value: "56" },
  { label: "Colombia (57)", value: "57" },
  { label: "Venezuela (58)", value: "58" },
  { label: "Malaysia (60)", value: "60" },
  { label: "Australia (61)", value: "61" },
  { label: "Indonesia (62)", value: "62" },
  { label: "Philippines (63)", value: "63" },
  { label: "New Zealand (64)", value: "64" },
  { label: "Singapore (65)", value: "65" },
  { label: "Thailand (66)", value: "66" },
  { label: "Japan (81)", value: "81" },
  { label: "South Korea (82)", value: "82" },
  { label: "Vietnam (84)", value: "84" },
  { label: "China (86)", value: "86" },
  { label: "Turkey (90)", value: "90" },
  { label: "India (91)", value: "91" },
  { label: "Pakistan (92)", value: "92" },
  { label: "Afghanistan (93)", value: "93" },
  { label: "Sri Lanka (94)", value: "94" },
  { label: "Myanmar (95)", value: "95" },
  { label: "Iran (98)", value: "98" },
  { label: "Morocco (212)", value: "212" },
  { label: "Algeria (213)", value: "213" },
  { label: "Tunisia (216)", value: "216" },
  { label: "Libya (218)", value: "218" },
  { label: "Gambia (220)", value: "220" },
  { label: "Senegal (221)", value: "221" },
  { label: "Mauritania (222)", value: "222" },
  { label: "Mali (223)", value: "223" },
  { label: "Guinea (224)", value: "224" },
  { label: "Ivory Coast (225)", value: "225" },
  { label: "Burkina Faso (226)", value: "226" },
  { label: "Niger (227)", value: "227" },
  { label: "Togo (228)", value: "228" },
  { label: "Benin (229)", value: "229" },
  { label: "Mauritius (230)", value: "230" },
  { label: "Liberia (231)", value: "231" },
  { label: "Sierra Leone (232)", value: "232" },
  { label: "Ghana (233)", value: "233" },
  { label: "Nigeria (234)", value: "234" },
  { label: "Central African Republic (236)", value: "236" },
  { label: "Cameroon (237)", value: "237" },
  { label: "Cape Verde (238)", value: "238" },
  { label: "Sao Tome and Principe (239)", value: "239" },
  { label: "Equatorial Guinea (241)", value: "241" },
  { label: "Gabon (242)", value: "242" },
  { label: "Republic of the Congo (243)", value: "243" },
  { label: "Democratic Republic of the Congo (243)", value: "243" },
  { label: "Angola (244)", value: "244" },
  { label: "Guinea-Bissau (245)", value: "245" },
  { label: "Seychelles (248)", value: "248" },
  { label: "Sudan (249)", value: "249" },
  { label: "Rwanda (250)", value: "250" },
  { label: "Ethiopia (251)", value: "251" },
  { label: "Somalia (252)", value: "252" },
  { label: "Djibouti (253)", value: "253" },
  { label: "Kenya (254)", value: "254" },
  { label: "Tanzania (255)", value: "255" },
  { label: "Uganda (256)", value: "256" },
  { label: "Burundi (257)", value: "257" },
  { label: "Mozambique (258)", value: "258" },
  { label: "Zambia (260)", value: "260" },
  { label: "Madagascar (261)", value: "261" },
  { label: "Reunion (262)", value: "262" },
  { label: "Zimbabwe (263)", value: "263" },
  { label: "Namibia (264)", value: "264" },
  { label: "Malawi (265)", value: "265" },
  { label: "Lesotho (266)", value: "266" },
  { label: "Botswana (267)", value: "267" },
  { label: "Swaziland (268)", value: "268" },
  { label: "Comoros (269)", value: "269" },
  { label: "British Virgin Islands (284)", value: "284" },
  { label: "Saint Helena (290)", value: "290" },
  { label: "Eritrea (291)", value: "291" },
  { label: "Aruba (297)", value: "297" },
  { label: "Faroe Islands (298)", value: "298" },
  { label: "Cayman Islands (345)", value: "345" },
  { label: "Gibraltar (350)", value: "350" },
  { label: "Portugal (351)", value: "351" },
  { label: "Luxembourg (352)", value: "352" },
  { label: "Ireland (353)", value: "353" },
  { label: "Iceland (354)", value: "354" },
  { label: "Albania (355)", value: "355" },
  { label: "Malta (356)", value: "356" },
  { label: "Cyprus (357)", value: "357" },
  { label: "Finland (358)", value: "358" },
  { label: "Bulgaria (359)", value: "359" },
  { label: "Lithuania (370)", value: "370" },
  { label: "Latvia (371)", value: "371" },
  { label: "Estonia (372)", value: "372" },
  { label: "Moldova (373)", value: "373" },
  { label: "Monaco (377)", value: "377" },
  { label: "San Marino (378)", value: "378" },
  { label: "Vatican City (379)", value: "379" },
  { label: "Ukraine (380)", value: "380" },
  { label: "Serbia (381)", value: "381" },
  { label: "Montenegro (382)", value: "382" },
  { label: "Croatia (385)", value: "385" },
  { label: "Slovenia (386)", value: "386" },
  { label: "Bosnia and Herzegovina (387)", value: "387" },
  { label: "North Macedonia (389)", value: "389" },
  { label: "Czech Republic (420)", value: "420" },
  { label: "Slovakia (421)", value: "421" },
  { label: "Liechtenstein (423)", value: "423" },
  { label: "Bermuda (441)", value: "441" },
  { label: "Guantanamo Bay (472)", value: "472" },
  { label: "Falkland Islands (500)", value: "500" },
  { label: "Belize (501)", value: "501" },
  { label: "Guatemala (502)", value: "502" },
  { label: "El Salvador (503)", value: "503" },
  { label: "Honduras (504)", value: "504" },
  { label: "Nicaragua (505)", value: "505" },
  { label: "Costa Rica (506)", value: "506" },
  { label: "Panama (507)", value: "507" },
  { label: "Saint Pierre and Miquelon (508)", value: "508" },
  { label: "Haiti (509)", value: "509" },
  { label: "Sint Maarten (534)", value: "534" },
  { label: "Saint Barthelemy (590)", value: "590" },
  { label: "Bolivia (591)", value: "591" },
  { label: "Guyana (592)", value: "592" },
  { label: "Ecuador (593)", value: "593" },
  { label: "French Guiana (594)", value: "594" },
  { label: "Paraguay (595)", value: "595" },
  { label: "Martinique (596)", value: "596" },
  { label: "Suriname (597)", value: "597" },
  { label: "Uruguay (598)", value: "598" },
  { label: "Curacao (599)", value: "599" },
  { label: "Turks and Caicos Islands (649)", value: "649" },
  { label: "Montserrat (664)", value: "664" },
  { label: "East Timor (670)", value: "670" },
  { label: "Northern Mariana Islands (671)", value: "671" },
  { label: "Christmas Island (672)", value: "672" },
  { label: "Brunei (673)", value: "673" },
  { label: "Nauru (674)", value: "674" },
  { label: "Papua New Guinea (675)", value: "675" },
  { label: "Tonga (676)", value: "676" },
  { label: "Solomon Islands (677)", value: "677" },
  { label: "Vanuatu (678)", value: "678" },
  { label: "Fiji (679)", value: "679" },
  { label: "Palau (680)", value: "680" },
  { label: "Cook Islands (682)", value: "682" },
  { label: "Niue (683)", value: "683" },
  { label: "American Samoa (684)", value: "684" },
  { label: "Samoa (685)", value: "685" },
  { label: "New Caledonia (687)", value: "687" },
  { label: "French Polynesia (689)", value: "689" },
  { label: "Micronesia (691)", value: "691" },
  { label: "Marshall Islands (692)", value: "692" },
  { label: "Saint Lucia (758)", value: "758" },
  { label: "Dominica (767)", value: "767" },
  { label: "Puerto Rico (787)", value: "787" },
  { label: "Dominican Republic (809)", value: "809" },
  { label: "North Korea (850)", value: "850" },
  { label: "Hong Kong (852)", value: "852" },
  { label: "Macao (853)", value: "853" },
  { label: "Cambodia (855)", value: "855" },
  { label: "Laos (856)", value: "856" },
  { label: "North Korea (Democratic People's Republic of Korea) (868)", value: "868" },
  { label: "Saint Kitts and Nevis (869)", value: "869" },
  { label: "Sint Eustatius (875)", value: "875" },
  { label: "Bangladesh (880)", value: "880" },
  { label: "Taiwan (886)", value: "886" },
  { label: "Maldives (960)", value: "960" },
  { label: "Lebanon (961)", value: "961" },
  { label: "Jordan (962)", value: "962" },
  { label: "Syria (963)", value: "963" },
  { label: "Iraq (964)", value: "964" },
  { label: "Kuwait (965)", value: "965" },
  { label: "Saudi Arabia (966)", value: "966" },
  { label: "Yemen (967)", value: "967" },
  { label: "Oman (968)", value: "968" },
  { label: "Palestine (970)", value: "970" },
  { label: "United Arab Emirates (971)", value: "971" },
  { label: "Israel (972)", value: "972" },
  { label: "Bahrain (973)", value: "973" },
  { label: "Qatar (974)", value: "974" },
  { label: "Bhutan (975)", value: "975" },
  { label: "Mongolia (976)", value: "976" },
  { label: "Nepal (977)", value: "977" },
  { label: "Tajikistan (992)", value: "992" },
  { label: "Turkmenistan (993)", value: "993" },
  { label: "Azerbaijan (994)", value: "994" },
  { label: "Georgia (995)", value: "995" },
  { label: "Kyrgyzstan (996)", value: "996" },
  { label: "Uzbekistan (998)", value: "998" }
]

export const LOGIN_FORM_OPTIONS = {
  login: 'LOGIN',
  signup: 'SIGNUP',
  default: 'LOGIN' 
}

export const introTourScreen = {
  title: "Quick tour",
  description:
    "Welcome, would you like to have a quick guided tour for Xailes?",
  buttons: [
    { label: "Skip", action: "skip" },
    { label: "Get Started", action: "next" },
  ],
  image: IMGQuickTourImage
};

export const dataSourceTourScreen =   {
  title: "Link new data source",
  description: "Click on the data source listed above to add it.",
  buttons: [
    { label: "Skip", action: "skip" },
    { label: "Next", action: "next" },
  ],
  step: 1,
  arrowPosition: 'arrow-top'
};

export const localFilesTourScreen = {
  title: "Link Local Files",
  description:
    "Drag and drop files here, or browse to upload, to start a chat using files. Supported formats include .pdf, .csv, .xls, and .xlsx.",
  buttons: [
    { label: "Skip", action: "skip" },
    { label: "Next", action: "next" },
  ],
  step: 2,
  arrowPosition: 'arrow-bottom'
};

export const WooCommerceTourScreen = {
  title: "Select the duration",
  description:
  "To analyze data, choose a duration from the options provided or enter a custom date to start a new Chat using Start Chat button.",
  buttons: [
    // { label: "Done", action: "done" },
    { label: "Next", action: "next" },
  ],
  step: 3,
  arrowPosition: 'arrow-left'
};

//uploadfirstfiletour

export const UploadFirstFileTourScreen = {
  title: "Upload local files",
  description:
    "To start chat using files drag and drop files here or browse files to upload. Supported formats include .pdf, .csv, .xls and .xlsx.",
  buttons: [
    { label: "Skip", action: "skip" },
    { label: "Next", action: "next" },
  ],
  step:1,
  arrowPosition: 'arrow-left'
};

export const selectedFilesTourScreen = {
  title: "Uploaded files",
    description:
      "This displays the uploaded file in the chat.",
    buttons: [
      { label: "Done", action: "done" },
    ],
    step:2,
    arrowPosition: 'arrow-top'
};

//chats->datasource

export const promptsTourScreen = {
  title: "Type prompts to chat",
    description:
      "Enter prompts or queries in this section to receive desired responses. Suggested prompts are provided to save time.",
    buttons: [
      { label: "Skip", action: "skip" },
      { label: "Next", action: "next" },
    ],
    step: 1,
    arrowPosition: 'arrow-bottom'
};
export const selectedDataSourceTourScreen = {
  title: "Selected data source",
    description:
      "This displays the current or default data source selected.",
    buttons: [
      { label: "Skip", action: "skip" },
      { label: "Next", action: "next" },
    ],
    step: 2,
    arrowPosition: 'arrow-top'
};

export const sideBarchatTourScreen= {
  title: "Chats",
    description:
      "This section displays recent chats. Click on View all button for displaying previous chats. ",
    buttons: [
      { label: "Skip", action: "skip" },
      { label: "Next", action: "next" },
    ],
    step: 3,
    arrowPosition: 'arrow-left-top'
};

export const startNewchatTourScreen= {
  title: "Chats",
    description:
      "Tap on this + icon to start a new chat.",
    buttons: [
      { label: "Skip", action: "skip" },
      { label: "Next", action: "next" },
    ],
    step: 4,
    arrowPosition: 'arrow-left-top'
};
export const searchchatTourScreen= {
  title: "Search the recent chat",
    description:
      "Tap to search recent chat by typing the chat name or the keywords used in the chat. ",
    buttons: [
      { label: "Skip", action: "skip" },
      { label: "Next", action: "next" },
    ],
    step: 5,
    arrowPosition: 'arrow-left-top'
};
export const uploadTourScreen= {
  title: "Uploaded Files",
    description:
      "This section displays all the files uploaded during chats conducted with local files.",
    buttons: [
      { label: "Done", action: "done" },
    ],
    step: 6,
    arrowPosition: 'arrow-left'
};

//Delete file screens

export const fileOptionsTourScreen= {
  title: "File options",
    description:
      "Start a new chat with the same file by selecting Analyze in New Chat. Download files using Download Files, and delete them with Delete Files.",
    buttons: [
      { label: "Skip", action: "skip" },
      { label: "Next", action: "next" },
    ],
    step:1,
    arrowPosition: 'arrow-top'
};
export const fileFilterTourScreen= {
  title: "Search using filters",
    description:
      "Search for files by time range by choosing a duration from the available options or entering a custom date. Filters will be reset using Reset and applied by Apply",
    buttons: [
      { label: "Done", action: "done" },
    ],
    step:2,
    arrowPosition: 'arrow-left'
};

//chatHistory
export const chatHistoryOptionsTourScreen = {
  title: "Chat options",
    description:
      "These chats can be deleted using  Delete Chat.",
    buttons: [
      { label: "Skip", action: "skip" },
      { label: "Next", action: "next" },
    ],
    step: 1,
    arrowPosition: 'arrow-top'
};
export const chatHistoryFilterTourScreen = {
  title: "Search using filters",
    description:
      "Search for file data sources and uploaded files by time range. Select a duration from the options provided or enter a custom date. Filters will be reset using Reset and applied by Apply",
    buttons: [
      { label: "Done", action: "done" },
    ],
    step: 2,
    arrowPosition: 'arrow-left'
};

