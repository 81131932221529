import React, { useEffect, useState } from "react";
import "./dateFilterWooCommerce.scss";
// import gpModalLogo from '../../../images/xailsImages/gpModalLogo.svg'
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import {
  IMGConnect,
  IMGLoader,
  IMGMonitoring,
  ImgCrossIconSm,
  ImgReload,
  ImgWooLogo,
} from "../../images";
import useWooCommerce from "../../hooks/useWooCommerce";
import { useDispatch } from "react-redux";
import { update } from "../../utils/itemSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useParams } from "react-router-dom";
import { setDataSourceName, setFirstChat } from "../../utils/dataSourceSlice";
// import monitoring from '../../../images/xailsImages/monitoring.svg'

const DateFilterWooCommerce = ({}) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [syncDays, setSyncDays] = useState("");
  const [dataIntegrationId, setDataIntegrationId] = useState("");
  const [error, setError] = useState({
    startDateError: "",
    endDateError: "",
  });
  const { dateDataHandler, getDataIntigrationId } = useWooCommerce();
  const dispatch = useDispatch();
  const { storeurl } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    switch (selectedFilter) {
      case "1W":
        setSyncDays(7);
        break;
      case "1M":
        setSyncDays(30);
        break;
      case "3M":
        setSyncDays(90);
        break;
      case "6M":
        setSyncDays(180);
        break;
      case "1Y":
        setSyncDays(365);
        break;
      default:
        break;
    }
  }, [selectedFilter]);

  const filters = ["1W", "1M", "3M", "6M", "1Y", "Custom-Range"];

  useEffect(() => {
    setDataIntegrationId(storeurl);
  }, [storeurl]);

  const handleFilterClick = (filter) => {
    if (selectedFilter === filter) {
      setSelectedFilter("");
      setSyncDays("");
      setStartDate("");
      setEndDate("");
      setError({
        startDateError: "",
        endDateError: "",
      });
    } else {
      if (filter !== "Custom-Range") {
        setStartDate("");
        setEndDate("");
        setError({
          startDateError: "",
          endDateError: "",
        });
      }
      setSelectedFilter(filter);
    }
  };
  
  //   const handleDateChange = (e, setDate, setError) => {
  //     const inputDate = e.target.value;
  //     setDate(inputDate);
  //   };

  // const handleDateChange = (value) => {
  //   const date = new Date(value);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based months
  //   const day = String(date.getDate()).padStart(2, "0");
  //   // setSyncDays(null);
  //   const formattedDate = `${year}-${month}-${day}`;
  //   return formattedDate;
  // };
  
  const validateDate = (input) => {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    return dateRegex.test(input);
  };

  const handleDateChange = (e, setDate, errorName, compareDate) => {
    let inputDate = e.target.value;
    const inputDateLength = e.target.defaultValue.length; 
    const isDeletion = inputDate.length < inputDateLength;
    if ((inputDate.length === 2 || inputDate.length === 5) && !isDeletion) {
        inputDate += '-';
    }
    setDate(inputDate);
    const [month, day, year] = inputDate.split('-');
    const currentDate = dayjs();
    const parsedDate = dayjs(inputDate, "MM-DD-YYYY");
    const currentYear = currentDate.year();
    const previousYear = currentYear - 1;
    const isValidMonth = month >= 1 && month <= 12;
    const isValidDay = day >= 1 && day <= 31;
    const isValidYear = year == currentYear || year == previousYear;

    if (validateDate(inputDate)) {
      if (!isValidMonth) {
        setError((prevError) => ({
            ...prevError,
            [errorName]: "Please enter a valid month",
        }));
      }
      else if (!isValidDay) {
          setError((prevError) => ({
              ...prevError,
              [errorName]: "Please enter a valid date",
          }));
      }
      else if (!isValidYear) {
          setError((prevError) => ({
              ...prevError,
              [errorName]: "Please enter a valid year",
          }));
      }
      else if (parsedDate.isAfter(currentDate)) {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "Date cannot be in the future",
        }));
      } 
      else if (errorName === "startDateError" && compareDate && parsedDate.isAfter(dayjs(compareDate))) {
        setError((prevError) => ({
          ...prevError,
          startDateError: "Start date cannot be after end date",
        }));
      } else if (errorName === "endDateError" && compareDate && parsedDate.isBefore(dayjs(compareDate))) {
        setError((prevError) => ({
          ...prevError,
          endDateError: "End date cannot be before start date",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "",
        }));
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        [errorName]: "Please enter a date in the format MM-DD-YYYY",
      }));
    }
  };

  // const getDataIntegrationIdHelper = async () => {
  //   const dataId = await getDataIntigrationId(storeurl);
  //   setDataIntegrationId(dataId);
  // };

  const convertToBackendFormat = (date) => {
    if(!date){
      return "";
    }
    const [month, day, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };
  
  const handleConnectClick = async () => {
    if (error.startDateError || error.endDateError) {
      return;
    }
    let formattedStartDate= "";
    let formattedEndDate= "" ;
    if(startDate && endDate ){
      formattedStartDate = convertToBackendFormat(startDate);
      formattedEndDate = convertToBackendFormat(endDate);
    }
    // const response = await dateDataHandler({
    //   dataIntegrationId,
    //   startDate,
    //   endDate,
    //   syncDays,
    // });
    // const date1 = new Date(endDate);
    // const date2 = new Date(startDate);
    // Calculate the difference in milliseconds
    // const diffInMilliseconds = date2 - date1;

    // Convert the difference from milliseconds to days
    // const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    // const stringDate = Math.abs(diffInDays).toString();
    // if (syncDays === "") {
    //   setSyncDays(stringDate + " Days");
    // }

    // if (isDataSync) {
    //   setDataIntegrationId(storeurl);
    //   handleAnalyzing();
    // }
    const userFilterInfo = {
      start_date: formattedStartDate || "",
      end_date: formattedEndDate || "",
      duration: selectedFilter !== "Custom-Range" ? syncDays : "",
      data_intigration_id: dataIntegrationId,
    };
    const dataSource = {
      datasource_identifier: "WOOCOMMERCE",
      start_date: formattedStartDate || "",
      end_date: formattedEndDate || "",
      no_of_days: selectedFilter !== "Custom-Range" ? syncDays : "",
      data_intigration_id: dataIntegrationId,
    };
    dispatch(setFirstChat(dataSource));
    dispatch(setDataSourceName("WOOCOMMERCE"));
    dispatch(update(userFilterInfo));
    navigate(`/queries/${dataIntegrationId}`);
  };

  const handleClose = () => {
    navigate("/dashboard");
  };

  return (
    <div className="date-filter-commerce-popup">
      <div className="sync-woo-commerce-Container">
        <div className="overall-img-container">
          <div className="connect-img-container">
            <img src={IMGConnect} alt="" />
          </div>
        </div>
        <div className="time-range-filters">
          <span>Analyze with Woocommerce</span>
          <label>Select time range for analysis</label>
          <div className="filters">
            {filters.map((filter, index) => (
              <div
                key={index}
                className={`range-Container ${
                  filter === "Custom-Range" ? "custom" : ""
                } ${selectedFilter === filter ? "selected" : ""}`}
                onClick={() => handleFilterClick(filter)}
              >
                {filter}
              </div>
            ))}
          </div>
        </div>
        <div className="date-range-filter">
          <div className="filter-dates">
            <p>Start Date </p>
            <TextField
              disabled={selectedFilter !== "Custom-Range"}
              value={startDate}
              placeholder="MM-DD-YYYY"
              inputProps={{ maxLength: 10 }}
              onChange={(e) =>
                handleDateChange(e, setStartDate, "startDateError", endDate)
              }
              // error={startDateError}
              sx={{
                width: "100%",
                borderRadius: "10px",
                marginTop: "10px",
                "& input": {
                  "-webkit-appearance": "none",
                  "-moz-appearance": "none",
                  appearance: "none",
                },
              }}
            />
            {<p className="error">{error?.startDateError}</p>}
          </div>
          <div className="filter-dates-end-date">
            <p>End Date </p>
            <TextField
              // label="DD/MM/YYYY"
              value={endDate}
              disabled={selectedFilter !== "Custom-Range"}
              placeholder="MM-DD-YYYY"
              inputProps={{ maxLength: 10 }}
              onChange={(e) =>
                handleDateChange(e, setEndDate, "endDateError", startDate)
              }
              // error={endDateError}
              sx={{
                width: "100%",
                borderRadius: "10px",
                marginTop: "10px",
                "& input": {
                  "-webkit-appearance": "none",
                  "-moz-appearance": "none",
                  appearance: "none",
                },
              }}
            />
            {<p className="error">{error?.endDateError}</p>}
          </div>
        </div>
        {/* <div className="date-range-filter">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              inputFormat="yyyy-MM-dd"
              label="Start Date"
              value={dayjs(startDate)}
              onChange={(newValue) => setStartDate(handleDateChange(newValue))}
              disableFuture={true}
              disabled={selectedFilter !== "Custom-Range"}
              maxDate={dayjs(endDate)}
              sx={{ height: "50%", borderRadius: "10px" }}
            />

            <DatePicker
              inputFormat="yyyy-MM-dd"
              label="End Date"
              value={dayjs(endDate)}
              onChange={(newValue) => setEndDate(handleDateChange(newValue))}
              disableFuture={true}
              disabled={selectedFilter !== "Custom-Range"}
              minDate={dayjs(startDate)}
              sx={{ height: "50%", borderRadius: "10px" }}
            />
          </LocalizationProvider>
        </div> */}
        <div className="btn-container">
          <button
            className="start-chat-btn"
            disabled={
              !((selectedFilter && selectedFilter !== "Custom-Range") ||
                (selectedFilter === "Custom-Range" && 
                  startDate && 
                  endDate && 
                  !error.startDateError && 
                  !error.endDateError
                )
              )
            }
            onClick={handleConnectClick}
          >
            Start Chat
          </button>
        </div>
        {/* render only  if user have chat history with gorila pay  data source*/}
        <div className="monitoring-container" onClick={handleClose}>
          <img src={IMGMonitoring} alt="monitoring" />
          <span>Change Data Source</span>
        </div>
      </div>{" "}
    </div>
  );
};
export default DateFilterWooCommerce;
