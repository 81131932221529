
import { configureStore } from '@reduxjs/toolkit';
import dateFilter from './itemSlice';
import fileSlice from './fileSlice';
import dataSourceSlice from './dataSourceSlice'
const store = configureStore({
  reducer: {
    dateFilterInfo: dateFilter,
    uploadFiles: fileSlice,
    dataSourceSlice:dataSourceSlice,
},
  
});

export default store;



