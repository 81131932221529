import React, { useEffect, useState } from "react";
import "./dashboardTour.scss";
import { IMGQuickTourImage } from "../images";
import { PRODUCT_TOUR_KEY, STORAGE_KEY } from "../config";
import { useLocation, useParams } from "react-router-dom";
import { authorizedotnet } from "../routes/routes";

// const introScreen = {
//   title: "Quick tour",
//   description:
//     "Welcome, Would you like to have a quick guided tour for Xailes?",
//   buttons: [
//     { label: "Skip", action: "skip" },
//     { label: "Get Started", action: "next" },
//   ],
//   image: IMGQuickTourImage
// };

const DashboardTour = ({
  currentStepInfo,
  currentStepIndex,
  setCurrentStepIndex,
  scrollToElement,
  steps,
  flowName,
}) => {
  const [isTourVisible, setIsTourVisible] = useState(true);
  const { storeurl } = useParams();
  const location= useLocation();

  useEffect(() => {
    if ((storeurl && currentStepIndex<0) || (location.pathname.startsWith(authorizedotnet) && currentStepIndex<0)) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
    switch (flowName) {
      case "show_product_tour":
        if (localStorage.getItem(PRODUCT_TOUR_KEY.show_product_tour)) {
          setIsTourVisible(false);
        } else {
          setIsTourVisible(true);
        }
        break;
      case "show_upload_with_file_tour":
        if (localStorage.getItem(PRODUCT_TOUR_KEY.show_upload_with_file_tour)) {
          setIsTourVisible(false);
        } else {
          setIsTourVisible(true);
        }
        break;
      case "show_upload_without_file_tour":
        if (
          localStorage.getItem(PRODUCT_TOUR_KEY.show_upload_without_file_tour)
        ) {
          setIsTourVisible(false);
        } else {
          setIsTourVisible(true);
        }
        break;
      case "show_all_chat_tour":
        if (localStorage.getItem(PRODUCT_TOUR_KEY.show_all_chat_tour)) {
          setIsTourVisible(false);
        } else {
          setIsTourVisible(true);
        }
        break;
      case "show_date_filter_tour":
        if (localStorage.getItem(PRODUCT_TOUR_KEY.show_date_filter_tour)) {
          setIsTourVisible(false);
        } else {
          setIsTourVisible(true);
        }
        break;
      default:
        setIsTourVisible(true); // Default behavior if no match found
        return;
    }
  }, [currentStepIndex]);

  useEffect(() => {
    if (scrollToElement) {
      const element = document.querySelector(scrollToElement);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const handleAction = (action) => {
    if (currentStepIndex === -1 && action === "skip") {
      console.log("i am here to stop all flows");
      setIsTourVisible(false);
    } else if (currentStepIndex !== -1 && action === "skip") {
      setIsTourVisible(false);
    } else if (action === "next") {
      if (currentStepIndex === -1) {
        setCurrentStepIndex(0);
        setIsTourVisible(false);
      } else {
        setCurrentStepIndex((prevIndex) => prevIndex + 1);
      }
    } else if (action === "done") {
      setCurrentStepIndex((prevIndex) => prevIndex + 1);
      setLocalStorageItem(flowName, "Visited");
      setIsTourVisible(false);
    }
  };

  const renderButtons = () => {
    if (currentStepIndex === -1) {
      return (
        <>
          <button className="tour-skip-btn" onClick={handleSkip}>
            Skip
          </button>
          <button
            className="tour-next-btn"
            onClick={() => handleAction("next")}
          >
            Get Started
          </button>
        </>
      );
    } else if (currentStepIndex < steps - 1) {
      return (
        <>
          <button className="tour-skip-btn" onClick={handleSkip}>
            Skip
          </button>
          <button
            className="tour-next-btn"
            onClick={() => handleAction("next")}
          >
            Next
          </button>
        </>
      );
    } else if (currentStepIndex === steps - 1) {
      return (
        <button className="tour-next-btn" onClick={() => handleAction("done")}>
          Done
        </button>
      );
    }
  };

  const setLocalStorageItem = (key, value) => {
    const itemValue = JSON.stringify(value);
    localStorage.setItem(key, itemValue);
  };

  const handleSkip = () => {
    setLocalStorageItem(PRODUCT_TOUR_KEY.show_product_tour, "Visited");
    setLocalStorageItem(PRODUCT_TOUR_KEY.show_upload_with_file_tour, "Visited");
    setLocalStorageItem(
      PRODUCT_TOUR_KEY.show_upload_without_file_tour,
      "Visited"
    );
    setLocalStorageItem(PRODUCT_TOUR_KEY.show_all_chat_tour, "Visited");
    setLocalStorageItem(PRODUCT_TOUR_KEY.show_date_filter_tour, "Visited");
    setIsTourVisible(false);
  };

  return (
    isTourVisible && (
      <div className="tour-container">
        <div className={`tour-content ${currentStepInfo.arrowPosition || ""}`}>
          <div className="tour-title">{currentStepInfo.title}</div>
          {currentStepInfo.image && (
            <img src={currentStepInfo.image} alt="" className="tour-image" />
          )}
          <div className="tour-description">{currentStepInfo.description}</div>
          <div className="tour-footer">
            {currentStepIndex >= 0 && (
              <span className="tooltip-step">
                {currentStepInfo.step} of {steps}
              </span>
            )}
            {renderButtons()}
          </div>
        </div>
      </div>
    )
  );
};

export default DashboardTour;
