import logo from "./logo.svg";
import "./App.css";
import Header from "./commonComponent/Header";
import Footer from "./commonComponent/Footer";
import WelcomePopupComponent from "./onboarding/welcomePopupComponent";
import SigninSignupComponent from "./authentication/signinSignupComponent";
import ForgetPasswordComponent from "./forgotPassword/forgetPasswordComponent";
import NewPasswordComponent from "./forgotPassword/newPasswordComponent";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  accountVerification,
  dateTimeRoute,
  uploadedFiles,
  saved,
  dataSources,
  forgetPassword,
  query,
  querywithChatId,
  registrationSuccess,
  resend,
  verification,
  welcomePopup,
  woocommerce,
  dataSourceChatScreens,
  authorizedotnet,
  dashboard,
  signUp,
  gorillapayLinkedRoute,
  authorizedotnetLinkedRoute,
  chatHistory,
  newChatWithFile,
  resendVerification,
} from "./routes/routes";
import QueryHome from "./query/queryHome";
import { SecureRoute } from "./routes/secureRoutes";
import AccountVerificationComponent from "./components/acoountVerificationComponent";
import VerificationComponent from "./components/emailVerification";
import Dashboard from "./dashboard/dashboard";
import { Provider } from "react-redux";
import store from "./utils/store";
import DataSources from "./query/dataSources";
import DataSourcesIntigration from "./query/dataSourcesIntigration";
import DateTimeSelectComponent from "./query/connectWithDataSources/dateTimeSelectComponent";
import ConnectWithComponent from "./query/connectWithDataSources/connectWithComponent";
import { useEffect, useState } from "react";
import NewChatScreen from "./commonComponent/newChatScreen/NewChatScreen";
import MenuComponent from "./dashboard/menuComponent";
import AuthorizeDotNet from "./dashboard/authorizeDotNet/AuthorizeDotNet";
import NewSignInForm from "./authentication/newAuthComponents/NewSignInForm";
import NewSignupForm from "./authentication/newAuthComponents/NewSignupForm";
import NewVerificationComponent from "./authentication/newAuthComponents/NewVerificationComponent";
import NewAccountVerificationComponent from "./authentication/newAuthComponents/NewAccountVerificationComponent";
import ResendVerification from "./authentication/newAuthComponents/resendVerification/ResendVerification";
import { STORAGE_KEY, USER_CREDENTIALS } from "./config";

function App() {
  const [syncDays, setSyncDays] = useState(null);
  const decodeBase64ToObject = (base64String) => {
    try {
      const decodedString = decodeURIComponent(base64String); 
      const jsonString = atob(decodedString); 
      return JSON.parse(jsonString); 
    } catch (error) {
      console.error('Error decoding', error);
      return null;
    }
  };
  const params = new URLSearchParams(window.location.search);
  const encodedData = params.get('data');
  function setttingItems() {
    if (encodedData) {
      const decodedObject = decodeBase64ToObject(encodedData);
      if (decodedObject.accessToken) {
        localStorage.setItem(STORAGE_KEY.access_token, decodedObject.accessToken);
        localStorage.setItem(STORAGE_KEY.refresh_token, decodedObject.refreshToken);
        localStorage.setItem(USER_CREDENTIALS.USER_EMAIL, decodedObject.email);
        localStorage.setItem(STORAGE_KEY.is_merchant_exist, decodedObject.is_merchant_exist);
        localStorage.setItem(STORAGE_KEY.user_role, decodedObject.user_role);
      }
    }
}

  return (

    <Provider store={store}>
      {setttingItems()}
      <Router>
        <div className="App">
          <SecureRoute />
          <Routes>
            {/* <Route path="/" element={<SigninSignupComponent />} /> */}
            <Route path="/" element={<NewSignInForm />} />
            <Route path={signUp} element={<NewSignupForm />} />
            {/* <Route
              path={accountVerification}
              element={<AccountVerificationComponent />}
            /> */}
            {/* <Route
              path={registrationSuccess}
              element={<VerificationComponent />}
            /> */}
            <Route
              path={accountVerification}
              element={<NewAccountVerificationComponent />}
            />
            <Route
              path={resendVerification}
              element={<ResendVerification />}
            />
            <Route
              path={registrationSuccess}
              element={<NewVerificationComponent />}
            />
            <Route
              path={forgetPassword}
              element={<ForgetPasswordComponent />}
            />
            <Route path={resend} element={<NewPasswordComponent />} />
            <Route path={authorizedotnet} element={<MenuComponent />} />
            <Route path={welcomePopup} element={<WelcomePopupComponent />} />
            <Route path={dashboard} element={<MenuComponent />} />
            <Route path={query} element={<MenuComponent />} />
            <Route path={saved} element={<MenuComponent />} />
            <Route path={uploadedFiles} element={<MenuComponent />} />
            <Route path={chatHistory} element={<MenuComponent />} />
            <Route path={dataSources} element={<MenuComponent />} />
            <Route path={querywithChatId} element={<MenuComponent />} />
            <Route path={"/dashboard"} element={<MenuComponent />} />
            <Route path={newChatWithFile} element={<MenuComponent />} />
            <Route
              path={dataSourceChatScreens}
              element={
                <MenuComponent setSyncDays={setSyncDays} syncDays={syncDays} />
              }
            />

            <Route
              path={dateTimeRoute}
              element={
                <MenuComponent
                  showDateTimeComponent={true}
                  setSyncDays={setSyncDays}
                  syncDays={syncDays}
                />
                
              }
              
            />
            
            <Route
              path={gorillapayLinkedRoute}
              element={
                <MenuComponent
                  showDateTimeComponent={true}
                  setSyncDays={setSyncDays}
                  syncDays={syncDays}
                />
              }
              
            />
               <Route
              path={authorizedotnetLinkedRoute}
              element={
                <MenuComponent
                  showDateTimeComponent={true}
                  setSyncDays={setSyncDays}
                  syncDays={syncDays}
                />
              }
              
            />
            {/* <Route path={'/datasource'} element={<DataSourcesIntigration/>} /> */}
            {/* <WelcomePopupComponent/> */}
            {/* <SigninSignupComponent /> */}
            {/* <ForgetPasswordComponent/> */}
            {/* <NewPasswordComponent/> */}
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
