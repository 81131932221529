import React from 'react';
import './LoadingAnimation.scss'; // Import the SCSS file for styling

const LoadingAnimation = () => {
  return (
    <div className="loading-animation">
      <svg className="loading-circle" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
        <circle cx="2" cy="2" r="2" fill="#465CA9" fillOpacity="0.2">
          <animate attributeName="fill-opacity" values="0.2; 0.6; 1; 0.6; 0.2" dur="2s" repeatCount="indefinite" />
          <animateTransform attributeName="transform" type="translate" values="0 -1; 0 1; 0 -1" dur="1s" repeatCount="indefinite"/>
        </circle>
      </svg>
      <svg className="loading-circle" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
        <circle cx="2" cy="2" r="2" fill="#465CA9" fillOpacity="0.2">
          <animate attributeName="fill-opacity" values="0.6; 1; 0.6; 0.2; 0.6" dur="2s" repeatCount="indefinite" />
          <animateTransform attributeName="transform" type="translate" values="0 1; 0 -1; 0 1" dur="1s" repeatCount="indefinite"/>
        </circle>
      </svg>
      <svg className="loading-circle" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
        <circle cx="2" cy="2" r="2" fill="#465CA9" fillOpacity="0.2">
          <animate attributeName="fill-opacity" values="1; 0.6; 0.2; 0.6; 1" dur="2s" repeatCount="indefinite" />
          <animateTransform attributeName="transform" type="translate" values="0 -1; 0 1; 0 -1" dur="1s" repeatCount="indefinite"/>
        </circle>
      </svg>
    </div>
  );
};

export default LoadingAnimation;
