import React, { useEffect, useRef, useState } from "react";
import "./chatScreen.scss";
import {
  IMGAiImage,
  IMGAvatar,
  IMGChatbotIcon,
  IMGCrossButton,
  IMGDatabaseIcon,
  IMGDataset,
  IMGDatasourceGp,
  IMGLeftArrowIcon,
  IMGRightArrowIcon,
  IMGSmallWooCommerceIcon,
  IMGTitleFileIcon,
  ImgChooseLocalFiles,
  ImgCrossIconSm,
  ImgDataSource,
  ImgFilesMultiple,
  ImgFilesMultipleGrey,
  ImgLoader,
  ImgReload,
  IMGUploadArrow,
  ImgUploadFile,
  IMGAuthNetLogo,
  IMGFileIcon,
  ImgChatIcon,
  IMGSpeaker,
  IMGEditPen,
  IMGCopyIcon,
  IMGReplay,
  IMGSpeakeroff,
  IMGThumbDownFilled,
  IMGThumbDown,
} from "../images";
import axios from "axios";
import { API_URL, PRODUCT_TOUR_KEY, STORAGE_KEY, promptsTourScreen } from "../config";
import useQuery from "../hooks/useQuery";
import useFile from "../hooks/useFile";
import ProgressBarComponent from "../commonComponent/progressBarComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { query } from "../routes/routes";
import ChartComponent from "../components/chartComponent";
import { getChartOptions } from "../utils/chartUtils";
import { getFileSize } from "../utils/commonUtils";
import RoundProgessBar from "../commonComponent/roundProgressBar";
import PendingTransactionPopup from "./pendingTransactionPopup";
import LoadingAnimation from "../commonComponent/loadingAnimation";
import {
  sessionStorageGetItem,
  sessionStorageSetItem,
} from "../utils/sessionStorageUtils";
import { useDispatch, useSelector } from "react-redux";
import useWooCommerce from "../hooks/useWooCommerce";
import { update } from "../utils/itemSlice";
import LandingPage from "./landingPage";
import GpModal from "../commonComponent/gpModal/GpModal";
import NewUserModal from "../commonComponent/gpModal/newUser/NewUserModal";
import ExistingUser from "../commonComponent/gpModal/existingUser/ExistingUser";
import AuthorizeDotNet from "../dashboard/authorizeDotNet/AuthorizeDotNet";
import { setFileIds, uploadFileSelector } from "../utils/fileSlice";
import { Alert, Snackbar } from "@mui/material";
import Markdown from "react-markdown";
import DateFilterModal from "./dateFilter/dateFilterModal";
import { setAllChat, setTotalChat } from "../utils/dataSourceSlice";
import DashboardTour from "../dashboard/dashboardTour";

const ChatScreen = ({
  chatInfo,
  onChatCreated,
  onDeselectChat,
  onSelectChat,
  isHovered = false,
  fileData = {},
  datasourceId = "",
  syncDays = "",
  handleMenuClick,
  showDateFliter,
  setShowDateFliter,
  setIsChatCreated,
  isNavigating = false,
  setIsNavigating,
  currentStepIndex,
  setCurrentStepIndex,
  currentStepForDatasource,
  setCurrentStepForDataSource,
}) => {
  const {
    createChat,
    getPrompts,
    createDialogue,
    getAllDialogues,
    editChatDialogue,
    chatLikeDislike,
    dummyQuery,
    dummpyPrompts,
  } = useQuery();
  const { getPresignedFileUploadUrl, saveFiles } = useFile();
  const { getChatInfo } = useWooCommerce();
  const navigate = useNavigate();
  const location = useLocation();
  const routeParams = useParams();
  const merchantOnboarded = !localStorage.getItem(
    STORAGE_KEY.is_merchant_exist
  );
  const [isNewChatInit, setIsNewChatInit] = useState(false);
  const [show, setShow] = useState(false);
  const [chatValue, setChatValue] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedFile, setSelectedFile] = useState([]);
  const [areFilesUploaded, setAreFilesUploaded] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const selectFilesRef = useRef();
  const chatQueryResponseBoxRef = useRef();
  const chatBoxContainerRef = useRef();
  const inputPromptWrapperRef = useRef();
  const queryTextArea = useRef();
  const editQueryInputRef = useRef();
  const [chatQueryResponseBoxHeight, setChatQueryResponseBoxHeight] =
    useState("66vh");
  const textAreaDefaultHeight = "50px";
  const [prompts, setPrompts] = useState({ prompt_id: "", prompts: [] });
  const [dialogues, setDialogures] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [dataSourceStartDate, setDataSourceStartDate] = useState("");
  const [open, setOpen] = useState(false);
  const [dataSourceEndDate, setDataSourceEndDate] = useState("");
  const [dataSourceDaysCount, setDataSourceDaysCount] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [chatId, setChatId] = useState("");
  const [speakerOn, setSpeakerOn] = useState(false);
  const [thumbDown, setThumbDown] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [copiedText, setCopiedText] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [editQuery, setEditQuery] = useState("");
  const [promptQuery, setPromptQuery] = useState("");
  const [dialogueId, setDialogueId] = useState("");
  const [likeDislikeAction, setLikeDislikeAction] = useState("");
  const [editingDialogueId, setEditingDialogueId] = useState(null);
  const [copiedId, setCopiedId] = useState(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorSnackbarMsg, setErrorSnackbarMsg] = useState("");
  const [snackbarErrorSeverity, setSnackbarErrorSeverity] = useState("error");
  const dispatch = useDispatch();
  const dataSourcAddedDetails = useSelector((state) => state.dataSourceSlice);
  const dataSourcDetails = useSelector(
    (state) => state.dateFilterInfo.syncInfo
  );
  const dateFilterInfo = useSelector((store) => store.dateFilterInfo.syncInfo);
  const fileUploadDetails = useSelector(uploadFileSelector);
  const chatList = useSelector((state) => state.dataSourceSlice.total_chat);
  const { uploadFiles, fileIds } = fileUploadDetails;
  let isScrollToBottom = true;
  useEffect(() => {
    if (!routeParams["datasourceId"] && fileIds && fileIds.length > 0)
      setSelectedFile(uploadFiles);
    setFileIds(fileIds);
    onFileChat();
  }, [fileIds]);

  const onFileChat = async () => {
    if (routeParams["datasourceId"]) {
      return;
    }
    setIsLoading(true);
    sessionStorageSetItem(STORAGE_KEY.chat_id, "");
    try {
      const responsePrompts = await getPrompts(fileIds);
      if (responsePrompts === "error") {
        showErrorSnackbar(
          "Unexpected error occurred. Please try again after sometime.",
          "error"
        );
      } else if (responsePrompts && responsePrompts.status === 200) {
        dispatch(
          update({
            start_date: "",
            end_date: "",
            duration: "",
            data_intigration_id: "",
          })
        );
        setPrompts(responsePrompts.data);
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleSendButton = () => {
    if (isLoading) {
      return;
    }
    if (chatValue.trim()) {
      sendChatQuery(chatValue);
      queryTextArea.current.style.height = textAreaDefaultHeight;
    }
  };
  const showErrorSnackbar = (message, severity) => {
    setErrorSnackbarMsg(message);
    setSnackbarErrorSeverity(severity);
    setOpenErrorSnackbar(true);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  const speak = (id, text) => {
    // If the current item is already speaking, stop the speech
    if (speakerOn === id) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setSpeakerOn(null);
    } else {
      setSpeakerOn(id);
      if (window.speechSynthesis) {
        if (isSpeaking) {
          window.speechSynthesis.cancel();
          setIsSpeaking(false);
        } else {
          const speech = new SpeechSynthesisUtterance(text);
          speech.onend = () => {
            setIsSpeaking(false);
            setSpeakerOn(null); // Reset speakerOn to null when the speech ends
          };
          window.speechSynthesis.speak(speech);
          setIsSpeaking(true);
        }
      } else {
        alert("Your browser does not support text-to-speech.");
      }
    }
  };

  const handleClick = async (id, text) => {
    try {
      if (copiedId !== id) {
        setCopiedText(text);
        setCopiedId(id);
        await navigator.clipboard.writeText(text);
        setAlertText("Copied to clipboard");
        setAlertOpen(true);
      }
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setAlertOpen(false);
      setCopiedText("");
      setCopiedId(null);
    }, 2000);
  }, [alertOpen]);

  const handleCancelClick = () => {
    setEditingDialogueId(null);
  };

  const handleConfirmClick = (dialogueId) => {
    isScrollToBottom = false;
    if (editQueryInputRef.current.value.trim()) {
      const query = editQueryInputRef.current.value.trim();
      setDialogures({
        ...dialogues,
        results: dialogues.results.map((item) => {
          if (item.id === dialogueId) {
            item.query = query;
          }
          return item;
        }),
      });
      updateAnswer(dialogueId, query);
      setEditingDialogueId(null);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleConfirmClick(editingDialogueId);
      setEditingDialogueId(null);
    }
  };

  const handleInputChange = (e) => {
    setChatValue(e.target.value);
    const lines = e.target.value.split(/\r|\r\n|\n/);
    const count = lines.length;
    switch (count) {
      case 0:
      case 1:
        queryTextArea.current.style.height = textAreaDefaultHeight;
        break;
      case 2:
        queryTextArea.current.style.height = "87px";
        break;
      case 3:
        queryTextArea.current.style.height = "107px";
        break;
      case 4:
        queryTextArea.current.style.height = "127px";
        break;
      case 5:
        queryTextArea.current.style.height = "150px";
        break;
      default:
        queryTextArea.current.style.height = "150px";
    }
  };

  const sendQuery = (e) => {
    if (isLoading) return;
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      if (chatValue.trim()) {
        sendChatQuery(chatValue);
        queryTextArea.current.style.height = textAreaDefaultHeight;
      }
    } else if (e.keyCode === 13) {
    }
  };

  const onSelectPrompt = (prompt, isRetry = false) => {
    setChatValue(prompt);
    if (!isLoading) {
      sendChatQuery(prompt, isRetry);
    }
    setIsCollapsed(true);
  };

  const sendChatQuery = async (prompt, isRetry = false) => {
    isScrollToBottom = true;
    if (routeParams["chatId"]) {
      createChatDialogue(routeParams["chatId"], prompt, isRetry);
    } else {
      if (sessionStorageGetItem(STORAGE_KEY.chat_id)) {
        createChatDialogue(
          sessionStorageGetItem(STORAGE_KEY.chat_id),
          prompt,
          isRetry
        );
      } else {
        const response = await createChat(
          prompt,
          routeParams["datasourceId"],
          dateFilterInfo
        );
        if (response === "error") {
          showErrorSnackbar(
            "Unexpected error occurred. Please try again after sometime.",
            "error"
          );
        } else if (response && response.status === 200) {
          const chatId = response.data.chat_id;
          sessionStorageSetItem(STORAGE_KEY.chat_id, chatId);
          createChatDialogue(chatId, prompt, isRetry);
        }
      }
    }
    setChatValue("");
  };

  const createChatDialogue = async (chatId, prompt, isRetry = false) => {
    setIsLoading(true);
    if (!isRetry) {
      setDialogures({
        ...dialogues,
        results: [
          ...dialogues.results,
          {
            id: "new-dialogue",
            query: prompt,
            response: "",
          },
        ],
      });
    }
    const response = await createDialogue(chatId, prompt);
    if (response === "error") {
      showErrorSnackbar(
        "Unexpected error occurred. Please try again after sometime.",
        "error"
      );
    } else if (response?.status === 200) {
      setDialogures((previousState) => ({
        ...previousState.dialogues,
        results: previousState.results.map((item) => {
          if (item.id === "new-dialogue") {
            return {
              id: response?.data?.dialogue_id,
              query: prompt,
              response: response?.data?.response,
            };
          } else {
            return item;
          }
        }),
      }));
      setIsCollapsed(true);
    } else {
      setDialogures((previousState) => ({
        ...previousState.dialogues,
        results: previousState.results.map((item) => {
          if (item.id === "new-dialogue") {
            return {
              id: response?.data?.dialogue_id,
              query: prompt,
              response: {
                ApacheOptionObject: null,
                TextResponse: "Something went wrong.Please try again.",
                responseType: "text",
              },
            };
          } else {
            return item;
          }
        }),
      }));
    }
    setIsLoading(false);
  };

  const realodPrompts = (e) => {
    e.stopPropagation();
  };

  const toggleCollapsible = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    // if (routeParams["chatId"]) {
    //   const resizeObserver = new ResizeObserver(() => {
    //     if (inputPromptWrapperRef?.current?.clientHeight) {
    //       setChatQueryResponseBoxHeight(
    //         `calc(66vh - ${inputPromptWrapperRef.current.clientHeight}px + 97px)`
    //       );
    //     }
    //   });
    //   resizeObserver.observe(inputPromptWrapperRef.current);
    //   return () => resizeObserver.disconnect();
    // }
  }, [inputPromptWrapperRef.current]);

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const fileExtension = file.type.split("/")[1];
      file.extension = fileExtension;
      const reader = new FileReader();
      const options = {
        headers: {
          "Content-Type": file.type,
        },
        // cancelToken: source.token,

        onUploadProgress: (progressEvent) => {
          // Update the upload progress
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            ),
          }));
        },
      };

      // setContentUploading(true);
      // setFileExtension(fileExtension);

      reader.onloadend = async (event) => {
        const fileData = event.target.result;
        try {
          const resp = await getPresignedFileUploadUrl(fileExtension);
          if (resp.status === 200) {
            const { presigned_url, s3_url } = resp.data;
            const uploadResponse = await axios.put(
              presigned_url,
              fileData,
              options
            );
            console.log(uploadResponse);
            if (uploadResponse.status === 200) {
              file.s3uri = s3_url;
              resolve();
            }
          }
        } catch {
          reject();
        }
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileChange = (e) => {
    setIsNewChatInit(false);
    const { files } = e.target;
    console.log(files);
    setSelectedFile([...selectedFile]);
    console.log(selectedFile);
  };

  const uplaodFiles = async () => {
    setIsFileUploading(true);
    for (let file of selectedFile) {
      await uploadFile(file);
    }
    setAreFilesUploaded(true);
    const requestBody = selectedFile.map(
      ({ name, size, extension, s3uri }) => ({ name, size, extension, s3uri })
    );
    const response = await saveFiles(requestBody);
    if (response && response.status === 200) {
      setIsLoading(true);
      const responsePrompts = await getPrompts(
        response.data.file_ids,
        routeParams["datasourceId"]
      );
      if (responsePrompts && responsePrompts.status === 200) {
        setPrompts(responsePrompts.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    setIsFileUploading(true);
  };
  useEffect(() => {
    if (isNavigating) return;
    if (routeParams["datasourceId"]) {
      uplaodFiles();
    }
    if (!routeParams["chatId"] && sessionStorageGetItem(STORAGE_KEY.chat_id)) {
      if (routeParams["datasourceId"]) {
        navigate(
          `/${"queries"}${`/${routeParams["datasourceId"]}`}/${sessionStorageGetItem(
            STORAGE_KEY.chat_id
          )}`
        );
      } else {
        navigate(`/${"query"}/${sessionStorageGetItem(STORAGE_KEY.chat_id)}`);
      }
    }
    // getChatDetails();
  }, [routeParams["datasourceId"]]);

  useEffect(() => {
    let chatId;
    if (routeParams["chatId"]) {
      chatId = routeParams["chatId"];
    } else if (sessionStorageGetItem(STORAGE_KEY.chat_id)) {
      chatId = sessionStorageGetItem(STORAGE_KEY.chat_id);
    }
    setChatId(chatId);
    setIsNavigating(false);
  });
  useEffect(() => {
    getChatDetails();
  }, [chatId]);

  const getChatDetails = async () => {
    let chatId;
    if (routeParams["chatId"]) {
      chatId = routeParams["chatId"];
    } else if (sessionStorageGetItem(STORAGE_KEY.chat_id)) {
      chatId = sessionStorageGetItem(STORAGE_KEY.chat_id);
    }
    if (chatId) {
      const response = await getChatInfo(chatId);
      dispatch(setAllChat(response));
      const itemFound = chatList?.findIndex((item) => item.id === chatId);
      if (itemFound == -1 && !routeParams["chatId"]) {
        dispatch(setTotalChat([response, ...chatList]));
      }
      const userFilterInfo = {
        start_date: response?.start_date?.slice(0, 10),
        end_date: response?.end_date?.slice(0, 10),
        duration: response?.no_of_days,
        data_intigration_id: response?.datasource_integration_id,
        // data_Source_Name: dataSourcDetails?.data_Source_Name,
        // total_chat: dataSourcDetails?.total_chat,
        // authorise_net_integration_id: dataSourcDetails?.authorise_net_integration_id
      };
      dispatch(update(userFilterInfo));
      setDataSourceDaysCount(response?.no_of_days);
      setDataSourceEndDate(response?.end_date?.slice(0, 10));
      setDataSourceStartDate(response?.start_date?.slice(0, 10));
    }
  };
  const removeSelectedFile = (index) => {
    const files = selectedFile.filter((item, i) => {
      return i !== index;
    });
    setSelectedFile(files);
  };

  const removeAllFile = () => {
    setSelectedFile([]);
  };

  const resetComponentData = () => {
    setSelectedFile([]);
    setDialogures({ count: 0, next: null, previous: null, results: [] });
    setPrompts({ prompt_id: "", prompts: [] });
    setIsNewChatInit(true);
    onDeselectChat();
    try {
      if (routeParams["chatId"]) {
        setIsNewChatInit(false);
      }
    } catch {}
  };

  useEffect(() => {
    sessionStorageSetItem(STORAGE_KEY.chat_id, "");
    if (routeParams["chatId"]) {
      setIsLoading(false);
      onSelectChat(routeParams["chatId"]);
      setIsNewChatInit(false);
      setPrompts({ prompt_id: "", prompts: [] });
      getAllDialogues(routeParams["chatId"]).then((resp) => {
        if (resp?.data) {
          setDialogures(resp.data);
        }
      });
    } else {
      resetComponentData();
    }
    return () => {};
  }, [routeParams]);

  useEffect(() => {
    if (isScrollToBottom && chatQueryResponseBoxRef?.current) {
      chatQueryResponseBoxRef.current.scrollTo(
        0,
        chatQueryResponseBoxRef.current.scrollHeight
      );
    }
  }, [dialogues]);

  useEffect(() => {
    if (chatInfo && chatInfo.files) {
      setSelectedFile([]);
      const fileIds = chatInfo.files.map((item) => item.id);
      getPrompts(fileIds, routeParams["datasourceId"]).then((resp) => {
        try {
          setPrompts(resp.data);
        } catch {}
      });
    } else {
      resetComponentData();
    }
    return () => {};
  }, [chatInfo]);

  useEffect(() => {
    if (routeParams["chatId"]) {
      chatQueryResponseBoxRef.current.scrollTop =
        chatQueryResponseBoxRef.current.scrollHeight;
    }
  }, [dummyQuery, chatQueryResponseBoxHeight]);

  const handleLeftClick = () => {
    console.log(prompts);
    if (count - 5 >= 0) {
      setCount(count - 5);
    }
  };

  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setHoveredIndex(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredIndex(null);
  };

  const handleRightClick = () => {
    console.log("prompts.length", prompts.prompts.length);

    if (count + 5 < prompts.prompts.length) {
      setCount(count + 5);
    }
  };

  const getDaysText = ({ syncDays, dataSourceDaysCount }) => {
    let days = syncDays ? syncDays : dataSourceDaysCount;
    if (days === 7 || days === 15 || days === 30) {
      return `Past ${days} Days of Data`;
    } else if (days === 90 || days === 180) {
      return `Past ${days / 30} Months of Data`;
    } else if (days === 365) {
      return `Past ${1} Year of Data`;
    } else {
      return "";
    }
  };

  const getDateRange = (
    startDate,
    endDate,
    dataSourceStartDate,
    dataSourceEndDate,
    datasourceId
  ) => {
    if (!datasourceId) {
      return <></>;
    } else if (startDate && endDate) {
      return (
        <div id="notsync" className="uploaded-files">
          <img src={IMGDatabaseIcon} alt="" />
          <span className="uploaded-files-heading-new">
            {/* {getDaysText(syncDays)} */}
            {`Data from ${startDate} to ${endDate}.`}
          </span>
        </div>
      );
    } else if (dataSourceStartDate && dataSourceEndDate) {
      return (
        <div id="notsync" className="uploaded-files">
          <img src={IMGDatabaseIcon} alt="" />
          <span className="uploaded-files-heading-new">
            {/* {getDaysText(syncDays)} */}
            {`Data from ${dataSourceStartDate} to ${dataSourceEndDate}.`}
          </span>
        </div>
      );
    } else {
      return <></>;
    }
  };
  const handleopenModal = () => {
    setShow(!show);
  };
  const handleClose = () => {
    setShow(!show);
  };
  const handleCloseAuthorizeModal = () => {
    navigate("/dashboard");
  };
  const handleAuthorizeModal = () => {
    navigate("/dataSources/authorizedotnet");
  };
  const imageMapping = {
    GORILLAPAY: IMGDatasourceGp,
    "AUTHORIZE.NET": IMGAuthNetLogo,
  };

  const defaultImage = IMGSmallWooCommerceIcon;
  const imageSrc = dataSourcAddedDetails?.data_Source_Name
    ? imageMapping[dataSourcAddedDetails.data_Source_Name] || defaultImage
    : defaultImage;

  const updateAnswer = async (dialogueId, query) => {
    isScrollToBottom = false;
    let chatId = routeParams["chatId"];
    if (!chatId) {
      chatId = sessionStorageGetItem(STORAGE_KEY.chat_id);
    }
    const index = dialogues?.results?.findIndex(
      (item) => item.id === dialogueId
    );
    // for showing the loading state
    const emptyResults = [...dialogues?.results];
    emptyResults[index] = { ...emptyResults[index], response: "" };
    setDialogures((prevState) => ({
      ...prevState,
      results: emptyResults,
    }));
    try {
      setIsLoading(true);
      const response = await editChatDialogue(chatId, query, dialogueId);
      if (index !== -1) {
        if (response?.status === 200) {
          const updatedResults = [...dialogues?.results];
          updatedResults[index] = {
            ...updatedResults[index],
            response: response?.data?.response,
          };
          setDialogures((prevState) => ({
            ...prevState,
            results: updatedResults,
          }));
        } else {
          setDialogures(dialogues);
          showErrorSnackbar(
            "Unexpected error occurred. Please try again after sometime.",
            "error"
          );
        }
      }
    } catch (error) {
      showErrorSnackbar(
        "An error occurred while updating the answer. Please try again.",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };
  const getSteps = (info) => {
    switch (info) {
      case 'currentStepIndex':
        return 3;
      case 'currentStepForDatasource':
        return 6;
      default:
        return 0;
    }
  };

  return (
    <>
      {show ? (
        merchantOnboarded ? (
          <GpModal
            show={show}
            handleClose={handleClose}
            merchantOnboarded={merchantOnboarded}
          >
            <ExistingUser />
          </GpModal>
        ) : (
          <GpModal
            show={show}
            handleClose={handleClose}
            merchantOnboarded={merchantOnboarded}
          >
            {<NewUserModal handleClose={handleClose} currentStepIndex={currentStepIndex} setCurrentStepIndex={setCurrentStepIndex} />}
          </GpModal>
        )
      ) : (
        ""
      )}
      {location?.pathname === "/dataSources/authorizedotnet" ? (
        <GpModal
          show={location?.pathname === "/dataSources/authorizedotnet"}
          handleClose={handleCloseAuthorizeModal}
          merchantOnboarded={false}
        >
          <AuthorizeDotNet />
        </GpModal>
      ) : (
        ""
      )}
      {showDateFliter && (
        <DateFilterModal
          handleClose={() => {
            setShowDateFliter(false);
          }}
        />
      )}
      <input
        type="file"
        id="file-input"
        onChange={handleFileChange}
        onDrop={handleFileChange}
        ref={selectFilesRef}
        accept={"text/csv"}
        onClick={(event) => {
          event.target.value = null;
        }}
        hidden
        multiple
      />
      {/* <ChartComponent option={option} /> */}
      {!routeParams["chatId"] &&
      isNewChatInit &&
      !location.pathname.startsWith(`${query}`) &&
      !routeParams["datasourceId"] ? (
        <div className="source-selection">
          <LandingPage
            selectFilesRef={selectFilesRef}
            onFileChat={onFileChat}
            currentStepIndex={currentStepIndex}
            setCurrentStepIndex={setCurrentStepIndex}
            steps={getSteps('currentStepIndex')}
          />
          {/* <div
            style={{ gap: "24px" }}
            onClick={() => handleMenuClick("datasource")}
          >
            <img
              src={ImgDataSource}
              style={{ width: "213px", height: "155px" }}
            />{" "}
            Connect to Data Sources
          </div>
          {/* testin part for modal */}
          {/* <div onClick={handleopenModal}>open gp modal</div>
          <div onClick={handleAuthorizeModal}>open authorize.net</div> */}
        </div>
      ) : (
        <>
        <div className="chatbox-container" ref={chatBoxContainerRef}>
          <div className="text-area-container">
            <ul
              className="query-avatar-container"
              style={{
                maxHeight: chatQueryResponseBoxHeight,
                height: chatQueryResponseBoxHeight,
              }}
              ref={chatQueryResponseBoxRef}
            >
              {dialogues?.results.length === 0 ? (
                <div className="center-Text-Container">
                  <img src={IMGDataset} alt="datasource" />
                  <h2>Ask XAiLES questions to begin analysis</h2>
                  {!fileIds || fileIds.length === 0 ? (
                    <p>
                      Your data has been linked from
                      <span> {dataSourcAddedDetails?.data_Source_Name}</span>
                      {dataSourcDetails?.start_date &&
                      dataSourcDetails?.end_date ? (
                        <>
                          for the dates{" "}
                          <span>
                            {dataSourcDetails?.start_date} to{" "}
                            {dataSourcDetails?.end_date}.
                          </span>
                        </>
                      ) : (
                        <>
                          <span> for {dataSourcDetails?.duration} days</span>
                        </>
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                  <p>Ask us a question or select a prompt to get started.</p>
                </div>
              ) : (
                dialogues.results.map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                      <li className="query-subject">
                        {!(editingDialogueId === item.id) ? (
                          <div className="query-display-container">
                            <img
                              src={IMGEditPen}
                              alt="edit"
                              className="edit-icon"
                              onClick={() => setEditingDialogueId(item.id)}
                            />
                            <span>{item.query}</span>
                          </div>
                        ) : (
                          <div className="edit-input-container">
                            <input
                              type="text"
                              defaultValue={item.query}
                              ref={editQueryInputRef}
                              onKeyDown={handleKeyDown}
                              autoFocus
                            />
                            <div className="btn-container">
                              <button
                                className="cancel-btn"
                                onClick={() => handleCancelClick(item.query)}
                              >
                                Cancel
                              </button>
                              <button
                                className="confirm-btn"
                                onClick={() => handleConfirmClick(item.id)}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        )}
                      </li>
                      {item?.response === "" && (
                        <li
                          className="query-answer"
                          style={{
                            float: "left",
                            display: "flex",
                            marginTop: "20px",
                            width: "95%",
                            marginLeft: "10px",
                          }}
                        >
                          {"Processing your request, please wait"}
                          <LoadingAnimation />
                          <img
                            style={{
                              position: "absolute",
                              left: "-30px",
                              background: "black",
                              padding: "6px",
                              right: "10px",
                              borderRadius: "50%",
                              marginRight: "20px",
                              top: "4px",
                              // bottom: "-30px",
                            }}
                            src={IMGAiImage}
                            alt="img"
                          />
                        </li>
                      )}
                      {item?.response?.responseType === "text" && (
                        <li
                          className="query-answer"
                          style={{ float: "left", width: "95%" }}
                        >
                          <div>
                            <Markdown style={{ margin: 0 }}>
                              {item?.response?.TextResponse}
                            </Markdown>
                            <div className="action-image-container">
                              <img
                                className="image"
                                src={
                                  speakerOn === item?.id
                                    ? IMGSpeakeroff
                                    : IMGSpeaker
                                }
                                onClick={() =>
                                  speak(item?.id, item?.response?.TextResponse)
                                }
                              />{" "}
                              <img
                                className="image"
                                src={IMGReplay}
                                onClick={() =>
                                  updateAnswer(item?.id, item?.query)
                                }
                              />{" "}
                              <img
                                className="image"
                                src={IMGCopyIcon}
                                onClick={() =>
                                  handleClick(
                                    item?.id,
                                    item?.response?.TextResponse
                                  )
                                }
                              />{" "}
                              {/* <img className="image"
                              src={
                                thumbDown[item.response.TextResponse] === item?.id ? IMGThumbDownFilled : IMGThumbDown
                              }
                              // onClick={() => sendLikeDislikeAction(item?.id, item.response.TextResponse)}
                            /> */}
                              {alertOpen && copiedId === item?.id && (
                                <div className="snackbar-alert-msg">
                                  <div className="alertmsg"> {alertText}</div>
                                </div>
                              )}
                            </div>
                          </div>

                          <img
                            style={{
                              position: "absolute",
                              left: "-20px",
                              background: "black",
                              padding: "5px",
                              borderRadius: "50%",
                              top: "23px",
                              // bottom: "-30px",
                            }}
                            src={IMGAiImage}
                            alt="img"
                          />
                          {item?.response?.TextResponse ===
                            "Could not process the request"}
                        </li>
                      )}
                      {item?.response?.responseType === "image" && (
                        <li
                          className={
                            Object.keys(item?.response?.ApacheOptionObject)
                              ?.length === 0
                              ? "query-answer-nochart"
                              : "query-answer"
                          }
                          style={{ float: "left" }}
                        >
                          {item?.response?.ApacheOptionObject &&
                            Object.keys(item?.response?.ApacheOptionObject)
                              .length > 0 && (
                              <div>
                                <ChartComponent
                                  option={getChartOptions(item.response)}
                                />
                              </div>
                            )}
                          <img
                            style={{
                              position: "absolute",
                              left: "-12px",
                              left: "-20px",
                              background: "black",
                              padding: "5px",
                              borderRadius: "50%",
                              top: "4px",
                              // bottom: "-30px",
                            }}
                            src={IMGAiImage}
                            alt="img"
                          />
                          <Markdown
                            className={
                              Object.keys(item?.response?.ApacheOptionObject)
                                ?.length === 0
                                ? "nullchartimageText"
                                : ""
                            }
                          >
                            {item?.response?.TextResponse}
                          </Markdown>
                          <div className="action-image-container">
                            <img
                              className="image"
                              src={
                                speakerOn === item?.id
                                  ? IMGSpeakeroff
                                  : IMGSpeaker
                              }
                              onClick={() =>
                                speak(item?.id, item?.response?.TextResponse)
                              }
                            />{" "}
                            <img
                              className="image"
                              src={IMGReplay}
                              onClick={() =>
                                updateAnswer(item?.id, item?.query)
                              }
                            />{" "}
                            <img
                              className="image"
                              src={IMGCopyIcon}
                              onClick={() =>
                                handleClick(
                                  item?.id,
                                  item?.response?.TextResponse
                                )
                              }
                            />{" "}
                            {/* <img className="image"
                              src={
                                thumbDown[item.response.TextResponse] === item?.id ? IMGThumbDownFilled : IMGThumbDown
                              }
                              // onClick={() => sendLikeDislikeAction(item?.id, item.response.TextResponse)}
                            /> */}
                            {copiedId === item?.id &&
                              alertOpen &&
                              copiedText === item?.response?.TextResponse && (
                                <div className="snackbar-alert-msg">
                                  <div className="alertmsg"> {alertText}</div>
                                </div>
                              )}
                          </div>
                        </li>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </ul>
          </div>
          {isHovered && (
            <PendingTransactionPopup
              fileData={fileData}
              styleClasses={{ bottom: "60px" }}
            />
          )}
          <div className="chatbox" ref={inputPromptWrapperRef}>
            {prompts?.prompts && prompts?.prompts.length > 0 && (
              <>
                <div className="collapsible-button" onClick={toggleCollapsible}>
                  {!isCollapsed ? "Hide prompts" : "Show prompts"}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                    style={
                      isCollapsed
                        ? { marginLeft: "14px", transform: "rotate(180deg)" }
                        : { marginLeft: "14px", transform: "rotate(0deg)" }
                    }
                  >
                    <path
                      d="M7.05333 5L4 1.90958L0.94 5L-4.15878e-08 4.04858L4 3.01992e-07L8 4.04858L7.05333 5Z"
                      fill="#465CA9"
                    />
                  </svg>
                </div>
                {isFileUploading === true && currentStepForDatasource === 0 && (
                  <div style={{position:'absolute',bottom: '600px',right: '430px'}}>
                    <DashboardTour
                      currentStepInfo={promptsTourScreen}
                      currentStepIndex={currentStepForDatasource}
                      setCurrentStepIndex={setCurrentStepForDataSource}
                      steps={getSteps('currentStepForDatasource')}
                      flowName={PRODUCT_TOUR_KEY.show_product_tour}
                  />
                  </div>
                )}

                {!isCollapsed && (
                  <div className="collapsible-container">
                    {/* <img
                      onClick={handleLeftClick}
                      style={{ marginLeft: "4px", cursor: "pointer" }}
                      src={IMGLeftArrowIcon}
                    /> */}
                    <div
                      className={`content ${
                        isCollapsed ? "collapsed" : "expanded"
                      }`}
                    >
                      <ul className="menu-items-container">
                        {prompts.prompts
                          .slice(count, count + 5)
                          .map((item, index) => {
                            return (
                              <li
                                className="menu-items"
                                onClick={() => onSelectPrompt(item)}
                              >
                                {item}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                    {/* <img
                      onClick={handleRightClick}
                      style={{ cursor: "pointer" }}
                      src={IMGRightArrowIcon}
                    /> */}
                  </div>
                )}
              </>
            )}

            <div className="chat-files-container">
              <textarea
                rows={1}
                value={chatValue}
                onChange={handleInputChange}
                onKeyDown={sendQuery}
                placeholder="Type here..."
                className="chat-input-box"
                ref={queryTextArea}
                disabled={
                  fileIds || chatInfo?.files || routeParams["datasourceId"]
                    ? false
                    : true
                }
              ></textarea>
              {!routeParams["datasourceId"] && fileIds && <></>}
              {routeParams["datasourceId"] && fileIds && (
                <div
                  style={{ position: "absolute", bottom: "16px", left: "10px" }}
                >
                  {/* <img src={imageSrc} alt={dataSourcAddedDetails?.data_Source_Name || 'Default Image'} /> */}
                </div>
              )}
              {(fileIds || routeParams["datasourceId"]) && (
                <div
                  className={`upload-arrow ${isLoading ? "disable-class" : ""}`}
                  onClick={handleSendButton}
                  disabled={isLoading}
                >
                  <img
                    src={IMGUploadArrow}
                    alt="uploadicon"
                    style={{
                      opacity: isLoading ? 0.5 : 1,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseErrorSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseErrorSnackbar}
              severity={snackbarErrorSeverity}
              variant="filled"
              sx={{
                width: "100%",
                bgcolor: "#FFECEF",
                color: "#3B3939",
                "& .MuiAlert-icon": {
                  color: "#CA6069",
                },
                fontFamily: "Chivo",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              {errorSnackbarMsg}
            </Alert>
          </Snackbar>
        </div>
        </>

      )}
    </>
  );
};

export default ChatScreen;
