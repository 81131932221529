import React from "react";
import axiosInstance from "./axiosConfig";
import { API_URL } from "../config";
import axios from "axios";

const useWooCommerce = () => {
  let cancelToken;
  const conntectStore = async (store_url, dataSource) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/chat/generate_woocommerece_auth_url/`,
        { data_source: dataSource, store_url: store_url }
      );

      if (response.status !== 200) {
        throw new Error("Store Linking failed");
      }
      const data = response?.data;

      if (data.status) {
        window.location.href = data?.oauth_url;
      }
      return data?.status;
    } catch (error) {
      return error?.response?.data?.store_url[0];
    }
  };
  const dateDataHandler = async ({
    checkboxValues,
    selectedRadio,
    dataIntegrationId,
    startDate,
    endDate,
    syncDays = null,
  }) => {
    const datasource_integration_id = dataIntegrationId;
    const end_date = endDate;
    const start_date = startDate;
    const no_of_days = syncDays === "" ? null : syncDays;
    let table_type = ["orders", "products", "product_reviews", "sales_report"];
    // if (checkboxValues.checkbox4) {
    // table_type.push("orders");
    // table_type.push("customers");
    // table_type.push("refund");
    // } else {
    //   if (checkboxValues.checkbox1) {
    //     table_type.push("orders");
    //   }
    //   if (checkboxValues.checkbox2) {
    //     table_type.push("customers");
    //   }
    //   if (checkboxValues.checkbox3) {
    //     table_type.push("refund");
    //   }
    // }
    // const order = checkboxValues;
    // const customer = checkboxValues;
    try {
      return await axiosInstance.post(`${API_URL}/chat/sync_datasource_data/`, {
        datasource_integration_id,
        end_date,
        start_date,
        table_type,
        no_of_days,
      });
    } catch (error) {}
  };

  const getDataIntigrationId = async (store_url) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/chat/get_woocommerce_data_integration_id/?store_id=${store_url}`,
        { store_url: store_url }
      );
      console.log(response.data.data_source_integartion_id);
      return response.data.data_source_integartion_id;
    } catch (error) {}
  };

  const getStoreList = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/chat/get_datasource_integration_list/`,
        {}
      );
      return response.data;
    } catch (error) {
      return "error";
    }
  };

  const getChatInfo = async (chatId) => {
    if (cancelToken) {
      cancelToken.cancel("Operation canceled by the user.");
    }

    // Create new cancel token
    cancelToken = axios.CancelToken.source();
    try {
      const response = await axiosInstance.get(`${API_URL}/chat/${chatId}/`, {
        cancelToken: cancelToken.token,
      });
      console.log(response.data.data_source_integartion_id);
      return response.data;
    } catch (error) {}
  };

  const updateChatDate = async (chatId, endDate, startDate, syncDays) => {
    if (cancelToken) {
      cancelToken.cancel("Operation canceled by the user.");
    }
    const datasource_integration_id = "";
    const end_date = endDate;
    const start_date = startDate;
    const no_of_days = syncDays === "" ? null : syncDays;

    // Create new cancel token
    cancelToken = axios.CancelToken.source();
    try {
      const response = await axiosInstance.patch(`${API_URL}/chat/${chatId}/`, {
        end_date,
        start_date,
        no_of_days,
        cancelToken: cancelToken.token,
      });
      console.log(response.data.data_source_integartion_id);
      return response.data;
    } catch (error) {}
  };

  const deleteDataSource = async (activeDataSource) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/chat/disconnect-datasource/`,
        {
          ...activeDataSource,
        }
      );
      return response?.data;
    } catch (error) {}
  };

  return {
    conntectStore,
    dateDataHandler,
    getDataIntigrationId,
    getStoreList,
    getChatInfo,
    updateChatDate,
    deleteDataSource,
  };
};

export default useWooCommerce;
