// Footer.js

import React, { useState } from "react";
import "./Footer.css";

// import { Checkbox } from "@mui/material";

const Footer = () => {
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onValueChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="form-component">
          <div className="gorillapay-text">XAiLES</div>
          <div className="subscribe-text">
            Subscribe to our Newsletter
          </div>
          <input
            className="input-box"
            type="text"
            placeholder={''}
            value={value}
            onChange={onValueChange}
          />
          <div className="privacy-policy">
            <input
              className="checkbox"
              type="checkbox"
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span>I agree to Privacy Policy*</span>
          </div>
          <button className="sumbit-button">Submit</button>
        </div>
        <div className="footer-column">
          <div className="heading">Company</div>
          <ul className="list-item-container">
            <li>About Us</li>
            <li>Careers</li>
            <li>Investors</li>
            <li>SignUp</li>
            <li>FAQs</li>
          </ul>
        </div>
        <div className="footer-column">
          <div className="heading">Support</div>
          <ul>
            <li>Help Center</li>
            <li>Contact Us</li>
            <li>ID Verification</li>
            <li>Account Status</li>
          </ul>
        </div>
        <div className="footer-column">
          <div className="heading">Explore</div>
          <ul>
            <li>Blogs</li>
            <li>Services</li>
          </ul>
        </div>
        <div className="footer-column">
          <div className="heading">Legal</div>
          <ul>
            <li>Leagal Disclaimer</li>
            <li>Terms & Conditions</li>
            <li>Privacy Policy</li>
            <li>Cookies Policy</li>
          </ul>
        </div>
      </div>
      <div className="footer-text-container">
        <div className="icon">
          <span className="svg-style">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M6.916 4.998C7.812 5.04 8.043 5.803 8.057 6.16H9.31C9.254 4.774 8.267 3.927 6.895 3.927C5.348 3.927 4.2 4.9 4.2 7.098C4.2 8.456 4.851 10.066 6.888 10.066C8.442 10.066 9.275 8.911 9.296 8.001H8.043C8.022 8.414 7.728 8.967 6.902 9.009C5.985 8.981 5.6 8.267 5.6 7.098C5.6 5.075 6.496 5.012 6.916 4.998ZM7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.913 12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6Z"
                fill="white"
              />
            </svg>
          </span>
          <div className="footer-text">2023 GorillaLabs, Los Angeles, United States</div>
        </div>
        <div className="icon-container">
          <span className="media-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M5.8 0H14.2C17.4 0 20 2.6 20 5.8V14.2C20 15.7383 19.3889 17.2135 18.3012 18.3012C17.2135 19.3889 15.7383 20 14.2 20H5.8C2.6 20 0 17.4 0 14.2V5.8C0 4.26174 0.61107 2.78649 1.69878 1.69878C2.78649 0.61107 4.26174 0 5.8 0ZM5.6 2C4.64522 2 3.72955 2.37928 3.05442 3.05442C2.37928 3.72955 2 4.64522 2 5.6V14.4C2 16.39 3.61 18 5.6 18H14.4C15.3548 18 16.2705 17.6207 16.9456 16.9456C17.6207 16.2705 18 15.3548 18 14.4V5.6C18 3.61 16.39 2 14.4 2H5.6ZM15.25 3.5C15.5815 3.5 15.8995 3.6317 16.1339 3.86612C16.3683 4.10054 16.5 4.41848 16.5 4.75C16.5 5.08152 16.3683 5.39946 16.1339 5.63388C15.8995 5.8683 15.5815 6 15.25 6C14.9185 6 14.6005 5.8683 14.3661 5.63388C14.1317 5.39946 14 5.08152 14 4.75C14 4.41848 14.1317 4.10054 14.3661 3.86612C14.6005 3.6317 14.9185 3.5 15.25 3.5ZM10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.67392 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.67392 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5ZM10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z"
              fill="white"
            />
          </svg>
          </span>
          <span className="media-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M9.99999 0C4.49999 0 0 4.50803 0 10.0602C0 15.0803 3.65999 19.247 8.43999 20V12.9719H5.89999V10.0602H8.43999V7.84137C8.43999 5.32129 9.92999 3.93574 12.22 3.93574C13.31 3.93574 14.45 4.12651 14.45 4.12651V6.60643H13.19C11.95 6.60643 11.56 7.37952 11.56 8.17269V10.0602H14.34L13.89 12.9719H11.56V20C13.9164 19.6264 16.0622 18.4192 17.6099 16.5964C19.1576 14.7737 20.0053 12.4555 20 10.0602C20 4.50803 15.5 0 9.99999 0Z"
              fill="white"
            />
          </svg>
          </span>
          <span className="media-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
          >
            <path
              d="M20 2.11765C19.2639 2.48824 18.4704 2.73176 17.6482 2.84824C18.4895 2.28706 19.1396 1.39765 19.4455 0.328235C18.652 0.857647 17.7725 1.22824 16.8451 1.44C16.0899 0.529412 15.0287 0 13.8241 0C11.5774 0 9.74187 2.03294 9.74187 4.54235C9.74187 4.90235 9.78012 5.25177 9.84704 5.58C6.4436 5.38941 3.413 3.57882 1.39579 0.836471C1.04207 1.50353 0.8413 2.28706 0.8413 3.11294C0.8413 4.69059 1.55832 6.08824 2.6673 6.88235C1.98853 6.88235 1.35755 6.67059 0.80306 6.35294C0.80306 6.35294 0.80306 6.35294 0.80306 6.38471C0.80306 8.58706 2.21797 10.4294 4.09178 10.8424C3.74761 10.9482 3.38432 11.0012 3.01147 11.0012C2.75335 11.0012 2.49522 10.9694 2.24665 10.9165C2.76291 12.7059 4.26386 14.04 6.07075 14.0718C4.67495 15.3 2.90631 16.02 0.975143 16.02C0.650096 16.02 0.325048 15.9988 0 15.9565C1.81644 17.2482 3.97706 18 6.29063 18C13.8241 18 17.9637 11.0753 17.9637 5.07176C17.9637 4.87059 17.9637 4.68 17.9541 4.47882C18.7572 3.84353 19.4455 3.03882 20 2.11765Z"
              fill="white"
            />
          </svg>
          </span>
          <span className="media-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10.2032 8.239V12.06H15.7577C15.0313 14.375 13.0574 16.032 10.2032 16.032C6.80414 16.032 4.04882 13.331 4.04882 10C4.04882 6.669 6.80414 3.968 10.2032 3.968C11.7313 3.968 13.1268 4.517 14.203 5.421L17.0736 2.607C15.2609 0.988 12.8493 0 10.2032 0C4.56806 0 0 4.477 0 10C0 15.523 4.56806 20 10.2032 20C18.768 20 20.6583 12.15 19.8187 8.252L10.2032 8.239Z"
              fill="white"
            />
          </svg>
          </span>
          <span className="media-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.7778 0H2.22222C0.994444 0 0 0.994444 0 2.22222V17.7778C0 19.0056 0.994444 20 2.22222 20H17.7778C19.0056 20 20 19.0056 20 17.7778V2.22222C20 0.994444 19.0056 0 17.7778 0ZM6.66667 15.5556H3.86333V7.77778H6.66667V15.5556ZM5.21556 6.35222C4.35889 6.35222 3.78667 5.78111 3.78667 5.01889C3.78667 4.25667 4.35778 3.68556 5.31 3.68556C6.16667 3.68556 6.73889 4.25667 6.73889 5.01889C6.73889 5.78111 6.16778 6.35222 5.21556 6.35222ZM16.6667 15.5556H13.9533V11.3044C13.9533 10.1289 13.23 9.85778 12.9589 9.85778C12.6878 9.85778 11.7833 10.0389 11.7833 11.3044C11.7833 11.4856 11.7833 15.5556 11.7833 15.5556H8.98V7.77778H11.7833V8.86333C12.1444 8.23 12.8678 7.77778 14.2244 7.77778C15.5811 7.77778 16.6667 8.86333 16.6667 11.3044V15.5556Z"
              fill="white"
            />
          </svg>
          </span>
          <span className="media-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
          >
            <path
              d="M9.6 11.4286L15.828 8L9.6 4.57143V11.4286ZM23.472 2.48C23.628 3.01714 23.736 3.73714 23.808 4.65143C23.892 5.56571 23.928 6.35429 23.928 7.04L24 8C24 10.5029 23.808 12.3429 23.472 13.52C23.172 14.5486 22.476 15.2114 21.396 15.4971C20.832 15.6457 19.8 15.7486 18.216 15.8171C16.656 15.8971 15.228 15.9314 13.908 15.9314L12 16C6.972 16 3.84 15.8171 2.604 15.4971C1.524 15.2114 0.828 14.5486 0.528 13.52C0.372 12.9829 0.264 12.2629 0.192 11.3486C0.108 10.4343 0.0719999 9.64571 0.0719999 8.96L0 8C0 5.49714 0.192 3.65714 0.528 2.48C0.828 1.45143 1.524 0.788572 2.604 0.502857C3.168 0.354286 4.2 0.251428 5.784 0.182857C7.344 0.102857 8.772 0.0685714 10.092 0.0685714L12 0C17.028 0 20.16 0.182857 21.396 0.502857C22.476 0.788572 23.172 1.45143 23.472 2.48Z"
              fill="white"
            />
          </svg>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
