import React, { useState } from "react";
import "./uploadFileFilter.scss";
import { Alert, Snackbar, TextField } from "@mui/material";
import useFile from "../hooks/useFile";
import dayjs from "dayjs";

const UploadFileFilter = ({
  filterRef,
  setListData,
  setFilterOpen,
  setFilterApplied,
  selectedFilter: initialSelectedFilter,
  startDate: initialStartDate,
  endDate: initialEndDate,
  onApplyFilter,
  onReset,
  applyFilterData,
}) => {
  const [selectedFilter, setSelectedFilter] = useState(initialSelectedFilter || "");
  const [selectedOption, setSelectedOption] = useState(false);
  const [startDate, setStartDate] = useState(initialStartDate || "");
  const [endDate, setEndDate] = useState(initialEndDate || "");
  const [error, setError] = useState({
    startDateError: "",
    endDateError: "",
  });
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorSnackbarMsg, setErrorSnackbarMsg] = useState("");
  const [snackbarErrorSeverity, setSnackbarErrorSeverity] = useState("error");
  const { uploadFileFilter, getAllFiles } = useFile();
  const filters = ["1W", "1M", "3M", "6M", "1Y", "Custom-Range"];

  const validateDate = (input) => {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    return dateRegex.test(input);
  };

  const convertedDateFormat = (input) => {
    if (!input) {
      return "";
    }
    const [month, day, year] = input.split("-");
    return `${year}-${month}-${day}`;
  };
  
  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    if (selectedFilter === filter) {
        setSelectedFilter("");
        setSelectedOption(false);
    } else {
        setStartDate("");
        setEndDate("");
        setError("");
        if (filter !== "Custom-Range") {
          setSelectedOption(selectedOption);
        }
    }
  };

  const handleDateChange = (e, setDate, errorName, compareDate) => {
    let inputDate = e.target.value;
    const inputDateLength = e.target.defaultValue.length; 
    const isDeletion = inputDate.length < inputDateLength;
    if ((inputDate.length === 2 || inputDate.length === 5) && !isDeletion) {
        inputDate += '-';
    }
    setDate(inputDate);
    const [month, day, year] = inputDate.split('-');
    const currentDate = dayjs();
    const parsedDate = dayjs(inputDate, "MM-DD-YYYY");
    const currentYear = currentDate.year();
    const previousYear = currentYear - 1;
    const isValidMonth = month >= 1 && month <= 12;
    const isValidDay = day >= 1 && day <= 31;
    const isValidYear = year == currentYear || year == previousYear;


    if (validateDate(inputDate)) {
      if (!isValidMonth) {
        setError((prevError) => ({
            ...prevError,
            [errorName]: "Please enter a valid month",
        }));
      }
      else if (!isValidDay) {
          setError((prevError) => ({
              ...prevError,
              [errorName]: "Please enter a valid date",
          }));
      }
      else if (!isValidYear) {
          setError((prevError) => ({
              ...prevError,
              [errorName]: "Please enter a valid year",
          }));
      }
      else if (parsedDate.isAfter(currentDate)) {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "Date cannot be in the future",
        }));
      } 
      else if (errorName === "startDateError" && compareDate && parsedDate.isAfter(dayjs(compareDate))) {
        setError((prevError) => ({
          ...prevError,
          startDateError: "Start date cannot be after end date",
        }));
      } else if (errorName === "endDateError" && compareDate && parsedDate.isBefore(dayjs(compareDate))) {
        setError((prevError) => ({
          ...prevError,
          endDateError: "End date cannot be before start date",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "",
        }));
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        [errorName]: "Please enter a date in the format MM-DD-YYYY",
      }));
    }
  };

  const getNoOfDays = (selectedFilter) => {
    switch (selectedFilter) {
      case "1W":
        return 7;
      case "1M":
        return 30;
      case "3M":
        return 90;
      case "6M":
        return 180;
      case "1Y":
        return 365;
      default:
        return "";
    }
  }; 

  const isButtonActive = () => {
    const isStartDateValid = validateDate(startDate) && !error?.startDateError;
    const isEndDateValid = validateDate(endDate) && !error?.endDateError;
    const convertedStartDate = convertedDateFormat(startDate);
    const convertedEndDate = convertedDateFormat(endDate);
    const isSelectedValid = selectedFilter !== "" && selectedFilter !== "Custom-Range";
  
    return (
      (isStartDateValid && isEndDateValid) || isSelectedValid
    );
  };
  
  let formattedStartDate = "";
  let formattedEndDate = "";

  const handleApplyButton = async () => {
    if (!buttonActive) return;
    try {
      const noOfDays = getNoOfDays(selectedFilter);
      formattedStartDate = convertedDateFormat(startDate);
      formattedEndDate = convertedDateFormat(endDate);

      const response = await uploadFileFilter(formattedStartDate , formattedEndDate , noOfDays);
      setListData(response);
      setFilterOpen(false);
      setFilterApplied(true);
      onApplyFilter(selectedFilter, startDate, endDate);
      applyFilterData(true);
    } catch (error) {
      showErrorSnackbar("Unexpected error occurred. Please try again after sometime.", "error");
    }
  };

  const handleReset = async () => {
    try {
      const response = await getAllFiles();
      setListData(response?.data?.results);
      setFilterOpen(false);
      setFilterApplied(false);
      onReset();
    } catch (error) {
      showErrorSnackbar("Unexpected error occurred. Please try again after sometime.", "error");
    }
  };

  const showErrorSnackbar = (message, severity) => {
    setErrorSnackbarMsg(message);
    setSnackbarErrorSeverity(severity);
    setOpenErrorSnackbar(true);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  const buttonActive = isButtonActive();
  const buttonStyle = {
    backgroundColor: buttonActive ? "#4e9386" : "#a6c9c2",
    cursor: buttonActive ? "pointer" : "not-allowed",
  };

  return (
    <div className="upload-filter-popup" ref={filterRef}>
      <div className="time-range-filters">
        <label className="label-container ">Time range</label>
        <div className="filters">
          {filters &&
            filters.length > 0 &&
            filters.map((filter, index) => (
              <div
                key={index}
                className={`range-Container ${
                  filter === "Custom-Range" ? "custom" : ""
                } ${selectedFilter === filter ? "selected" : ""}`}
                onClick={() => handleFilterClick(filter)}
              >
                {filter}
              </div>
            ))}
        </div>
        {selectedFilter === "Custom-Range" && (
          <div className="date-range-filter">
            <div className="filter-dates">
              <p>Start Date </p>
              <TextField
                disabled={selectedFilter !== "Custom-Range"}
                value={startDate}
                placeholder="MM-DD-YYYY"
                inputProps={{ maxLength: 10 }}
                onChange={(e) =>
                  handleDateChange(e, setStartDate, "startDateError", endDate)
                }
                sx={{
                  width: "100%",
                  borderRadius: "8px",
                  marginTop: "12px",
                  "& input": {
                    "-webkit-appearance": "none",
                    "-moz-appearance": "none",
                    appearance: "none",
                  },
                }}
              />
              {<p className="error">{error?.startDateError}</p>}
            </div>
            <div className="filter-dates-end-date">
              <p>End Date </p>
              <TextField
                value={endDate}
                disabled={selectedFilter !== "Custom-Range"}
                placeholder="MM-DD-YYYY"
                inputProps={{ maxLength: 10 }}
                onChange={(e) =>
                  handleDateChange(e, setEndDate, "endDateError", startDate)
                }
                sx={{
                  width: "100%",
                  borderRadius: "8px",
                  marginTop: "12px",
                  "& input": {
                    "-webkit-appearance": "none",
                    "-moz-appearance": "none",
                    appearance: "none",
                  },
                }}
              />
              {<p className="error">{error?.endDateError}</p>}
            </div>
          </div>
        )}
      </div>
      <div className="buttons">
        <button
          onClick={handleApplyButton}
          className="apply-btn"
          disabled={
            selectedFilter === "" ||
            error?.endDateError ||
            error?.startDateError
          }
          style={buttonStyle}
        >
          Apply
        </button>
        <button className="remove-btn" onClick={handleReset}>
          Reset Filter
        </button>
      </div>
      <Snackbar
      open={openErrorSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseErrorSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
      <Alert
        onClose={handleCloseErrorSnackbar}
        severity={snackbarErrorSeverity}
        variant="filled"
        sx={{
          width: '100%',
          bgcolor: '#FFECEF',
          color: '#3B3939',
          '& .MuiAlert-icon': {
          color: '#CA6069',
        },
          fontFamily: 'Chivo',
          fontWeight: 400,
          fontSize: "16px"
        }}
      >
        {errorSnackbarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default UploadFileFilter;
