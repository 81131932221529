import React, { useState } from "react";
import "./storeUrlComponent.scss";
import { IMGConnect,IMGbackArrow } from "../../images";
import { TextField } from "@mui/material";
import useWooCommerce from "../../hooks/useWooCommerce";

const StoreUrlComponent = ({setShowUrlComponent,setShowDateTime,setIsWooCommerseInActive}) => {
  const [url, setUrl] = useState("");
  const [storeStatus,setStoreStatus] = useState(1)
  const {conntectStore} = useWooCommerce();

  const handleConnectClick =async () => {
    console.log(url);
    // setShowUrlComponent(false);
    // setShowDateTime(true);
    const status =await conntectStore(url);
    setStoreStatus(status);
  }
  return (
    <div className="url-componet-container">
        <img style={{marginLeft:"33px",cursor:'pointer'}} src={IMGbackArrow} alt="back-arrow" onClick={() => {setIsWooCommerseInActive(true)}}/>
      <div className="connect-img-container">
        <img src={IMGConnect} alt="" />
      </div>
      <div className="headings-container">
        <div className="heading-styles">Connect With Woocommerce</div>
        <div className="sub-heading-styles">
        Please provide your store URL in order to continue.
        </div>
      </div>
      <div className="text-field-container">
        <TextField
          fullWidth
          id="standard-basic"
          label="Store URL"
          variant="standard"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <div style={{display:'flex',justifyContent:'center'}}>
          <button className="connect-button" onClick={handleConnectClick}> Next</button>
        </div>
        {!storeStatus && <div className="error-text">Store is already integrated</div>}
      </div>
    </div>
  );
};

export default StoreUrlComponent;
