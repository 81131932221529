import React, { useState } from 'react';
import './GpModal.scss';
import { IMGModalclose,IMGModalclose2 } from '../../images';

const GpModal = ({ show, handleClose, children, merchantOnboarded }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  console.log("this is merc" ,merchantOnboarded);

  return (
    <div className={showHideClassName}>
      <section className={`${merchantOnboarded === 'true' ? 'modal-main':'merchantOnboarded-modal-main'  }`}>
        {children}
        <img src={merchantOnboarded=== 'true' ?  IMGModalclose:IMGModalclose2} alt='close' onClick={handleClose} className="close-btn" />
      </section>
    </div>
  );
};

export default GpModal;
