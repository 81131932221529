import React from "react";
import "./deletePopup.scss";
import useWooCommerce from "../hooks/useWooCommerce";

const DeletePopup = ({ activeDataSource, setIsDeletePopupOpen,fetchData,getStoreInfo }) => {
  const { deleteDataSource } = useWooCommerce();

  const handleDelete = async () => {
    const response = await deleteDataSource(activeDataSource);
    fetchData();
    getStoreInfo();
    if(response) {
        setIsDeletePopupOpen(false);
    }
  };
  return (
    <div className="delete-popup-container">
      <div className="delete-popup-content">
        <div className="delete-bold-text">Delete data source confirmation </div>
        <div className="delete-lite-text">
          This{" "}
          <span className="delete-bold-text">
            {" "}
            will remove the data except chats related to this data source.
          </span>{" "}
          Do you want to proceed?
        </div>
        <div className="popup-buttons">
          <button
            className="cancel-button"
            onClick={() => setIsDeletePopupOpen(false)}
          >
            Cancel
          </button>
          <button
            className="delete-button"
            onClick={() => handleDelete()}
          >
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
