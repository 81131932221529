import React, { useState, useEffect } from "react";
import "./GpLinkedChatFilterEmbedScreen.scss";
import dayjs from "dayjs";
import { IMGGpModalLogo, IMGMonitoring } from "../../images";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../utils/itemSlice";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { setDataSourceName, setFirstChat } from "../../utils/dataSourceSlice";

const GpLinkedChatFilterEmbedScreen = ({ gorrilapayintegrationId }) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState({
    startDateError: "",
    endDateError: "",
  });
  const [intIdError, setIntIdError] = useState(null);
  const [days, setDays] = useState(null);
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const filters = ["1W", "1M", "3M", "6M", "1Y", "Custom-Range"];

  const validateDate = (input) => {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    return dateRegex.test(input);
  };

  const handleFilterClick = (filter) => {
    if (selectedFilter === filter) {
      setSelectedFilter("");
      setStartDate("");
      setEndDate("");
      setError({
        startDateError: "",
        endDateError: "",
      });
    } else {
      if (filter !== "Custom-Range") {
        setStartDate("");
        setEndDate("");
        setError({
          startDateError: "",
          endDateError: "",
        });
      }
      setSelectedFilter(filter);
    }
  };

  const handleDateChange = (e, setDate, errorName, compareDate) => {
    let inputDate = e.target.value;
    const inputDateLength = e.target.defaultValue.length; 
    const isDeletion = inputDate.length < inputDateLength;
    if ((inputDate.length === 2 || inputDate.length === 5) && !isDeletion) {
        inputDate += '-';
    }
    setDate(inputDate);
    const [month, day, year] = inputDate.split('-');
    const currentDate = dayjs();
    const parsedDate = dayjs(inputDate, "MM-DD-YYYY");
    const currentYear = currentDate.year();
    const previousYear = currentYear - 1;
    const isValidMonth = month >= 1 && month <= 12;
    const isValidDay = day >= 1 && day <= 31;
    const isValidYear = year == currentYear || year == previousYear;

    if (validateDate(inputDate)) {
      if (!isValidMonth) {
        setError((prevError) => ({
            ...prevError,
            [errorName]: "Please enter a valid month",
        }));
      }
      else if (!isValidDay) {
          setError((prevError) => ({
              ...prevError,
              [errorName]: "Please enter a valid date",
          }));
      }
      else if (!isValidYear) {
          setError((prevError) => ({
              ...prevError,
              [errorName]: "Please enter a valid year",
          }));
      }
      else if (parsedDate.isAfter(currentDate)) {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "Date cannot be in the future",
        }));
      } 
      else if (errorName === "startDateError" && compareDate && parsedDate.isAfter(dayjs(compareDate))) {
        setError((prevError) => ({
          ...prevError,
          startDateError: "Start date cannot be after end date",
        }));
      } else if (errorName === "endDateError" && compareDate && parsedDate.isBefore(dayjs(compareDate))) {
        setError((prevError) => ({
          ...prevError,
          endDateError: "End date cannot be before start date",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "",
        }));
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        [errorName]: "Please enter a date in the format MM-DD-YYYY",
      }));
    }
  };

  const convertToBackendFormat = (date) => {
    const [month, day, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };

  const handleStartChat = async () => {

    if (error.startDateError || error.endDateError) {
      return;
    }
    let formattedStartDate= "";
    let formattedEndDate= "" ;

    if(startDate && endDate){
      formattedStartDate = convertToBackendFormat(startDate);
      formattedEndDate = convertToBackendFormat(endDate);
    }

    dispatch(
      update({
        start_date: formattedStartDate || "",
        end_date: formattedEndDate || "",
        duration: days,
        data_intigration_id: gorrilapayintegrationId,
      })
    );
    dispatch(setDataSourceName("GORILLAPAY"));
    const dataSource = {
      datasource_identifier: "GORILLAPAY",
      start_date: formattedStartDate || "",
      end_date: formattedEndDate || "",
      no_of_days: days,
      data_intigration_id: gorrilapayintegrationId,
    };
    dispatch(setFirstChat(dataSource));

    naviagte(`/queries/${gorrilapayintegrationId}`);
  };
  
  useEffect(() => {
    if (selectedFilter === "1W") {
      setDays(7);
    }
    if (selectedFilter === "1M") {
      setDays(30);
    }
    if (selectedFilter === "3M") {
      setDays(90);
    }
    if (selectedFilter === "6M") {
      setDays(180);
    }
    if (selectedFilter === "1Y") {
      setDays(365);
    }
    if (selectedFilter === "Custom-Range") {
      setDays("");
    }
  }, [selectedFilter]);

  return (
    <div className="linkednewUerModalContainer">
      <div className="logoContainer">
        <img src={IMGGpModalLogo} alt="logo" />
      </div>
      <div className="linked-time-range-filters">
        <span>Analyze with GorillaPay</span>
        <label>Select time range for analysis</label>
        <div className="filters">
          {filters.map((filter, index) => (
            <div
              key={index}
              className={`range-Container ${
                filter === "Custom-Range" ? "custom" : ""
              } ${selectedFilter === filter ? "selected" : ""}`}
              onClick={() => handleFilterClick(filter)}
            >
              {filter}
            </div>
          ))}
        </div>
      </div>
      <div className="linked-date-range-filter">
        <div className="filter-dates">
          <p>Start Date </p>
          <TextField
            disabled={selectedFilter !== "Custom-Range"}
            value={startDate}
            placeholder="MM-DD-YYYY"
            inputProps={{ maxLength: 10 }}
            onChange={(e) =>
              handleDateChange(e, setStartDate, "startDateError", endDate)
            }
            // error={startDateError}
            sx={{
              width: "100%",
              borderRadius: "10px",
              marginTop: "10px",

              "& input": {
                "-webkit-appearance": "none",
                "-moz-appearance": "none",
                appearance: "none",
              },
            }}
          />
          {<p className="error">{error?.startDateError}</p>}
        </div>
        <div className="filter-dates-end-date">
          <p>End Date </p>
          <TextField
            // label="YYYY-MM-DD"
            value={endDate}
            disabled={selectedFilter !== "Custom-Range"}
            placeholder="MM-DD-YYYY"
            inputProps={{ maxLength: 10 }}
            onChange={(e) =>
              handleDateChange(e, setEndDate, "endDateError", startDate)
            }
            // error={endDateError}
            sx={{
              width: "100%",
              borderRadius: "10px",
              marginTop: "10px",
              "& input": {
                "-webkit-appearance": "none",
                "-moz-appearance": "none",
                appearance: "none",
              },
            }}
          />
          {<p className="error">{error?.endDateError}</p>}
        </div>
      </div>

      <div className="btn-container">
        <button
          className="linked-start-chat-btn"
          disabled={
            !((selectedFilter && selectedFilter !== "Custom-Range") || intIdError !== null ||
              (selectedFilter === "Custom-Range" && 
                startDate && 
                endDate && 
                !error.startDateError && 
                !error.endDateError
              )
            )
          }
          onClick={handleStartChat}
        >
          Start Chat
        </button>
      </div>

      <div
        className="linked-monitoring-container"
        onClick={() => naviagte("/dashboard")}
      >
        <img src={IMGMonitoring} alt="monitoring" />
        <span>Change Data Source</span>
      </div>
    </div>
  );
};

export default GpLinkedChatFilterEmbedScreen;
