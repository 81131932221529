import {IMGCrossIcon,IMGCsvIcon,IMGFileIcon, IMGPdfIcon, IMGXlsIcon, IMGXlsxIcon} from "../images";
import "./progressBarComponent.scss";

const ProgressBarComponent = ({ progressPercent ,selectedFile , setIsFileUploading ,removeSelectedFile, fileId}) => {

    const formatFileSize = (sizeInBytes) => {
        if (sizeInBytes >= 1024 * 1024) {
          return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
        } else if (sizeInBytes >= 1024) {
          return `${(sizeInBytes / 1024).toFixed(2)} KB`;
        } else {
          return `${sizeInBytes} B`;
        }
      };

      const getFileIcon = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        
        switch (extension) {
          case 'csv':
            return IMGCsvIcon;
          case 'pdf':
            return IMGPdfIcon;
          case 'xls':
            return IMGXlsIcon;
          case 'xlsx':
            return IMGXlsxIcon;
          default:
            return IMGFileIcon;
        }
      };

      const handleRemoveClick = (e, fileId) => {
        e.stopPropagation();
        setIsFileUploading(false);
        removeSelectedFile(fileId);
      };
      
  return (
    <div className="progress-bar">
      <div className="uploading-filename">
        <img className="file-icon" src={getFileIcon(selectedFile[0]?.name)} /> 
        <div className="file-info">
          {selectedFile[0]?.name }{" "}
          {formatFileSize(Number(selectedFile[0]?.size) || "")}
        </div>
        <img className="cross-icon" src={IMGCrossIcon}
          alt=""
          onClick={(e)=> handleRemoveClick(e, fileId)}
        />
      </div>
      <div
        className="progress-bar-fill"
        style={{
          width: `${progressPercent}%`}}
      ></div>
    </div>
  );
};

export default ProgressBarComponent;
