import React, { useEffect, useRef, useState } from "react";
import "./accountMenu.scss";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import useLogout from "../hooks/useLogOut";
import {
  IMGProfileIcon,
  ImgWooSm,
  ImgChatIcon,
  IMGAuthorizeNetMenuIcon,
  IMGDatasourceGp,
  IMGCalander,
  IMGDropdownArrow,
  IMGHollowDropdownArrow,
  IMGGreenDownloadIcon,
  IMGGreenDeleteIcon,
  IMGGreenPencilIcon,
  IMGGreenShareIcon,
  IMGGreyCalanderSmIcon,
  IMGHeaderHelpIcon,
  IMGPoductTourIcon,
  IMGHeaderHelpFilledIcon,
} from "../images";
import { useDispatch, useSelector } from "react-redux";
import useInsideHover from "../hooks/useInsideHover";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useOutsideHover from "../hooks/isOutsideHover";
import useOutsideClick from "../hooks/useOutsideClick";
import {
  PRODUCT_TOUR_KEY,
  STORAGE_KEY,
  introTourScreen,
  selectedDataSourceTourScreen,
  selectedFilesTourScreen,
} from "../config";
import { sessionStorageGetItem } from "../utils/sessionStorageUtils";
import useWooCommerce from "../hooks/useWooCommerce";
import useChatAction from "../hooks/useChatAction";
import { Alert, Snackbar } from "@mui/material";
import { updateChatTitle } from "../utils/dataSourceSlice";
import HeaderNamePopup from "./headerNamePopup";
import DashboardTour from "../dashboard/dashboardTour";
import { dashboardWithoutStoreUrl } from "../routes/routes";

const AccountMenu = ({
  handleOpen,
  setToggleChatDropdown,
  currentStepIndexforUploadFiles,
  setCurrentStepIndexforUploadFiles,
  currentStepForDatasource,
  setCurrentStepForDataSource,
  currentStepIndex,
  setCurrentStepIndex,
  resetProductTour,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isChatsDropdownOpen, setIsChatsDropdownOpen] = useState(false);
  const [chatTitles, setChatTitles] = useState("");
  const [chatId, setChatId] = useState("");
  const [isEditTitleDisabled, setIsEditTitleDisabled] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [successSnackbarMsg, setSuccessSnakbarMsg] = useState("");
  const [errorSnackbarMsg, setErrorSnackbarMsg] = useState("");
  const [snackbarSuccessSeverity, setSnackbarSuccessSeverity] =
    useState("success");
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [showProductTour, setShowProductTour] = useState(false);
  const [snackbarErrorSeverity, setSnackbarErrorSeverity] = useState("error");
  const { logout, logoutStatus } = useLogout();
  const popupRef = useRef(null);
  const dataSourceContainerRef = useRef(null);
  const editTitleRef = useRef(null);
  const helpRef = useRef(null);
  const open = Boolean(anchorEl);
  const { getChatInfo } = useWooCommerce();
  const { editChatTitle, downloadChat, deleteChat } = useChatAction();
  const routeParams = useParams();
  const dispatch = useDispatch();
  const dataSourceName = useSelector(
    (state) => state.dataSourceSlice.first_chat
  );
  const dataSourceDetails = useSelector(
    (state) => state.dataSourceSlice.all_chat
  );
  const activeChat = useSelector((state) => state.dataSourceSlice.all_chat);

  const AllChat = useSelector((state) => state.dataSourceSlice.total_chat);
  const location = useLocation();
  const navigate = useNavigate();
  const editChatTitleRef = useRef();
  const [openEditChatTitle, setOpenEditChatTitle] = useState(false);

  const getChatTitle = async () => {
    const chatId =
      sessionStorageGetItem(STORAGE_KEY.chat_id) || routeParams["chatId"];
    setChatId(chatId || "");

    if (
      location.pathname.startsWith("/queries") ||
      location.pathname.startsWith("/query")
    ) {
      setChatTitles(chatId ? activeChat?.title || "" : "");
    } else {
      setChatTitles("");
      setChatId("");
    }
  };

  useEffect(() => {
    getChatTitle();
  }, [location, activeChat]);

  {
    /*useOutsideHover is used because to make popup 
    close on hover (Date Popup Component)  */
  }

  // useOutsideHover(dataSourceContainerRef, popupOpen, setPopupOpen);
  // useOutsideHover(popupRef, popupOpen, setPopupOpen);
  // useInsideHover(dataSourceContainerRef, popupOpen, setPopupOpen);
  useOutsideClick(popupRef, popupOpen, setPopupOpen);
  useOutsideClick(editTitleRef, isChatsDropdownOpen, setIsChatsDropdownOpen);
  useOutsideClick(helpRef, isHelpOpen, setIsHelpOpen);

  const {
    no_of_days = "",
    datasource_identifier = "",
    start_date = "",
    end_date = "",
  } = dataSourceName || {};

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    setAnchorEl(null);
    try {
      await logout();
    } catch (error) {
      // Handle logout failure (e.g., display error message)
    }
  };

  const getDataSourceContent = () => {
    if (
      (!location.pathname.startsWith("/query") &&
        !location.pathname.startsWith("/queries")) ||
      !chatId
    ) {
      return;
    }
    if (!dataSourceName || dataSourceDetails?.datasource_identifier === null) {
      return (
        <>
          <div
            className="datasource-container"
            ref={dataSourceContainerRef}
            onClick={() => setPopupOpen(true)}
          >
            <div className="datasource-content">
              <img
                src={ImgChatIcon}
                alt="Local File"
                className="datasource-icon"
              />
              <span className="datasource-text">Linked with Local file</span>
            </div>
          </div>
          {currentStepIndexforUploadFiles === 1 && (
            <div
              style={{ position: "absolute", top: "-135px", right: "200px" }}
            >
              <DashboardTour
                currentStepInfo={selectedFilesTourScreen}
                currentStepIndex={currentStepIndexforUploadFiles}
                setCurrentStepIndex={setCurrentStepIndexforUploadFiles}
                steps={getSteps("currentStepIndexforUploadFiles")}
                flowName={PRODUCT_TOUR_KEY.show_upload_with_file_tour}
              />
            </div>
          )}
        </>
      );
    }
    switch (
      dataSourceDetails?.datasource_identifier ||
      dataSourceName?.data_source_identifier ||
      datasource_identifier
    ) {
      case "WOOCOMMERCE":
        return (
          <>
            {currentStepForDatasource === 1 && (
              <div
                style={{ position: "absolute", top: "-132px", right: "200px" }}
              >
                <DashboardTour
                  currentStepInfo={selectedDataSourceTourScreen}
                  currentStepIndex={currentStepForDatasource}
                  setCurrentStepIndex={setCurrentStepForDataSource}
                  steps={getSteps("currentStepForDatasource")}
                  flowName={PRODUCT_TOUR_KEY.show_product_tour}
                />
              </div>
            )}
            <div
              className="datasource-container"
              ref={dataSourceContainerRef}
              onClick={() => setPopupOpen(true)}
            >
              <div className="datasource-content">
                <img
                  src={ImgWooSm}
                  alt="WooCommerce"
                  className="datasource-icon"
                />
                <span className="datasource-text">Linked with WooCommerce</span>
                <img src={IMGGreyCalanderSmIcon} alt="" />
              </div>
            </div>
          </>
        );
      case "AUTHORIZE_NET":
        return (
          <div
            className="datasource-container"
            ref={dataSourceContainerRef}
            onClick={() => setPopupOpen(true)}
          >
            <div className="datasource-content">
              <img
                src={IMGAuthorizeNetMenuIcon}
                alt="Authorize.Net"
                className="datasource-icon"
              />
              <span className="datasource-text">Linked with Authorize.Net</span>
              <img src={IMGGreyCalanderSmIcon} alt="" />
            </div>
          </div>
        );
      case "GORILLAPAY":
        return (
          <div
            className="datasource-container"
            ref={dataSourceContainerRef}
            onClick={() => setPopupOpen(true)}
          >
            <div className="datasource-content">
              <img
                src={IMGDatasourceGp}
                alt="GorillaPay"
                className="datasource-icon"
              />
              <span className="datasource-text">Linked with Gorilla Pay</span>
              <img src={IMGGreyCalanderSmIcon} alt="" />
            </div>
          </div>
        );
      default:
        return (
          <div className="datasource-content">
            <img
              src={ImgChatIcon}
              alt="Local File"
              className="datasource-icon"
            />
            <span className="datasource-text">Linked with Local file</span>
          </div>
        );
    }
  };

  const getDataSourceName = () => {
    switch (
      dataSourceDetails?.datasource_identifier ||
      dataSourceName?.data_source_identifier ||
      datasource_identifier
    ) {
      case "WOOCOMMERCE":
        return "WooCommerce";
      case "AUTHORIZE_NET":
        return "Authorize.Net";
      case "GORILLAPAY":
        return "Gorilla Pay";
      default:
        return "Local file";
    }
  };

  const handleTitleChange = (e) => {
    setChatTitles(e.target.value);
  };

  const handleSaveTitle = async (chatTitles) => {
    try {
      await editChatTitle(chatId, chatTitles);
      const chatList = AllChat.map((item) => {
        const chat = { ...item };
        if (chat.id === chatId) {
          chat.title = chatTitles;
        }
        return chat;
      });
      dispatch(updateChatTitle(chatList));
      showSuccessSnackbar("Chat renamed successfully", "success");
      setIsEditTitleDisabled(true);
      setOpenEditChatTitle(false);
    } catch (error) {
      showErrorSnackbar(
        "Unexpected error occurred. Please try again after sometime.",
        "error"
      );
    }
  };

  const handleDownload = async (chatId) => {
    try {
      const response = await downloadChat(chatId);
      showSuccessSnackbar("Downloading Started Successfully", "success");
    } catch (error) {
      console.error(error);
      showErrorSnackbar(
        "Unexpected error occurred. Please try again after sometime.",
        "error"
      );
    }
  };

  const handleDelete = async (chatId) => {
    try {
      await deleteChat([chatId]);
      setIsChatsDropdownOpen(false);
      const chatList = AllChat.filter((item) => item.id !== chatId);
      if (chatList?.length === 0) {
        setToggleChatDropdown(false);
      }
      dispatch(updateChatTitle(chatList));
      showSuccessSnackbar("Deleted successfully!", "success");
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      showErrorSnackbar(
        "Unexpected error occurred. Please try again after sometime.",
        "error"
      );
    }
  };

  const handleEditTitleChange = (e) => {
    setIsEditTitleDisabled(false);
    setIsChatsDropdownOpen(false);
    setOpenEditChatTitle(true);
    // editChatTitleRef.current.focus();
    // editChatTitleRef.current.active();
  };

  const handleProductTour = () => {
      setIsHelpOpen(false);
      navigate(dashboardWithoutStoreUrl);
      removeLocalStorageItem(PRODUCT_TOUR_KEY.show_product_tour, "");
      removeLocalStorageItem(PRODUCT_TOUR_KEY.show_upload_with_file_tour, "");
      removeLocalStorageItem(PRODUCT_TOUR_KEY.show_upload_without_file_tour, "");
      removeLocalStorageItem(PRODUCT_TOUR_KEY.show_all_chat_tour, "");
      removeLocalStorageItem(PRODUCT_TOUR_KEY.show_date_filter_tour, "");
      resetProductTour();
  };

  const removeLocalStorageItem = (key) => {
    localStorage.removeItem(key);
  };

  const showSuccessSnackbar = (message, severity) => {
    setSuccessSnakbarMsg(message);
    setSnackbarSuccessSeverity(severity);
    setOpenSuccessSnackbar(true);
  };

  const showErrorSnackbar = (message, severity) => {
    setErrorSnackbarMsg(message);
    setSnackbarErrorSeverity(severity);
    setOpenErrorSnackbar(true);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessSnackbar(false);
  };

  // const showSnackbar = (message, severity) => {
  //   setSnackbarMessage(message);
  //   setSnackbarSeverity(severity);chatId
  //   setOpenSnackbar(true);
  // };
  // const handleCloseSnackbar = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpenSnackbar(false);
  // };

  const getSteps = (info) => {
    switch (info) {
      case "currentStepForDatasource":
        return 6;
      case "currentStepIndexforUploadFiles":
        return 2;
      default:
        return 0;
    }
  };

  return (
    <>
      <div className="chat-options-container">
        {chatId && (
          <>
            <input
              className="title-input-box"
              type="text"
              value={chatTitles}
              disabled={isEditTitleDisabled}
              onChange={handleTitleChange}
              // ref={editChatTitleRef}
            />
            <img
              className={`dropdown-img-classes ${
                isChatsDropdownOpen ? "rotate" : ""
              }`}
              src={IMGHollowDropdownArrow}
              alt=""
              onClick={() => {
                setIsChatsDropdownOpen(!isChatsDropdownOpen);
              }}
            />
          </>
        )}
      </div>
      <div className="profile-container">
        {getDataSourceContent()}

        {/* <React.Fragment> */}
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <div className="header-help-container">
            <img
              src={isHelpOpen ? IMGHeaderHelpFilledIcon : IMGHeaderHelpIcon}
              alt=""
              onClick={() => {
                setIsHelpOpen(!isHelpOpen);
              }}
            />
          </div>
          <Tooltip onClick={handleClick} title="Account settings">
            <img src={IMGProfileIcon} />
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>Add another account</MenuItem>
          <MenuItem onClick={handleClose}>Settings</MenuItem> */}
          <MenuItem onClick={handleLogOut}>Logout</MenuItem>
        </Menu>
        {popupOpen &&
          location.pathname.startsWith("/queries") &&
          dataSourceDetails?.datasource_identifier && (
            <div className="datasource-details-container" ref={popupRef}>
              <img src={IMGCalander} alt="" />
              <div className="bold-text-classes">
                {`${getDataSourceName()}`} data analyzed{" "}
                {dataSourceDetails?.start_date && dataSourceDetails?.end_date
                  ? `from ${dataSourceDetails?.start_date} to ${dataSourceDetails?.end_date}`
                  : dataSourceDetails?.no_of_days &&
                    `for ${dataSourceDetails?.no_of_days} days`}
                <br />
                <span
                  className="light-text-classes"
                  onClick={() => {
                    handleOpen();
                    setPopupOpen(false);
                  }}
                >
                  click to set new date range
                </span>
              </div>
            </div>
          )}
        {isHelpOpen && (
          <div className="help-dropdown-container" ref={helpRef}>
            <div className="help-option-container" onClick={handleProductTour}>
              <img src={IMGPoductTourIcon} alt="" />
              Product Tour
            </div>
          </div>
        )}
        {isChatsDropdownOpen && (
          <div className="chat-title-dropdown-container" ref={editTitleRef}>
            <div className="option-container" onClick={handleEditTitleChange}>
              <img src={IMGGreenPencilIcon} alt="" />
              Rename chat
            </div>
            {/* <div className="option-container">
              <img src={IMGGreenShareIcon} alt="" />
              Share Chat
            </div>
            <div
              className="option-container"
              onClick={() => handleDownload(chatId)}
            >
              <img src={IMGGreenDownloadIcon} alt="" />
              Download chat
            </div> */}
            <div
              className="option-container"
              onClick={() => handleDelete(chatId)}
            >
              <img src={IMGGreenDeleteIcon} alt="" />
              Delete chat
            </div>
          </div>
        )}
      </div>
      {/* </React.Fragment> */}
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity={snackbarErrorSeverity}
          variant="filled"
          sx={{
            width: "100%",
            bgcolor: "#FFECEF",
            color: "#3B3939",
            "& .MuiAlert-icon": {
              color: "#CA6069",
            },
            fontFamily: "Chivo",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {errorSnackbarMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSuccessSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity={snackbarSuccessSeverity}
          variant="filled"
          sx={{
            width: "100%",
            bgcolor: "#D4ECDA",
            color: "#3B3939",
            "& .MuiAlert-icon": {
              color: "#4A998A",
            },
            fontFamily: "Chivo",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {successSnackbarMsg}
        </Alert>
      </Snackbar>

      {openEditChatTitle && (
        <HeaderNamePopup
          chatTitle={chatTitles}
          setChatTitles={setChatTitles}
          chatId={chatId}
          handleSaveTitle={handleSaveTitle}
          setOpenEditChatTitle={setOpenEditChatTitle}
        />
      )}
    </>
  );
};

export default AccountMenu;
