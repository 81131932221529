import React from "react";
import "../../../forgotPassword/forgotPasswordComponent.scss";
import { useState } from "react";
import AuthWrapper from "../authWrapper/AuthWrapper";
import { TextField } from "@mui/material";
import { IMGWarning } from "../../../images";
import useSignUp from "../../../hooks/useSignUp";
import { useNavigate } from "react-router-dom";
import { registrationSuccess } from "../../../routes/routes";
const ResendVerification = () => {
  const {
    resentAccountActivationEmail,
    error,
  } = useSignUp();
  const [emailValid, setEmailValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [loading ,setLoading] = useState(false)
const navigate = useNavigate();

  const validateEmail = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValid) {
      setEmailValid(false);
      setErrorMsg("Enter Valid Email");
      return false;
    } else {
      setEmailValid(true);
      setErrorMsg("");
      return true;
    }
  };

  const handleSignup = async () => {
    const isEmailVaild = validateEmail();
    if (isEmailVaild) {
      setLoading(true)
     const res =  await resentAccountActivationEmail(email);
      if (res?.status === 201) {
      navigate(`${registrationSuccess}?email=${encodeURIComponent(email)}`);
      setLoading(false)
      }
      else{
        setErrorMsg(res?.response?.data?.non_field_errors[0])        
        setLoading(false)
      }
    }
  };
  
  return (
    <AuthWrapper>
      <div className="reset-password-container">
        <div className="forget-email-container">
          <div className="reset-password-form">
            <div className="form-container">
              <div className="forget-password-text">
                {" "}
                Resend Verification Email{" "}
              </div>
              <div className="forget-password-subtext">
                Enter the e-mail id to continue
              </div>
              <TextField
                type="email"
                name="email"
                label="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Business email"
                variant="outlined"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: emailValid ? 'inherit' : 'red',
                      borderWidth: emailValid ? '1px' : '2px', 
                    },
                    '&:hover fieldset': {
                      borderColor: emailValid ? 'inherit' : 'red',
                      borderWidth: emailValid ? '1px' : '2px', 
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: emailValid ? '#4E9386' : 'red',
                      borderWidth: '2px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: emailValid ? 'inherit' : 'red',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: emailValid ? '#4E9386' : 'red',
                  },
                }}
              />
              {errorMsg && (
                <div className="error-text">
                  <img src={IMGWarning} style={{ marginRight: "4px" }} />
                  {errorMsg}
                </div>
              )}
              <div className="button-container">
                <button className="button" onClick={handleSignup}>
                {loading ? 'Loading...' :'Continue'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default ResendVerification;
