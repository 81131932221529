import React, { useEffect, useState } from "react";
import UploadingFirstFileComponent from "./uploadingFirstFileComponent";
import DownloadAndDeleteUploadedFile from "./downloadAndDeleteUploadedFile";

const UploadedFile = ({ files, setIsRefreshChat ,
  currentStepIndexforUploadFiles, 
  setCurrentStepIndexforUploadFiles ,
  currentStepForDeleteFile,
  setCurrentStepForDeleteFile,
  isTourVisible,
  setIsTourVisible,
}) => {
  const [listData, setListData] = useState(files?.results || []);
  const [filterApplied,setFilterApplied] = useState(false);

  useEffect(() => {
    setListData(files?.results || []);
  }, [files]);

  const handleApplyData = (data) => {
    setFilterApplied(data)
  };
  const getSteps = (info) => {
    switch (info) {
      case 'currentStepIndexforUploadFiles':
        return 2;
      case 'currentStepForDeleteFile':
          return 2;
      default:
        return 0;
    }
  };

  return (

    <div className="upload-file-container">
      {listData?.length === 0 && filterApplied === false ? (
        <UploadingFirstFileComponent 
        currentStepIndexforUploadFiles={currentStepIndexforUploadFiles} 
        setCurrentStepIndexforUploadFiles={setCurrentStepIndexforUploadFiles}
        steps={getSteps('currentStepIndexforUploadFiles')}
        />
      ) : (
        <DownloadAndDeleteUploadedFile
          setIsRefreshChat={setIsRefreshChat}
          listData={listData}
          setListData={setListData}
          applyFilterData={handleApplyData}

          currentStepForDeleteFile={currentStepForDeleteFile}
          setCurrentStepForDeleteFile={setCurrentStepForDeleteFile}
          steps={getSteps('currentStepForDeleteFile')}
        />
      )}
    </div>
  );
};
export default UploadedFile;
