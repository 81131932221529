export const accountVerification = "/verify-email/:token";
export const failedVerification = "/failed-verification";
export const registrationSuccess = "/registration-success";
export const welcomePopup = "/welcomepopup";
export const forgetPassword = "/forget-password";
export const resend = "/reset/:token";
export const signIn = "/sign-in";
export const signUp = "/sign-up";
export const dashboard = "/dashboard/:storeurl";
export const dashboardWithoutStoreUrl = "/dashboard";
export const query = "/query";
export const querywithChatId = "/query/:chatId";
export const dateTimeRoute = '/woocommerce-sync/:storeurl'
export const gorillapayLinkedRoute = '/gorrilapay/:gorrilapayintegrationId'
export const authorizedotnetLinkedRoute = '/authorizedotne/:authorizedotneintegrationId'
export const uploadedFiles = '/uploadedFiles'
export const saved = '/saved'
export const dataSources = '/dataSources'
export const woocommerce = '/dataSources/woocommerce'
export const authorizedotnet = '/dataSources/authorizedotnet'
export const dataSourceChatScreens = "/queries/:datasourceId/:chatId?"
export const chatHistory = "/chatHistory"
export const newChatWithFile = "/newChat"
export const resendVerification = '/resend-verification'
