import React, { useEffect, useRef } from 'react'
import './AuthWrapper.scss'
import { IMGLogo, IMGManUsingTablet, IMGAiImage, IMGGradientAiImage } from '../../../images'
import { useLocation, useNavigate } from 'react-router-dom'
const AuthWrapper = ({ children }) => {
    const navigate = useNavigate();
    const scrollRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); 
      }, [location.pathname]); 

    return (
        <div className='authwrappermaincontainer' ref={scrollRef}>
            <div className='authwrapperLogoContainer'>
                <img src={IMGLogo} alt='LOGO' onClick={()=> {navigate('/')}}/>
            </div>
            <div className='authWrpperBodyContainer'>
                {children}
                <div className='authWrapperBannerContainer'>
                    <div className='bannerContainer'>
                        <img src={IMGManUsingTablet} alt='banner' />
                        <div className='textoverlaycontainer'>
                            {/* <div className='AiimageContainer'> */}
                                <img src={IMGGradientAiImage} alt='AI' />
                            {/* </div> */}
                            Ask anything, anytime!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AuthWrapper