import React, { useEffect } from "react";
import "./forgetEmailComponent.scss";
import { useState } from "react";
import useResetPassword from "../hooks/useResetPassword";
import { IMGErrorIcon, IMGWarning } from "../images";
import { Alert, Snackbar, TextField } from "@mui/material";

const ForgetEmailComponent = ({
  setShowEmailValid,
  setShowConfirmPasswordValid,
  setForgetEmail,
}) => {
  const { sendResetPasswordLink, error: resetError ,setError:setResetError } = useResetPassword();
  
  const [emailValid, setEmailValid] = useState(true);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [cancelToken, setCancelToken] = useState(null);


  const validateEmail = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValid) {
      setEmailValid(false);
      return false;
    } else {
      setEmailValid(true);
      return true;
    }
  };

  const handleSignup = async () => {
    const isEmailVaild = validateEmail();
    if (isEmailVaild) {
      const response = await sendResetPasswordLink(email);
      if (response) {
        setShowEmailValid(false);
        setShowConfirmPasswordValid(true);
      }
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    setResetError("");
    if (reason === "clickaway") {
      return;
    }
 
  };

  return (
    <>
      <div className="reset-password-form">
        <div className="form-container">
          <div className="forget-password-text">Forget Password ?</div>
          <div className="forget-password-subtext">
            Enter the e-mail id to continue
          </div>
          {/* <div className="email-txt">Email</div>
          <input
            className="forget-password-email-input"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setForgetEmail(e.target.value);
            }}
            placeholder=""
          /> */}
          <TextField
            type="email"
            name="email"
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setForgetEmail(e.target.value);
            }}
            placeholder="Business email"
            // InputLabelProps={{ shrink: true }}
            // className="forget-password-email-input"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: emailValid ? 'inherit' : 'red',
                  borderWidth: emailValid ? '1px' : '2px', 
                },
                '&:hover fieldset': {
                  borderColor: emailValid ? 'inherit' : 'red',
                  borderWidth: emailValid ? '1px' : '2px', 
                },
                '&.Mui-focused fieldset': {
                  borderColor: emailValid ? '#4E9386' : 'red',
                  borderWidth: '2px',
                },
              },
              '& .MuiInputLabel-root': {
                color: emailValid ? 'inherit' : 'red',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: emailValid ? '#4E9386' : 'red',
              },
            }}
          />
          {!emailValid && (
            <div className="error-text">
              <img src={IMGWarning} style={{ marginRight: "4px" }} />
              Enter Valid Email
            </div>
          )}
          <div className="button-container">
            <button className="button" onClick={handleSignup}>
              Continue
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={resetError}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={"error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {resetError}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ForgetEmailComponent;
