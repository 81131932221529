import React from "react";
import { IMGDocumentScannerIcon, IMGFileIcon, IMGMonitoring } from "../../images";
import "./chatViaFileEmbedScreen.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFirstChat } from "../../utils/dataSourceSlice";
import { setFileIds } from "../../utils/fileSlice";
import { sessionStorageSetItem } from "../../utils/sessionStorageUtils";
import { STORAGE_KEY } from "../../config";


const ChatViaFileEmbedScreen = () => {
  const navigate = useNavigate();
  const dataSourceName = useSelector((state) => state.dataSourceSlice.first_chat);
  const fileId = useSelector((state) => state.uploadFiles.fileIds)
  const fileUploadName = useSelector((state) => state.uploadFiles.uploadFiles);
  const fileNames = fileUploadName.map((file) => file.name);
  const dispatch = useDispatch();

  const handlerDataSource = () => {
    sessionStorageSetItem(STORAGE_KEY.chat_id, "");
    navigate("/query");
    dispatch(setFirstChat(dataSourceName));
    dispatch(setFileIds(fileId));
  };

  return (
    <div className="image-item-container">
      <div className="receipt-overall-container">
        <div className="receipt-icon" alt="">
          <img src={IMGDocumentScannerIcon} alt="" />
        </div>
        <div className="receipt-content">
          Files analyzed in chats will appear here.
        </div>
        <div className="upload-file-box">
          <div className="title-container">File uploaded</div>
          <div className="browse-box-container">
            <div className="text-container">
              <img className="file-icon" src={IMGFileIcon} />
              {fileNames}
            </div>
          </div>
        </div>
        <div className="start-button" onClick={handlerDataSource}>
          Start Chat
        </div>
        <div
        className="auth-linked-monitoring-container"
        onClick={() => navigate("/dashboard")}
      >
        <img src={IMGMonitoring} alt="monitoring" />
        <span>Change Data Source</span>
      </div>
      </div>
    </div>
  );
};

export default ChatViaFileEmbedScreen;
