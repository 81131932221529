import React from 'react'
import './ExistingUser.scss'
import { IMGGorillaPayYellowLogo ,IMGTickmark,IMGModalimg } from '../../../images'
import { merchant_onboarding_url } from '../../../config'

const ExistingUser = () => {
    
    const handleGetStartedClick = () => {
        window.open(merchant_onboarding_url, '_blank')
      };
    
    return (
        <div className='maincontainer'>
            <div className='existingUserModalContainer'>
                <div className='modalSubContainer'>
                    <img src={IMGGorillaPayYellowLogo} alt='logo' className='yellowLogo' />
                    <p>Revolutionizing Payments with AI</p>
                    <div className='listitem'><img src={IMGTickmark} />Instant Onboarding</div>
                    <div className='listitem'><img src={IMGTickmark} />AI Optimized Processing Rates</div>
                    <div className='listitem'><img src={IMGTickmark} />Accept Digital & Crypto Currency</div>
                </div>
                <div className='bannercontainer'>
                    <img src={IMGModalimg} alt='banner' />
                </div>
            </div>
            <button className='get-started-btn' onClick={handleGetStartedClick}>Get Started</button>
        </div>

    )
}

export default ExistingUser
