import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data_Source_Name:'',
  total_chat : [], // All chat List
  authorise_net_integration_id :'',
  first_chat: null,  // Latest chat
  all_chat: "",  // Active Chat
};

const dataSourceSlice = createSlice({
  name: 'dataSourceSliceInfo',
  initialState,
  reducers: {
    setDataSourceName:(state, action)=>{
      state.data_Source_Name = action.payload;
    },
    setTotalChat:(state,action)=>{
      state.total_chat = action.payload;
    },
    SetAuthorise_net_integration_id:(state,action)=>{
      state.authorise_net_integration_id = action.payload;
    },
    setFirstChat: (state, action) => {
      state.first_chat = action.payload;
    },
    setAllChat: (state, action) => {
      state.all_chat = action.payload;
    },
    updateChatTitle: (state, action) => {
      state.total_chat = action.payload
    },
  },
});

export const { setDataSourceName,setTotalChat,SetAuthorise_net_integration_id, setFirstChat, setAllChat, updateChatTitle } = dataSourceSlice.actions;
export default dataSourceSlice.reducer;