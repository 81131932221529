import axios from "axios";
import { useState } from "react";
import { API_URL, SSO_API_URL } from "../config";

const useAuth = () => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const activateUserAccount = async (token) => {
    try {
      console.log(token);
      const response = await axios.get(
        // `${API_URL}/auth/user/activate/?tk=${token}`
        `${SSO_API_URL}/api/v1/activate/?tk=${token}`
      );
      if (response.status !== 200) {
        throw new Error("Invalid credentials");
      }
      const { data } = response;
      console.log("activateUserAccount: ", data);
      setIsLoading(false);
      setSuccess(true);
    } catch (err) {
      setIsLoading(false);
      setError(err.response.data.error);
    }
  };

  return { activateUserAccount, isLoading, success, error };
};

export default useAuth;
