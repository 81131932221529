import { useState } from "react";

import { API_URL } from "../config";
import axiosInstance from "./axiosConfig";

const useFile = () => {
  const getPresignedFileUploadUrl = async (file_format) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/file-storage/presigned-url/`,
        { file_format }
      );
      return response;
    } catch (err) {
      throw err;
    }
  };

  const saveFiles = async (files) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/chat/files/`,
        files
      );
      return response;
    } catch (err) {
      throw err;
    }
  };

  const getAllFiles = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/chat/files/?limit=100`
      );
      return response;
    } catch (err) {
      throw err;
    }
  };

  const uploadFileFilter = async (startDate, endDate, selectedFilter) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/chat/files/?limit=20&start_date=${startDate}&end_date=${endDate}&no_of_days=${selectedFilter}`
      );
      return response?.data?.results;
    } catch (err) {
      throw err;
    }
  };

  const downloadFile = async (fileId) => {
    try{
      const response = await axiosInstance.post(`${API_URL}/file-storage/download-file/`,{
        file_id: fileId
      })
      const data = response?.data;
      if (response?.status === 200) {
        window.location.href = data?.presigned_url;
      }
      return response;
    } catch(error){
      throw error;
    }
  }

  return {
    getPresignedFileUploadUrl,
    saveFiles,
    getAllFiles,
    uploadFileFilter,
    downloadFile,
  };
};

export default useFile;
