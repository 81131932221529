import React, { useState } from "react";
// import Header from "../landingPage/Header";
// import Footer from "../landingPage/Footer";
import "./Welcomepopup.css";
import { IMGonboarding } from "../images";
import Footer from "../commonComponent/Footer";
import Header from "../commonComponent/Header";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router";
// import { mearchantOnboarding } from "../routes/routes";

const WelcomePopupComponent = ({onClosePopup}) => {
  const navigate = useNavigate();


  return (
    <>
    <Header showPublicPage={false}/>
      <div className="popup-container">
        <div className="welcome-popup">
          <div className="img-container">
          <img className="img" src={IMGonboarding} alt="onboarding image" />
          </div>
          <div className="welcomepage-welcome-text">Welcome Onboard</div>
          <div className="popup-welcome-description-text">
            Now you are a part of our community,
          </div>
          <div className="grow-text">Let’s grow together</div>
          <div className="continue-btn-container">
          <button onClick={() => navigate('/query')} className="continue-btn">Continue</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WelcomePopupComponent;
