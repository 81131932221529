import React, { useEffect, useRef, useState } from "react";
import "./headerNamePopup.scss";

const HeaderNamePopup = ({
  chatTitle,
  setChatTitles,
  handleSaveTitle,
  setOpenEditChatTitle,
}) => {
  const editChatTitleRef = useRef();

  useEffect(() => {
    editChatTitleRef.current.focus();
    editChatTitleRef.current.select();
  }, [editChatTitleRef]);

  const handleNameChange = (e) => {
    setChatTitles(e.target.value);
  };


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSaveTitle(chatTitle);
      setOpenEditChatTitle(false);
    }
  };

  return (
    <div className="name-popup-container">
      <div className="name-popup-wrapper">
        <div className="title-container">Enter New Chat Name</div>
        <input
          className="name-input"
          type="text"
          placeholder="Enter New Title"
          value={chatTitle}
          onChange={handleNameChange}
          onKeyDown={handleKeyDown}
          ref={editChatTitleRef}
        />
        <div className="button-container">
          <button
            className="cancel-button"
            onClick={() => setOpenEditChatTitle(false)}
          >
            Cancel
          </button>
          <button
            className="confirm-button"
            onClick={() => {
              handleSaveTitle(chatTitle);
              setOpenEditChatTitle(false);
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeaderNamePopup;
