import React, { useEffect } from "react";
import "./Header.scss";
import { IMGLogo } from "../images";
import { useNavigate } from "react-router-dom";
import { DOCUMENT_ROOT } from "../config";
// import AccountMenu from "./profileIcon";

const Header = ({ isLoggedIn = false, showPublicPage = true }) => {
  const navigate = useNavigate();

  return (
    <div className="header">
      <div className="xails-header-logo">
        <img
          src={IMGLogo}
          style={{ height: "35px", width: "130px", cursor: "pointer" }}
          onClick={() => navigate(DOCUMENT_ROOT)}
        />
      </div>
      <div className="xails-header-text">
        {showPublicPage ? (
          <>
            <span className="header-text">About Us</span>
            <span className="header-text">Contact Us</span>
          </>
        ):""
        // <AccountMenu/>
        }
      </div>
    </div>
  );
};

export default Header;
