import { useState } from 'react';
import { API_URL } from '../config';
import axiosInstance from './axiosConfig';

const useDeleteFiles = () => {

    const deleteFiles = async (file_ids) => {
        try {
            const response = await axiosInstance.post(`${API_URL}/chat/delete_files/`, {file_ids});
            return response;
        } catch (err) {
            throw err;
        }
    }
    return { deleteFiles}

}

export default useDeleteFiles;