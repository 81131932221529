import React, { useState, useEffect } from 'react'
import AuthWrapper from './authWrapper/AuthWrapper'
import useSignIn from '../../hooks/useSignIn';
import { useNavigate } from 'react-router-dom';
import { IMGCrossedEye, IMGErrorIcon, IMGEye, IMGWarning, IMGShowpwd } from '../../images';
import { forgetPassword } from '../../routes/routes';
import './NewSigninForm.scss'
import { Alert, Snackbar, snackbarClasses, TextField } from '@mui/material';
import useSignUp from '../../hooks/useSignUp';

const eyeWithoutErrorStyle = {
  float: "right",
  position: "relative",
  marginTop: "-33px",
  marginRight: "20px",
  cursor: "pointer",
}

const eyeWithErrorStyle = {
  float: "right",
  position: "relative",
  marginTop: "-52px",
  marginRight: "20px",
  cursor: "pointer",
}
const NewSignInForm = () => {
  const [businessEmail, setBusinessEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [showPassword, setShowPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSigninClicked, setIsSigninClicked] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleCheckboxChange = () => {
    setChecked(!checked);
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { signIn, error, respData } = useSignIn();
  const {resentAccountActivationEmail ,error:resendError, responseData,isVerificationEmailSent,} = useSignUp();
  console.log(resendError , responseData?.status ,isVerificationEmailSent);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
    if (isVerificationEmailSent) {
      setOpenSnackbar(true)
    }
  }, [error, isVerificationEmailSent]);

  const handleForgetPassword = () => {
    navigate(forgetPassword);
  };
  const validatePassword = () => {    
    // const minLength = 6;
    // const uppercaseRegex = /[A-Z]/;
    // const lowercaseRegex = /[a-z]/;
    // const numberRegex = /[0-9]/;
    // const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    let passwordValid = true;

    // if (password.length < minLength) {
    //   passwordValid = false;
    //   setPasswordErrorMsg('Password must have 6 to 15 charater.');
    // } else if (!uppercaseRegex.test(password)) {
    //   passwordValid = false;
    //   setPasswordErrorMsg('Password must have upper case charater.');
    // } else if (!lowercaseRegex.test(password)) {
    //   passwordValid = false;
    //   setPasswordErrorMsg('Password must have lower charater.');
    // } else if (!numberRegex.test(password)) {
    //   passwordValid = false;
    //   setPasswordErrorMsg('Password must have number.');
    // } else if (!specialCharRegex.test(password)) {
    //   passwordValid = false;
    //   setPasswordErrorMsg('Password must have special charater.');
    // } else {
    //   passwordValid = true;
    // }
    const pwdRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (password?.length === 0) {
      passwordValid = false;
      setPasswordErrorMsg(
        "Enter your password"
      );
    }else if (!pwdRegex.test(password)) {
    passwordValid = false;
    setPasswordErrorMsg(
      "Password must contain a minimum of 8 characters along with least a number, an uppercase letter a lowercase letter and a special character."
    );
  }
  setIsPasswordValid(passwordValid);
  return passwordValid;
    // setIsPasswordValid(passwordValid);
    // return passwordValid;
  };

  const handleInputChange = (e) => {
    if (e.target.name === "email") {
      setBusinessEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const validateEmail = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(businessEmail);
    if (!isValid) {
      setEmailValid(false);
      return false;
    } else {
      setEmailValid(true);
      return true;
    }
  };

 
  const handleEyeClick = () => {
    setShowPassword(!showPassword);
  };
  const handleSignin =async  () => {
    setIsSigninClicked(true);
    const isEmailValid = validateEmail();
    const passwordValid = validatePassword();
    setIsPasswordValid(passwordValid);
    if (isEmailValid && passwordValid) {
      setIsLoading(true)
      const data =  await signIn(businessEmail, password);
      if (data) {
        setIsLoading(false);

      }
      console.log(data);
    }
    setIsSigninClicked(false);
  };

  const handleSignup = () => {
    navigate('/sign-up')
  }
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (password !== "") {
      validatePassword();
    }
  }, [password]);
  
  return (
    <AuthWrapper>
      <div className="signin-form">
        <div className='labelContainer'>
          <label>Sign in</label>
          <p>
            Please login to continue to your account.
          </p>
        </div>
        <TextField
          type="email"
          name="email"
          label="Email"
          value={businessEmail}
          onChange={handleInputChange}
          onBlur={validateEmail}
          placeholder="Email Address"
          InputLabelProps={{ shrink: true }}
          // className="signup-input-classes-new"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: emailValid ? 'inherit' : 'red',
                borderWidth: emailValid ? '1px' : '2px', // Default 1px or 2px if invalid
              },
              '&:hover fieldset': {
                borderColor: emailValid ? 'inherit' : 'red',
                borderWidth: emailValid ? '1px' : '2px', // Keeps the border width consistent on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: emailValid ? '#4E9386' : 'red',
                borderWidth: '2px', // 2px border width when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: emailValid ? 'inherit' : 'red',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: emailValid ? '#4E9386' : 'red',
            },
          }}
        />
        {!emailValid && (
          <>
            <div className="password-error-text">
              <img src={IMGWarning} style={{ marginRight: '8px' }} />
              Enter valid E-mail address
            </div>
          </>
        )}
        <TextField
          type={showPassword ? "password" : "text"}
          name="password"
          label="Password"
          style={isPasswordValid ? { margin: "0px" } : { marginBottom: "20px" } }
          value={password}
          onChange={(e) => handleInputChange(e)}
          onBlur={validatePassword}
          placeholder="Enter Password"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: isPasswordValid ? 'inherit' : 'red',
                borderWidth: isPasswordValid ? '1px' : '2px', 
              },
              '&:hover fieldset': {
                borderColor: isPasswordValid ? 'inherit' : 'red',
                borderWidth: isPasswordValid ? '1px' : '2px', 
              },
              '&.Mui-focused fieldset': {
                borderColor: isPasswordValid ? '#4E9386' : 'red',
                borderWidth: '2px',
              },
            },
            '& .MuiInputLabel-root': {
              color: isPasswordValid ? 'inherit' : 'red',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: isPasswordValid ? '#4E9386' : 'red',
            },
          }}
        />
        <img
          src={showPassword ? IMGEye : IMGShowpwd}
          style={isPasswordValid ? eyeWithoutErrorStyle : eyeWithErrorStyle}
          onClick={handleEyeClick}
        />
        {!isPasswordValid && (
      
          <div className="password-error-text">
          <img src={IMGWarning}  className={`${passwordErrorMsg === 'Enter your password' ?'short-pwd-error' :'long-pwd-error'  }`} />
          {passwordErrorMsg}
        </div>
        )}
        {/* <div className='logged-in-container'>
          <input type="checkbox" checked={checked} onChange={handleCheckboxChange} />
          <span className="custom-checkbox"></span>
          Keep me logged in
        </div> */}
        <button className="button-new" disabled={isSigninClicked || !businessEmail || !password} onClick={handleSignin}>
           {isLoading ?'Loading...' :'Sign In'}
        </button>
        {error && (
          <div className="password-error-text" style={{ marginTop: "10px" }}>
            {error}
          </div>
        )}
        {
          respData?.data?.errorCode === 455 ?  <div className="resend-email-text" style={{ marginTop: "10px" }}  onClick={() => resentAccountActivationEmail(businessEmail)}>
         Click here to Resend Email
        </div> : ''
        }
        {/* {isLoading && <Spinner />} */}
        <div className="dont-have-account-text">

          <div className="forgot-text-new">
            <span onClick={handleForgetPassword}>Forgot Password ?</span>
          </div>
          <div className='orTextContainer'>
            <div className='line'>

            </div>
         <span>  or</span>
            <div className='line'>

            </div>
          </div>
          <div className='acc-create-text'>
            Need an account? <span onClick={handleSignup}>Create one</span>
          </div>

        </div>
      </div>
      <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={isVerificationEmailSent ? 'success':'error'}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {responseData?.status === 201 ? responseData?.data?.message : resendError}
          </Alert>
        </Snackbar>
    </AuthWrapper>

  )
}

export default NewSignInForm