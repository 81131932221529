import React from "react";
import "./Loader.scss";
import { IMGLoader } from "../../images";
const Loader = ({ dataSource }) => {
  return (
    <div className="loadercontainer">
      <div className="loading-img-container">
        <img src={IMGLoader} alt="" className="rotate-loader" />
      </div>
      <div className="loading-text">Loading Data from {dataSource}...</div>
    </div>
  );
};

export default Loader;
