import React, { useEffect, useRef, useState } from "react";
import "./downloadAndDeleteUploadedFile.scss";
import moment from "moment";
import useDeleteFiles from "../hooks/useDeleteFiles";
import { Alert, Snackbar } from "@mui/material";
import {
  IMGEnabledDeleteIcon,
  IMGEnabledDownloadIcon,
  IMGFilterIcon,
  IMGGreenDeleteIcon,
  IMGGreenDownloadIcon,
  IMGGreenFilterIcon,
  IMGGreyDeleteIcon,
  IMGGreyDownloadIcon,
  IMGInfoTooltip,
  IMGSearchIcon,
  IMGThreeDotIcon,
  IMGUploadedFileChatIcon,
} from "../images";
import useOutsideClick from "../hooks/useOutsideClick";
import UploadFileFilter from "./uploadFileFilter";
import { setFileIds } from "../utils/fileSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFirstChat } from "../utils/dataSourceSlice";
import useFile from "../hooks/useFile";
import {
  fileFilterTourScreen,
  fileOptionsTourScreen,
  PRODUCT_TOUR_KEY,
} from "../config";
import DashboardTour from "../dashboard/dashboardTour";

const DownloadAndDeleteUploadedFile = ({
  files,
  listData,
  setListData,
  setIsRefreshChat,
  applyFilterData,
  currentStepForDeleteFile,
  setCurrentStepForDeleteFile,
  steps,
}) => {
  const [checked, setChecked] = useState(false);
  const selectFilesRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const { deleteFiles } = useDeleteFiles();
  const { downloadFile } = useFile();
  const [showOption, setShowOption] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(listData);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [successSnackbarMsg, setSuccessSnakbarMsg] = useState("");
  const [errorSnackbarMsg, setErrorSnackbarMsg] = useState("");
  const [snackbarSuccessSeverity, setSnackbarSuccessSeverity] =
    useState("success");
  const [snackbarErrorSeverity, setSnackbarErrorSeverity] = useState("error");
  const [endDate, setEndDate] = useState("");
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [chatList, setChatList] = useState([]);
  const optionRef = useRef(null);
  const filterRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataSourceName = useSelector(
    (state) => state.dataSourceSlice.first_chat
  );
  useOutsideClick(optionRef, showOption, setShowOption);
  useOutsideClick(filterRef, filterOpen, setFilterOpen);

  useEffect(() => {
    if (searchTerm) {
      setFilteredData(
        listData.filter(
          (item) =>
            item?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            formatFileSize(Number(item?.size))
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredData(listData);
    }
  }, [searchTerm, listData]);

  const handleApplyFilter = (filter, startDate, endDate) => {
    setSelectedFilter(filter);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleResetState = () => {
    setSelectedFilter("");
    setStartDate("");
    setEndDate("");
    setFilterApplied(false);
  };

  const handleDeleteClick = () => {
    if (isAnyCheckboxChecked) {
      setConfirmDeletePopup(true);
    } else {
      showErrorSnackbar("Please select at least one checkbox.", "error");
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setListData((prevListData) =>
      prevListData.map((item) => ({
        ...item,
        isChecked: event.target.checked,
      }))
    );
  };

  const handleCheckboxChange = (index) => {
    setListData((prevListData) => {
      const updatedList = [...prevListData];
      if (updatedList?.length > 0 && updatedList[index]) {
        updatedList[index] = {
          ...updatedList[index],
          isChecked: !updatedList[index]?.isChecked,
        };
      }
      const allChecked =
        updatedList?.length > 0 && updatedList.every((file) => file?.isChecked);
      setChecked(allChecked);
      return updatedList;
    });
  };

  const handleDelete = async (fileIds) => {
    try {
      await deleteFiles(fileIds);
      setListData((prevListData) =>
        prevListData.filter((file) => !fileIds?.includes(file?.id))
      );
      setIsRefreshChat(true);
      showSuccessSnackbar("File(s) deleted successfully!");
      setOpenSuccessSnackbar(true);
    } catch (error) {
      showErrorSnackbar(
        "Unexpected error occured. Please try again after sometime."
      );
      setOpenErrorSnackbar(true);
    } finally {
      setConfirmDeletePopup(false);
      setActiveIndex(null);
    }
  };

  const handleConfirmDelete = () => {
    // Deletion based on condition whether it's a single or multiple files.
    const fileIdsToDelete =
      activeIndex !== null
        ? [listData[activeIndex]?.id]
        : listData.filter((file) => file?.isChecked).map((file) => file?.id);
    handleDelete(fileIdsToDelete);
    setConfirmDeletePopup(false);
  };

  const handleNewChat = (fileId) => {
    dispatch(setFileIds([fileId]));
    navigate("/query");
    dispatch(setFirstChat(dataSourceName));
  };

  const handleDownloadFile = async (fileId) => {
    try {
      const response = await downloadFile(fileId);
      showSuccessSnackbar("File downloaded successfully!", "success");
    } catch (error) {
      showErrorSnackbar(
        "Unexpected error occured. Please try again after sometime.",
        "error"
      );
    }
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    setSelectedFile([...selectedFile, ...files]);
    setFileUpload(true);
  };

  const handleToggleOption = (index) => {
    setShowOption((prevOptions) => ({
      ...prevOptions,
      [index]: !prevOptions[index],
    }));
  };

  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes >= 1024 * 1024) {
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    } else if (sizeInBytes >= 1024) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} B`;
    }
  };
  const isAnyCheckboxChecked = (listData || []).some((file) => file?.isChecked);

  const showSuccessSnackbar = (message, severity) => {
    setSuccessSnakbarMsg(message);
    setSnackbarSuccessSeverity(severity);
    setOpenSuccessSnackbar(true);
  };

  const showErrorSnackbar = (message, severity) => {
    setErrorSnackbarMsg(message);
    setSnackbarErrorSeverity(severity);
    setOpenErrorSnackbar(true);
  };

  const highlightText = (text, searchTerm) => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    let parts = text.split(regex);

    return parts.map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <strong key={i} style={{ color: "black" }}>
          {part}
        </strong>
      ) : (
        part
      )
    );
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessSnackbar(false);
  };

  return (
    <>
      <div className="upload-file-headder">Uploaded Files</div>
      <div className="upload-file-item-container">
        <div className="upload-icon-container">
          <div className="search-container">
            <img className="search-icon" src={IMGSearchIcon} alt="" />
            <input
              className="searchbar"
              type="text"
              placeholder="Search for data sources..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <img
              className="filter-icon"
              src={filterApplied ? IMGGreenFilterIcon : IMGFilterIcon}
              alt=""
              onClick={() => setFilterOpen(true)}
            />
            {filterOpen && (
              <>
                <UploadFileFilter
                  filterRef={filterRef}
                  setListData={setListData}
                  setFilterOpen={setFilterOpen}
                  setFilterApplied={setFilterApplied}
                  selectedFilter={selectedFilter}
                  startDate={startDate}
                  endDate={endDate}
                  onApplyFilter={handleApplyFilter}
                  onReset={handleResetState}
                  applyFilterData={applyFilterData}
                />
                {currentStepForDeleteFile === 1 && (
                  <div
                    ref={filterRef}
                    style={{
                      position: "absolute",
                      bottom: "150px",
                      left: "700px",
                    }}
                  >
                    <DashboardTour
                      currentStepInfo={fileFilterTourScreen}
                      currentStepIndex={currentStepForDeleteFile}
                      setCurrentStepIndex={setCurrentStepForDeleteFile}
                      steps={steps}
                      flowName={PRODUCT_TOUR_KEY.show_upload_without_file_tour}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="download-delete-iconcontainer">
            {/* <img
              className="grey-download-icon"
              src={
                isAnyCheckboxChecked
                  ? IMGEnabledDownloadIcon
                  : IMGGreyDownloadIcon
              }
              alt=""
            />
            <input
              type="file"
              ref={selectFilesRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            /> */}
            <img
              className="grey-delete-icon"
              src={
                isAnyCheckboxChecked ? IMGEnabledDeleteIcon : IMGGreyDeleteIcon
              }
              alt=""
              onClick={handleDeleteClick}
            />
            <input
              type="file"
              ref={selectFilesRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="overall-select-container ">
          <div className="select-text-container">
            <input
              className="select-all-input-box"
              type="checkbox"
              checked={checked}
              onChange={(event) => handleChange(event, files)}
            />
            <div className="select-all-text"> Select All</div>
          </div>
        </div>
        <div className="file-info-container">
          <div className="file-item-container">
            {filteredData &&
              Array.isArray(filteredData) &&
              filteredData?.length > 0 &&
              filteredData.map((item, index) => (
                <div className="file-items" key={index}>
                  <div className="filename-input-container">
                    <input
                      type="checkbox"
                      checked={item?.isChecked}
                      onChange={() => handleCheckboxChange(index)}
                    />
                    <span style={{ paddingLeft: "10px", color: "#6B6B6B" }}>
                      {highlightText(
                        item?.name.length > 30
                          ? `${item?.name.slice(0, 30)}... ${formatFileSize(
                              Number(item?.size)
                            )}`
                          : `${item?.name} ${formatFileSize(
                              Number(item?.size)
                            )}`,
                        searchTerm
                      )}
                    </span>
                  </div>
                  <div className="date-container">
                    <span style={{ color: "#95B4AF" }}>uploaded on </span>
                    <span style={{ color: "#438074", marginLeft: "5px" }}>
                      {moment(item?.creation_date).format("DD-MM-YYYY")}
                    </span>
                    <div className="three-dot-image">
                      <img
                        src={IMGThreeDotIcon}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onClick={() => handleToggleOption(index)}
                      />
                      <div style={{ position: "relative" }}>
                        {showOption?.[index] && (
                          <>
                            <div ref={optionRef} className="option-container">
                              <div
                                onClick={() => {
                                  setShowOption(false);
                                  setActiveIndex(index);
                                  handleNewChat(item.id);
                                }}
                                className="options"
                              >
                                <img src={IMGUploadedFileChatIcon} alt="" />{" "}
                                Analyse in new chat
                              </div>
                              <div
                                onClick={() => {
                                  setShowOption(false);
                                  setActiveIndex(index);
                                  handleDownloadFile(item.id);
                                }}
                                className="options"
                              >
                                <img src={IMGGreenDownloadIcon} alt="" />{" "}
                                Download File
                              </div>
                              <div
                                onClick={() => {
                                  setShowOption(false);
                                  setActiveIndex(index);
                                  setConfirmDeletePopup(true);
                                }}
                                onChange={handleFileChange}
                                ref={selectFilesRef}
                                className="options"
                              >
                                <img src={IMGGreenDeleteIcon} alt="" /> Remove
                                File
                              </div>
                              {currentStepForDeleteFile === 0 && (
                                <div
                                  ref={optionRef}
                                  style={{
                                    position: "absolute",
                                    right: "135px",
                                  }}
                                >
                                  <DashboardTour
                                    currentStepInfo={fileOptionsTourScreen}
                                    currentStepIndex={currentStepForDeleteFile}
                                    setCurrentStepIndex={
                                      setCurrentStepForDeleteFile
                                    }
                                    steps={steps}
                                    flowName={
                                      PRODUCT_TOUR_KEY.show_upload_without_file_tour
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {confirmDeletePopup && (
          <div className="confirm-delete-popup">
            <div className="confirm-delete-dialog">
              <span className="header-text">Delete File confirmation </span>
              <span>
                This will delete{" "}
                <strong>all the chats related to this file(s)</strong>. Do you
                want to proceed?
              </span>
              <div className="confirm-delete-buttons">
                <button
                  className="cancel-btn"
                  onClick={() => setConfirmDeletePopup(false)}
                >
                  Cancel
                </button>
                <button className="confirm-btn" onClick={handleConfirmDelete}>
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        )}
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseErrorSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseErrorSnackbar}
            severity={snackbarErrorSeverity}
            variant="filled"
            sx={{
              width: "100%",
              bgcolor: "#FFECEF",
              color: "#3B3939",
              "& .MuiAlert-icon": {
                color: "#CA6069",
              },
              fontFamily: "Chivo",
              fontWeight: 400,
              fontSize: "16px",
            }}
          >
            {errorSnackbarMsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={openSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSuccessSnackbar}
            severity={snackbarSuccessSeverity}
            variant="filled"
            sx={{
              width: "100%",
              bgcolor: "#D4ECDA",
              color: "#3B3939",
              "& .MuiAlert-icon": {
                color: "#4A998A",
              },
              fontFamily: "Chivo",
              fontWeight: 400,
              fontSize: "16px",
            }}
          >
            {successSnackbarMsg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
export default DownloadAndDeleteUploadedFile;
