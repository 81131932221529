import React, { useEffect, useState } from "react";
import "./syncWooCommercePopup.scss";
// import gpModalLogo from '../../../images/xailsImages/gpModalLogo.svg'
import dayjs from "dayjs";
import { Alert, Snackbar, TextField } from "@mui/material";
import {
  IMGConnect,
  IMGLoader,
  IMGMonitoring,
  ImgCrossIconSm,
  ImgReload,
  ImgWooLogo,
} from "../../images";
import useWooCommerce from "../../hooks/useWooCommerce";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../utils/itemSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useParams } from "react-router-dom";
import { setDataSourceName, setFirstChat } from "../../utils/dataSourceSlice";
import { PRODUCT_TOUR_KEY, WooCommerceTourScreen } from "../../config";
import DashboardTour from "../../dashboard/dashboardTour";
// import monitoring from '../../../images/xailsImages/monitoring.svg'

const SyncWooCommercePopup = ({ handleClose, storeurl , currentStepIndex,setCurrentStepIndex,steps}) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dataIntegrationId, setDataIntegrationId] = useState("");
  const [syncDays, setSyncDays] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    startDateError: "",
    endDateError: "",
  });
  const [fileErrMsg, setFileErrMsg] = useState("");
  const { dateDataHandler, getDataIntigrationId } = useWooCommerce();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Total_chat = useSelector((state) => state.dataSourceSlice.total_chat);
  // const { storeurl } = useParams();

  useEffect(() => {
    switch (selectedFilter) {
      case "1W":
        setSyncDays(7);
        break;
      case "1M":
        setSyncDays(30);
        break;
      case "3M":
        setSyncDays(90);
        break;
      case "6M":
        setSyncDays(180);
        break;
      case "1Y":
        setSyncDays(365);
        break;
      default:
        break;
    }
  }, [selectedFilter]);

  const filters = ["1W", "1M", "3M", "6M", "1Y", "Custom-Range"];

  useEffect(() => {
    getDataIntegrationIdHelper();
  }, []);

  const handleFilterClick = (filter) => {
    if (selectedFilter === filter) {
      setSelectedFilter("");
      setSyncDays("");
      setStartDate("");
      setEndDate("");
      setError({
        startDateError: "",
        endDateError: "",
      });
    } else {
      if (filter !== "Custom-Range") {
        setStartDate("");
        setEndDate("");
        setError({
          startDateError: "",
          endDateError: "",
        });
      } else {
        setSyncDays("");
      }
      setSelectedFilter(filter);
    }
  };

  //   const handleDateChange = (e, setDate, setError) => {
  //     const inputDate = e.target.value;
  //     setDate(inputDate);
  //   };

  // const handleDateChange = (value) => {
  //   const date = new Date(value);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based months
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const formattedDate = `${year}-${month}-${day}`;
  //   return formattedDate;
  // };

  const validateDate = (input) => {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    return dateRegex.test(input);
  };

  const handleDateChange = (e, setDate, errorName, compareDate) => {
    let inputDate = e.target.value;
    const inputDateLength = e.target.defaultValue.length;
    const isDeletion = inputDate.length < inputDateLength;
    if ((inputDate.length === 2 || inputDate.length === 5) && !isDeletion) {
      inputDate += "-";
    }
    setDate(inputDate);
    const [month, day, year] = inputDate.split("-");
    const currentDate = dayjs();
    const parsedDate = dayjs(inputDate, "MM-DD-YYYY");
    const currentYear = currentDate.year();
    const previousYear = currentYear - 1;
    const isValidMonth = month >= 1 && month <= 12;
    const isValidDay = day >= 1 && day <= 31;
    const isValidYear = year == currentYear || year == previousYear;

    if (validateDate(inputDate)) {
      if (!isValidMonth) {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "Please enter a valid month",
        }));
      } else if (!isValidDay) {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "Please enter a valid date",
        }));
      } else if (!isValidYear) {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "Please enter a valid year",
        }));
      } else if (parsedDate.isAfter(currentDate)) {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "Date cannot be in the future",
        }));
      } else if (
        errorName === "startDateError" &&
        compareDate &&
        parsedDate.isAfter(dayjs(compareDate))
      ) {
        setError((prevError) => ({
          ...prevError,
          startDateError: "Start date cannot be after end date",
        }));
      } else if (
        errorName === "endDateError" &&
        compareDate &&
        parsedDate.isBefore(dayjs(compareDate))
      ) {
        setError((prevError) => ({
          ...prevError,
          endDateError: "End date cannot be before start date",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "",
        }));
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        [errorName]: "Please enter a date in the format MM-DD-YYYY",
      }));
    }
  };

  const getDataIntegrationIdHelper = async () => {
    const dataId = await getDataIntigrationId(storeurl);
    setDataIntegrationId(dataId);
  };

  const convertToBackendFormat = (date) => {
    if (!date) {
      return "";
    }
    const [month, day, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };

  const handleConnectClick = async () => {
    if (error.startDateError || error.endDateError) {
      return;
    }
    let formattedStartDate = "";
    let formattedEndDate = "";
    if (startDate && endDate) {
      formattedStartDate = convertToBackendFormat(startDate);
      formattedEndDate = convertToBackendFormat(endDate);
    }
    try {
      setIsLoading(true);
      const response = await dateDataHandler({
        dataIntegrationId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        syncDays,
      });
      const userFilterInfo = {
        start_date: formattedStartDate || "",
        end_date: formattedEndDate || "",
        duration: syncDays,
        data_intigration_id: dataIntegrationId,
      };

      const dataSource = {
        datasource_identifier: "WOOCOMMERCE",
        start_date: formattedStartDate || "",
        end_date: formattedEndDate || "",
        no_of_days: syncDays,
        data_intigration_id: dataIntegrationId,
      };
      dispatch(setDataSourceName("WOOCOMMERCE"));
      dispatch(update(userFilterInfo));
      dispatch(setFirstChat(dataSource));
      if (response.status === 200) {
        navigate(`/queries/${dataIntegrationId}`);
      }
    } catch (e) {
      setFileErrMsg('Something went wrong. Please try again')
      setTimeout(() => {handleClose();},2000)
    }
  };

  return (
    <>
      <div className="sync-woo-commerce-popup">
        <div className="sync-woo-commerce-Container">
          <img
            className="cross-icon-classes"
            src={ImgCrossIconSm}
            alt=""
            onClick={handleClose}
          />
          {!isLoading ? (
            <>
              <div className="overall-img-container">
                <div className="connect-img-container">
                  <img src={IMGConnect} alt="" />
                </div>
              </div>
              <div className="time-range-filters">
                <span>Analyze with Woocommerce</span>
                <label>Select time range for analysis</label>
                <div className="filters">
                  {filters.map((filter, index) => (
                    <div
                      key={index}
                      className={`range-Container ${
                        filter === "Custom-Range" ? "custom" : ""
                      } ${selectedFilter === filter ? "selected" : ""}`}
                      onClick={() => handleFilterClick(filter)}
                    >
                      {filter}
                    </div>
                  ))}
                </div>
              </div>
              <div className="date-range-filter">
                <div className="filter-dates">
                  <p>Start Date </p>
                  <TextField
                    disabled={selectedFilter !== "Custom-Range"}
                    value={startDate}
                    placeholder="MM-DD-YYYY"
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) =>
                      handleDateChange(
                        e,
                        setStartDate,
                        "startDateError",
                        endDate
                      )
                    }
                    // error={startDateError}
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      marginTop: "10px",
                      "& input": {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "none",
                        appearance: "none",
                      },
                    }}
                  />
                  {<p className="error">{error?.startDateError}</p>}
                </div>
                <div className="filter-dates-end-date">
                  <p>End Date </p>
                  <TextField
                    // label="DD/MM/YYYY"
                    value={endDate}
                    placeholder="MM-DD-YYYY"
                    disabled={selectedFilter !== "Custom-Range"}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) =>
                      handleDateChange(e, setEndDate, "endDateError", startDate)
                    }
                    // error={endDateError}
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      marginTop: "10px",
                      "& input": {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "none",
                        appearance: "none",
                      },
                    }}
                  />
                  {<p className="error">{error?.endDateError}</p>}
                </div>
              </div>
              {/* <div className="date-range-filter">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              inputFormat="yyyy-MM-dd"
              label="Start Date"
              value={dayjs(startDate)}
              onChange={(newValue) => setStartDate(handleDateChange(newValue))}
              disableFuture={true}
              disabled={selectedFilter !== "Custom-Range"}
              maxDate={dayjs(endDate)}
              sx={{ height: "50%", borderRadius: "10px" }}
            />

            <DatePicker
              inputFormat="yyyy-MM-dd"
              label="End Date"
              value={dayjs(endDate)}
              onChange={(newValue) => setEndDate(handleDateChange(newValue))}
              disableFuture={true}
              disabled={selectedFilter !== "Custom-Range"}
              minDate={dayjs(startDate)}
              sx={{ height: "50%", borderRadius: "10px" }}
            />
          </LocalizationProvider>
        </div> */}
              <div className="btn-container">
                <button
                  className="start-chat-btn"
                  disabled={
                    !(
                      (selectedFilter && selectedFilter !== "Custom-Range") ||
                      (selectedFilter === "Custom-Range" &&
                        startDate &&
                        endDate &&
                        !error.startDateError &&
                        !error.endDateError)
                    )
                  }
                  onClick={handleConnectClick}
                >
                  Start Chat
                </button>
              </div>
              {/* render only if user have chat history*/}
              {Total_chat?.length > 0 ? (
                <div className="monitoring-container" onClick={handleClose}>
                  <img src={IMGMonitoring} alt="monitoring" />
                  <span>Change Data Source</span>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <div className="loading-img-container">
                <img
                  src={IMGLoader}
                  alt=""
                  className="rotate-loader"
                  // style={{
                  //   animation: "rotate-loader 1.5s linear infinite reverse",
                  // }}
                />
              </div>
              <div className="loading-text">
                Loading data from Woocommerce...
              </div>
            </>
          )}
        </div>
      </div>
        <div style={{position:'absolute',top:'0px',left:'1156px'}}>
          <DashboardTour
          currentStepInfo={WooCommerceTourScreen}
          currentStepIndex={2}
          setCurrentStepIndex={setCurrentStepIndex}
          flowName={PRODUCT_TOUR_KEY.show_date_filter_tour}
          steps={steps}
        />
        </div>
      <Snackbar
        open={fileErrMsg !== ""}
        autoHideDuration={6000}
        onClose={() => setFileErrMsg("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFileErrMsg("")}
          severity="error"
          variant="filled"
          sx={{
            width: "100%",
            bgcolor: "#FFECEF",
            color: "#3B3939",
            "& .MuiAlert-icon": {
              color: "#CA6069",
            },
            fontFamily: "Chivo",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {fileErrMsg}
        </Alert>
      </Snackbar>
    </>
  );
};
export default SyncWooCommercePopup;
