import { useState } from 'react';
import axios from 'axios';
import { API_URL  , SSO_API_URL} from '../config';


const useResetPassword = () => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState();

  const sendResetPasswordLink = async (email) => {
    try {
      setEmail(email);
      const response = await axios.post(`${SSO_API_URL}/api/v1/user/forget-password/`,
        { email }
      );
      if (response.status !== 201) {
        throw new Error('Password reset failed');
      }
     
      return response;
    } catch (error) {
      if (error.response.status === 404) {
        setError('Please enter a registered email address to proceed')
      }
      console.error("Error sending email:", error);
    }
  };

  const resetPassword = async (password, confirm_password, token = '') => {
    try {
      const response = await axios.patch(`${SSO_API_URL}/api/v1/user/reset-password/`, {
        password,
        confirm_password,
        token,
      });
      if (response.status !== 200) {
        throw new Error('Password reset failed');
      }

      const data = response.data;
      console.log('Password reset successful:', data);
      return response;
    } catch (err) {
      setError('Password reset failed. Please try again.');
      console.error('Password reset error:', err);
    }
  };

  return { resetPassword, sendResetPasswordLink, email, error ,setError };
};

export default useResetPassword;
