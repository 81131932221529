import React, { useState } from "react";
import "./connectWooCommerceUrl.scss";
import { IMGConnect, ImgCrossIconSm } from "../../images";
import { TextField } from "@mui/material";
import useWooCommerce from "../../hooks/useWooCommerce";

const ConnectWooCommerceUrl = ({
  isWooCommerseInActive,
  setIsWooCommerseInActive,
}) => {
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [storeStatus, setStoreStatus] = useState(1);
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [buttonText, setButtonText] = useState("Continue");
  const { conntectStore } = useWooCommerce();

  function validateLink(url) {
    try {
      new URL(url);
      setIsLinkValid(true);
      return true;
    } catch (e) {
      setIsLinkValid(false);
      return false;
    }
  }

  const handleConnectClick = async () => {
    setButtonText("Loading...");
    const status = await conntectStore(url, "WOOCOMMERCE");
    if (status === "Enter a valid URL.") {
      setError(status);
      setButtonText("Continue");
    } else {
      setError("");
    }
    setStoreStatus(status);
  };

  return (
    <div className="connect-woo-commerce-url-container">
      <div className="connect-woo-commerce-url-popup">
        <img
          className="cross-icon-classes"
          src={ImgCrossIconSm}
          alt=""
          onClick={() => setIsWooCommerseInActive(false)}
        />
        <div className="overall-img-container">
          <div className="connect-img-container">
            <img src={IMGConnect} alt="" />
          </div>
        </div>
        <div className="headings-container">
          <div className="heading-styles">Connect With Woocommerce</div>
        </div>
        <div className="text-field-container">
          <input
            className="store-input-field"
            id="standard-basic"
            label="Store URL"
            variant="standard"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              validateLink(e.target.value);
            }}
            placeholder="enter store url..."
          />
          {/* {!isLinkValid && (
            <div className="error-text">Error! Couldn’t find link</div>
          )} */}
          {!storeStatus && (
            <div className="error-text">Store is already integrated</div>
          )}
          {error && <div className="error-text">{error}</div>}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="connect-button"
              style={!isLinkValid ? { background: "#909090" } : {}}
              onClick={handleConnectClick}
              disabled={!isLinkValid}
            >
              {" "}
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWooCommerceUrl;
