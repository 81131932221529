import { useState, useEffect } from 'react';
import { API_URL, STORAGE_KEY } from '../config';
import { localStorageGetItem } from './localStorageUtils';
import axios from 'axios';

  export const saveToken = (key, token, expires) => {
    const item = {
      token,
      expires
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  export const getToken = (key) => {
    if (!localStorage.getItem(key)) {
      return null;
    }
    const token  = JSON.parse(localStorage.getItem(key));
    if (!token) {
      return null;
    }
    return ('Bearer '+token);
  };

  export const flushToken = (key) => {
    localStorage.getItem(key) && localStorage.removeItem(key);
  };

  export const getRefreshToken = async () => {
    const token = getToken(STORAGE_KEY.access_token);
    const refresh = getToken(STORAGE_KEY.refresh_token);
    const axiosOptions = {
      headers: {
        Authorization: token,
      },
    };
    return axios.post(`${API_URL}/auth/user/refresh/`, { refresh }, axiosOptions);
  };

//   return {
//     saveToken,
//     getToken,
//     flushToken
//   };
// };

// export default useSessionUtils;
