import React from "react";
import "./pendingTransactionPopup.scss";
import { ImgWooSm, ImgUploadFile, ImgChatIcon,IMGAuthorizeNetMenuIcon, IMGDatasourceGp } from "../images";
import { Popover } from "@mui/material";
import moment from "moment";

// const urlLinks = ["l1", "l2"];

const PendingTransactionPopup = ({
  fileData = {},
  styleClasses = {},
  open,
  anchorEl,
  onClose,
  fileList,
  item
}) => {
  const { files, title, creation_date, last_modified_date } = fileData;

  const getSuperScriptIcon = (dataSourceName) => {
    switch (dataSourceName) {
      case 'GORILLAPAY':
        return { src: IMGDatasourceGp, title: 'Gorilla Pay' };
      case 'AUTHORIZE_NET':
        return { src: IMGAuthorizeNetMenuIcon, title: 'Authorize.Net' };
      case 'WOOCOMMERCE':
        return { src: ImgWooSm, title: 'WooCommerce' };
      default:
        return { src: ImgChatIcon, title: 'Default' };
    }
  };

  const dataSourceIcon = getSuperScriptIcon(item?.datasource_identifier);
  

  return (
    <Popover
      sx={{
        pointerEvents: "none",
        '.MuiPopover-paper': {
          padding: '0',
          backgroundColor: 'transparent',
          borderRadius:"12px",
          marginLeft:"-40px"
        },
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      // transformOrigin={{
      //   vertical:"top",
      //   horizontal:"left"
      // }}
      onClose={onClose}
      disableRestoreFocus
    >
      <div style={styleClasses} className="pending-transaction-popup-container">
        <div className="msg-icon-and-title-container">
          <img src={dataSourceIcon?.src} alt="" />
          <div className="heading-text">{title}</div>
        </div>
        <div className="date-container">
          <div className="created-date">Created
            <span className="chat-date">{moment(creation_date).format("MM-DD-YYYY")}</span>
          </div>
          <div className="created-date">Updated
            <span className="chat-date">{moment(last_modified_date).format("MM-DD-YYYY")}</span>
          </div>
        </div>

        {files &&
          Array.isArray(files) &&
          files.length > 0 &&
          files.map((file, index) => (
            <>
              <div className="divider"></div>
              <div className="heading-info">Uploaded Files</div>
              <div className="file-list-container">
                <img src={ImgUploadFile} alt="" />
                <div key={index} className="file-list-text">{file?.name}</div>
              </div>
            </>
          ))}
      </div>
    </Popover>
  );
};

export default PendingTransactionPopup;
