import React, { useState, useEffect } from "react";
import AuthWrapper from "./authWrapper/AuthWrapper";
import "./NewSignupForm.scss";
import { useNavigate } from "react-router-dom";
import useSignUp from "../../hooks/useSignUp";
import { IMGEye, IMGShowpwd, IMGWarning } from "../../images";
import { signIn } from "../../routes/routes";
import { TextField } from "@mui/material";

const eyeWithoutErrorStyle = {
  float: "right",
  position: "relative",
  marginTop: "-52px",
  marginRight: "20px",
  cursor: "pointer",
};

const eyeWithErrorStyle = {
  float: "right",
  position: "relative",
  marginTop: "-52px",
  marginRight: "20px",
  cursor: "pointer",
};
const confirmpwdeyeWithoutErrorStyle = {
  float: "right",
  position: "relative",
  marginTop: "-35px",
  marginRight: "20px",
  cursor: "pointer",
};
const confirmpwdeyeWithErrorStyle = {
  float: "right",
  position: "relative",
  marginTop: "-44px",
  marginRight: "20px",
  cursor: "pointer",
};

const NewSignupForm = () => {
  const [name, setName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [nameValid, setNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);
  const [isCheckedError, setIsCheckedError] = useState("");
  const [isSumbit, setIsSumbit] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [formError, setFormError] = useState("");
  const [isSignUpClicked, setIsSignUpClicked] = useState(false);

  const { signUp, error, response } = useSignUp();
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleInputChange = (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "email") {
      setBusinessEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    }
  };

  const handleSignup = async () => {
    setIsSignUpClicked(true);
    const isValid = checkValidations();
    if (
      isValid &&
      isChecked &&
      nameValid &&
      emailValid &&
      isPasswordValid &&
      isConfirmPasswordValid
    ) {
      setIsLoading(true);
      await signUp(name, businessEmail, password, confirmPassword, isChecked);
      setIsLoading(false);
    }
    setIsSignUpClicked(false);
  };

  const validateName = () => {
    if (name.length < 2) {
      setNameValid(false);
      return false;
    } else {
      setNameValid(true);
      return true;
    }
  };
  const handleEyeClick = () => {
    setShowPassword(!showPassword);
  };
  const handleConfirmPwdEyeClick = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const validateEmail = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(businessEmail);
    if (!isValid) {
      setEmailValid(false);
      return false;
    } else {
      setEmailValid(true);
      return true;
    }
  };

  const validateTnc = () => {
    if (!isChecked) {
      setIsCheckedError("Please Accept Term And Condition");
      return false;
    } else {
      setIsCheckedError("");
      return true;
    }
  };

  const validatePassword = () => {
    // const minLength = 6;
    // const uppercaseRegex = /[A-Z]/;
    // const lowercaseRegex = /[a-z]/;
    // const numberRegex = /[0-9]/;
    // const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    let passwordValid = true;

    // if (password.length < minLength) {
    //   passwordValid = false;
    //   setPasswordErrorMsg("Password must have 6 to 15 charater.");
    // } else if (!uppercaseRegex.test(password)) {
    //   passwordValid = false;
    //   setPasswordErrorMsg("Password must have upper case charater.");
    // } else if (!lowercaseRegex.test(password)) {
    //   passwordValid = false;
    //   setPasswordErrorMsg("Password must have lower charater.");
    // } else if (!numberRegex.test(password)) {
    //   passwordValid = false;
    //   setPasswordErrorMsg("Password must have number.");
    // } else if (!specialCharRegex.test(password)) {
    //   passwordValid = false;
    //   setPasswordErrorMsg("Password must have special charater.");
    // } else {
    //   passwordValid = true;
    // }
    // setIsPasswordValid(passwordValid);
    // return passwordValid;

    const pwdRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (password?.length === 0) {
        passwordValid = false;
        setPasswordErrorMsg(
          "Enter your password"
        ) }
      else if (!pwdRegex.test(password)) {
      passwordValid = false;
      setPasswordErrorMsg(
        "Password must contain a minimum of 8 characters along with least a number, an uppercase letter a lowercase letter and a special character."
      );
    }
    setIsPasswordValid(passwordValid);
    return passwordValid;
  };

  const validateConfirmPassword = () => {
    if (password === confirmPassword) {
      setIsConfirmPasswordValid(true);
    } else {
      setIsConfirmPasswordValid(false);
    }
  };

  const checkValidations = () => {
    const nameCheck = validateName();
    const emailCheck = validateEmail();
    const passwordValid = validatePassword();
    const tncCheck = validateTnc();
    setIsPasswordValid(passwordValid);
    if (password === confirmPassword) {
      setIsConfirmPasswordValid(true);
    } else {
      setIsConfirmPasswordValid(false);
    }
    return nameCheck && emailCheck && passwordValid;
  };

  useEffect(() => {
    setFormError(error);
  }, [error]);

  useEffect(() => {
    if (password !== "") {
      validatePassword();
    }
  }, [password]);

  return (
    <AuthWrapper>
      <div className="signup-form-container">
        {/* <input
        type="text"
        name="name"
        value={name}
        onChange={(e) => handleInputChange(e)}
        placeholder="Name"
        className="signup-input-classes-new"
      /> */}
        <div className="labelContainer">
          <label>Sign up</label>
          <p>Sign up to enjoy the feature of Revolutie</p>
        </div>
        <TextField
          type="text"
          name="name"
          label="Full Name"
          value={name}
          onChange={(e) => handleInputChange(e)}
          onBlur={validateName}
          placeholder="Full Name"
          //    className="signup-input-classes-new"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: nameValid ? 'inherit' : 'red',
                borderWidth: nameValid ? '1px' : '2px', 
              },
              '&:hover fieldset': {
                borderColor: nameValid ? 'inherit' : 'red',
                borderWidth: nameValid ? '1px' : '2px', 
              },
              '&.Mui-focused fieldset': {
                borderColor: nameValid ? '#4E9386' : 'red',
                borderWidth: '2px',
              },
            },
            '& .MuiInputLabel-root': {
              color: nameValid ? 'inherit' : 'red',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: nameValid ? '#4E9386' : 'red',
            },
          }}
        />
        {!nameValid && name?.length<2 && (
          <div className="password-error-text">
            <img src={IMGWarning} style={{ marginRight: "4px" }} />
            { name?.length ===0 ? 'Enter your name' :'Enter valid Name' }
          </div>
        )}
        {/* <input
        type="text"
        name="email"
        value={businessEmail}
        onChange={(e) => handleInputChange(e)}
        placeholder="Business email"
        className="signup-input-classes-new"
      /> */}
        <TextField
          type="text"
          name="email"
          label="Email"
          value={businessEmail}
          onChange={(e) => handleInputChange(e)}
          placeholder="Email Address"
          onBlur={validateEmail}
          InputLabelProps={{ shrink: true }}
          // className="signup-input-classes-new"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: emailValid ? 'inherit' : 'red',
                borderWidth: emailValid ? '1px' : '2px', 
              },
              '&:hover fieldset': {
                borderColor: emailValid ? 'inherit' : 'red',
                borderWidth: emailValid ? '1px' : '2px', 
              },
              '&.Mui-focused fieldset': {
                borderColor: emailValid ? '#4E9386' : 'red',
                borderWidth: '2px',
              },
            },
            '& .MuiInputLabel-root': {
              color: emailValid ? 'inherit' : 'red',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: emailValid ? '#4E9386' : 'red',
            },
          }}
        />
        {!emailValid && (
          <div className="password-error-text">
            <img src={IMGWarning} style={{ marginRight: "4px" }} />
            Enter valid E-mail address
          </div>
        )}
        {/* <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Password"
                    className="signup-input-classes-new"
                /> */}
        <TextField
          type={showPassword ? "password" : "text"}
          name="password"
          label="Password"
          onChange={(e) => handleInputChange(e)}
          placeholder="Enter Password"
          // style={isPasswordValid ? { margin: "0px" } : { marginBottom: "12px" }}
          value={password}
          onBlur={validatePassword}
          InputLabelProps={{ shrink: true }}
          // className="signup-input-classes-new"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: isPasswordValid ? 'inherit' : 'red',
                borderWidth: isPasswordValid ? '1px' : '2px', 
              },
              '&:hover fieldset': {
                borderColor: isPasswordValid ? 'inherit' : 'red',
                borderWidth: isPasswordValid ? '1px' : '2px', 
              },
              '&.Mui-focused fieldset': {
                borderColor: isPasswordValid ? '#4E9386' : 'red',
                borderWidth: '2px',
              },
            },
            '& .MuiInputLabel-root': {
              color: isPasswordValid ? 'inherit' : 'red',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: isPasswordValid ? '#4E9386' : 'red',
            },
          }}
        />
        <img
          src={showPassword ? IMGEye : IMGShowpwd}
          style={isPasswordValid ? eyeWithoutErrorStyle : eyeWithErrorStyle}
          onClick={handleEyeClick}
        />
        {!isPasswordValid && (
          <div className="password-error-text">
            <img
              src={IMGWarning}
              className={`${passwordErrorMsg === 'Enter your password' ?'short-pwd-error' :'long-pwd-error'  }`} 
            />
            {passwordErrorMsg}
          </div>
        )}
        {/* <input
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={validateConfirmPassword}
                    placeholder="Confirm Password"
                    className="signup-input-classes-new"
                /> */}
        <TextField
          type={showConfirmPassword ? "password" : "text"}
          name="confirmPassword"
          label="Confirm password"
          value={confirmPassword}
          onChange={(e) => handleInputChange(e)}
          onBlur={validateConfirmPassword}
          placeholder="Confirm Password"
          style={isPasswordValid ? { margin: "0px" } : { marginBottom: "12px" }}
          InputLabelProps={{ shrink: true }}
          // className="signup-input-classes-new"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: isConfirmPasswordValid ? 'inherit' : 'red',
                borderWidth: isConfirmPasswordValid ? '1px' : '2px', 
              },
              '&:hover fieldset': {
                borderColor: isConfirmPasswordValid ? 'inherit' : 'red',
                borderWidth: isConfirmPasswordValid ? '1px' : '2px', 
              },
              '&.Mui-focused fieldset': {
                borderColor: isConfirmPasswordValid ? '#4E9386' : 'red',
                borderWidth: '2px',
              },
            },
            '& .MuiInputLabel-root': {
              color: isConfirmPasswordValid ? 'inherit' : 'red',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: isConfirmPasswordValid ? '#4E9386' : 'red',
            },
          }}
        />
        <img
          src={showConfirmPassword ? IMGEye : IMGShowpwd}
          style={
            isPasswordValid
              ? confirmpwdeyeWithoutErrorStyle
              : confirmpwdeyeWithErrorStyle
          }
          onClick={handleConfirmPwdEyeClick}
        />
        {!isConfirmPasswordValid && (
          <div className="password-error-text-confirm-password">
            <img src={IMGWarning} style={{ marginRight: "4px" }} />
            Password do not match
          </div>
        )}

        <button
          className="button"
          disabled={
            isSignUpClicked ||
            !name ||
            !businessEmail ||
            !password ||
            !confirmPassword
          }
          onClick={handleSignup}
        >
          {isLoading ? "Loading..." : "Sign Up"}
        </button>
        {formError && (
          <div className="password-error-text" style={{ marginTop: "10px" }}>
            {" "}
            <img src={IMGWarning} style={{ marginRight: "4px" }} />
            {formError}{" "}
          </div>
        )}
        <div className="orTextContainer">
          <div className="line" style={{marginRight:'10px'}}></div>
          or
          <div className="line" style={{marginLeft:'10px'}}></div>
        </div>
        <div className="acc-create-text">
          Already have an account?{" "}
          <span onClick={() => navigate("/")}>Sign in</span>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default NewSignupForm;
