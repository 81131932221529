import React, { useEffect, useRef, useState, useCallback } from "react";
import "./uploadFirstFileComponent.scss";
import { IMGDocumentScannerIcon, IMGDriveFolderUploadIcon, IMGMonitoring } from "../images";
import useFile from "../hooks/useFile";
import axios from "axios";
import ProgressBarComponent from "./progressBarComponent";
import useDeleteFiles from "../hooks/useDeleteFiles";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setFileIds, setUploadFiles } from "../utils/fileSlice";
import { useNavigate } from "react-router-dom";
import {useDropzone} from 'react-dropzone'
import { setFirstChat } from "../utils/dataSourceSlice";
import { PRODUCT_TOUR_KEY, UploadFirstFileTourScreen } from "../config";
import DashboardTour from "../dashboard/dashboardTour";

const UploadingFirstFileComponent = ({currentStepIndexforUploadFiles, setCurrentStepIndexforUploadFiles,steps}) => {
  const [selectedFile, setSelectedFile] = useState([]);
  const [areFilesUploaded, setAreFilesUploaded] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileId, setFileId] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [fileErrMsg, setFileErrMsg] = useState("");
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState("");
  const selectFilesRef = useRef(null);
  const [isButtonEnabled,setIsButtonEnabled] = useState(false)
  const { getPresignedFileUploadUrl, saveFiles } = useFile();
  const { deleteFiles } = useDeleteFiles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataSourceName = useSelector((state) => state.dataSourceSlice.first_chat)

  useEffect(() => {
    if (selectedFile && selectedFile.length > 0) {
      uplaodFiles();
    }
  }, [selectedFile]);

  const handlerDataSource = () =>{
    navigate("/query");
    dispatch(setFirstChat(dataSourceName));
  }

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const allowedExtensions = ["pdf", "csv", "xlsx", "xls"];
      const fileExtension = file.name.split(".")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setFileErrMsg("Unsupported file format. Files supported with the following extensions: .pdf, .csv, .xlsx, .xls.");
        setOpen(true);
      } else {
        setOpen(false);
        }
      file.extension = fileExtension;
      const reader = new FileReader();
      const options = {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          setUploadProgress((progressEvent.loaded * 100) / progressEvent.total);
        },
      };
      reader.onloadend = async (event) => {
        const fileData = event.target.result;
        try {
          const resp = await getPresignedFileUploadUrl(fileExtension);
          if (resp.status === 200) {
            const { presigned_url, s3_url } = resp.data;
            const uploadResponse = await axios.put(
              presigned_url,
              fileData,
              options
            );
            if (uploadResponse.status === 200) {
              file.s3uri = s3_url;
              resolve();
            }
          }
        } catch {
          reject();
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const uplaodFiles = async () => {
    setIsFileUploading(true);
    for (let file of selectedFile) {
      await uploadFile(file);
    }
    setAreFilesUploaded(true);
    const requestBody = selectedFile.map(
      ({ name, size, extension, s3uri }) => ({ name, size, extension, s3uri })
    );
    try {
      const fileResponse = await saveFiles(requestBody);
      const file_Id = fileResponse?.data?.file_ids;
      dispatch(setFileIds(file_Id));
      setFileId(file_Id);
      setIsFileUploading(true);
      setIsButtonEnabled(true);
    } catch (error) {
      setErrMsg("Unexpected error occurred. Please try again after sometime.");
      setOpen(true);
    }
  };

  const removeSelectedFile = async (fileId) => {
    try {
      await deleteFiles(fileId);
      setSelectedFile([]);
      setUploadProgress(0);
      setIsButtonEnabled(selectedFile.length > 1);
      setDeleteSuccessMessage("File removed successfully.");
    } catch (error) {
      setErrMsg("Unexpected error occurred. Please try again after sometime.");
      setOpen(true);
    }
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const allowedExtensions = ["pdf", "csv", "xlsx", "xls"];
      const invalidFiles = Array.from(files).filter(file => {
        const fileExtension = file.name.split(".")[1];
        return !allowedExtensions.includes(fileExtension);
      });
      if (invalidFiles.length > 0) {
        setFileErrMsg("Unsupported file format. Files supported with the following extensions: .pdf, .csv, .xlsx, .xls.");
        setOpen(true);
        return;
      }
      setSelectedFile([...files]);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    handleFileChange(acceptedFiles);
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: ".pdf, .csv, .xlsx, .xls",
    noClick: true
  })

  return (
    <div className="image-item-container">
      <div className="receipt-overall-container">
        <div className="receipt-icon" alt="">
          <img src={IMGDocumentScannerIcon} alt="" />
        </div>
        <div className="receipt-content">
          Files analyzed in chats will appear here.
        </div>
        <div className="upload-file-box">
          <div className="title-container">Link local files</div>
          <div className="upload-browse-box" 
            {...getRootProps()}
            onClick={() => {
              selectFilesRef.current.click();
            }}
            style={{ cursor: "pointer", border: isDragActive ? "1px dashed #AFAFAF" : "1px dashed #AFAFAF" }}
            >
            <div className="upload-inner-browsebox" >
              {selectedFile.length > 0 ? (
                <ProgressBarComponent
                  progressPercent={uploadProgress}
                  selectedFile={selectedFile}
                  setIsFileUploading={setIsFileUploading}
                  removeSelectedFile={removeSelectedFile}
                  fileId={fileId}
                />
              ) : (
                <>
                  <div className="image-container">
                    <img
                      alt=""
                      src={IMGDriveFolderUploadIcon}
                      style={{ marginRight: "12px" }}
                    />
                  </div>
                  <div className="text-container">
                    <span className="drag-and-drop">Drag and Drop </span>
                    <span className="or" style={{ color: "#BCBCBC" }}>
                      {" "}
                      or{" "}
                    </span>
                    <span className="browse">browse</span>
                    <span className="supported-formats">
                      {" "}
                      supported .pdf, .csv, .xlsx, .xls
                      </span>
                  </div>
                </>
              )}
            </div>
            <input
            {...getInputProps()}
              ref={selectFilesRef}
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e.target.files)}
              accept={"text, .pdf, .csv, .xlsx, .xls"}
            />
          </div>
        </div>
        <button className={`start-button ${isButtonEnabled ? 'active' : 'inactive'}`} onClick={handlerDataSource} disabled={!isButtonEnabled}>Start Chat</button>
        <div className="monitoring-container" onClick={() => navigate("/dashboard")}>
          <img src={IMGMonitoring} alt="monitoring" />
          <span>Change Data Source</span>
        </div>
      </div>
      {currentStepIndexforUploadFiles === 0 && (
            <div style={{position:'absolute',top:'94px',right:'165px'}}>
            <DashboardTour
              currentStepInfo={UploadFirstFileTourScreen}
              currentStepIndex={currentStepIndexforUploadFiles}
              setCurrentStepIndex={setCurrentStepIndexforUploadFiles}
              steps={steps}
              flowName={PRODUCT_TOUR_KEY.show_upload_with_file_tour}
            />
            </div>
        )}
      <Snackbar
        open={errMsg !== ""}
        autoHideDuration={6000}
        onClose={()=> setErrMsg("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={()=> setErrMsg("")}
          severity="error"
          variant="filled"
          sx={{
            width: '100%',
            bgcolor: '#FFECEF',
            color: '#3B3939',
            '& .MuiAlert-icon': {
            color: '#CA6069',
          },
            fontFamily: 'Chivo',
            fontWeight: 400,
            fontSize: "16px"
          }}        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleteSuccessMessage !== ""}
        autoHideDuration={6000}
        onClose={() => setDeleteSuccessMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setDeleteSuccessMessage("")}
          severity="success"
          variant="filled"
          sx={{
            width: '100%',
            bgcolor: '#D4ECDA',
            color: '#3B3939',
            '& .MuiAlert-icon': {
            color: '#4A998A',
            },
            fontFamily: 'Chivo',
            fontWeight: 400,
            fontSize: "16px"
          }}>
          {deleteSuccessMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={fileErrMsg !== ""}
        autoHideDuration={6000}
        onClose={() => setFileErrMsg("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFileErrMsg("")}
          severity="error"
          variant="filled"
          sx={{
            width: '100%',
            bgcolor: '#FFECEF',
            color: '#3B3939',
            '& .MuiAlert-icon': {
            color: '#CA6069',
          },
            fontFamily: 'Chivo',
            fontWeight: 400,
            fontSize: "16px"
          }}    
          >
          {fileErrMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default UploadingFirstFileComponent;
