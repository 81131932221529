import React, { useEffect, useRef, useState, useCallback } from "react";
import "./landingPage.scss";
import {
  IMGGorillaBanner,
  IMGSearchIcon,
  ImgWooLogo,
  IMGAuthorizeNetLogo,
  IMGZohoInventory,
  IMGShippingBob,
  IMGInflow,
  IMGSalesForce,
  IMGIntuitQuickbooks,
  IMGDriveFolderUploadIcon,
  IMGGorillaPayDataSourceLogo,
  IMGFreedomPay,
  IMGThreeDotIcon,
  IMGGorillaConnectBanner,
} from "../images";
import ConnectWooCommerceUrl from "./connectWithDataSourcesV2/connectWooCommerceUrl";
import { API_URL, dataSourceTourScreen,introTourScreen,localFilesTourScreen, PRODUCT_TOUR_KEY, STORAGE_KEY } from "../config";
import GpModal from "../commonComponent/gpModal/GpModal";
import ExistingUser from "../commonComponent/gpModal/existingUser/ExistingUser";
import NewUserModal from "../commonComponent/gpModal/newUser/NewUserModal";
import { useLocation, useNavigate } from "react-router-dom";
import AuthorizeDotNet from "../dashboard/authorizeDotNet/AuthorizeDotNet";
import { query } from "../routes/routes";
import axios from "axios";
import useWooCommerce from "../hooks/useWooCommerce";
import { useDispatch, useSelector } from "react-redux";
import UploadedFile from "./uploadedFileV2";
import DateFilterWooCommerce from "./connectWithDataSourcesV2/dateFilterWooCommerce";
import useFile from "../hooks/useFile";
import ProgressBarComponent from "./progressBarComponent";
import useDeleteFiles from "../hooks/useDeleteFiles";
import {
  setUploadFiles,
  uploadFileSelector,
  setFileIds,
} from "../utils/fileSlice";
import axiosInstance from "../hooks/axiosConfig";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { Alert, Snackbar } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { setDataSourceName, setFirstChat } from "../utils/dataSourceSlice";
import useOutsideClick from "../hooks/useOutsideClick";
import DeletePopup from "./deletePopup";
import DashboardTour from "../dashboard/dashboardTour";

const dataSources = {
  WOOCOMMERCE: { imgSrc: ImgWooLogo, title: "Woocommerce" },
  AUTHORIZE_NET: { imgSrc: IMGAuthorizeNetLogo, title: "Authorize.Net" },
  ZOHO_INVENTORY: { imgSrc: IMGZohoInventory, title: "Zoho Inventory" },
  SHIP_BOB: { imgSrc: IMGShippingBob, title: "Ship Bob" },
  INFLOW_INVENTORY: { imgSrc: IMGInflow, title: "InFlow Inventory" },
  SALESFORCE: { imgSrc: IMGSalesForce, title: "Salesforce" },
  INTUIT_QUICKBOOKS: {
    imgSrc: IMGIntuitQuickbooks,
    title: "Intuit Quickbooks",
  },
  GORILLAPAY: { imgSrc: IMGGorillaPayDataSourceLogo, title: "Gorilla Pay" },
};

const LandingPage = ({
  files,
  handleMenuClick,
  selectedChat,
  setSelectedChat,
  onFileChat,
  handleOpen,
  currentStepIndex,
  setCurrentStepIndex,
  steps,
}) => {
  const [searchText, setSearchText] = useState("");
  const [storeDetails, setStoreDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState("");
  const [isWooCommerseInActive, setIsWooCommerseInActive] = useState(false);
  // const [isDateFilterPopup, setIsDateFilterPopup] = useState(false);
  // const [listData, setListData] = useState(files?.results || []);
  const [selectedFile, setSelectedFile] = useState([]);
  const [areFilesUploaded, setAreFilesUploaded] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [fileId, setFileId] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [show, setShow] = useState(false);
  const [fileErrMsg, setFileErrMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activeDataSource, setActiveDataSource] = useState({});
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const merchantOnboarded = localStorage.getItem(STORAGE_KEY.is_merchant_exist);
  // const [fileUpload, setFileUpload] = useState(false);
  const { getPresignedFileUploadUrl, saveFiles } = useFile();
  const [Oauthurl, setOauthurl] = useState("");
  const [isGorrilapayConnected, setIsGorrilapayConnected] = useState(false);
   const [dataSourceError , setDataSourceError] = useState('')
  // const [Oauthurl, setOauthurl] = useState("https://sandbox.authorize.net/oauth/authorize?sub=oauth&client_id=NGfPqqM784&state=123456&scope=read%2Cwrite&redirect_uri=https%3A%2F%2Fdev.gorillapay.ai%2F")
  const selectFilesRef = useRef(null);
  const threeDotPopup = useRef(null);
  const { deleteFiles } = useDeleteFiles();
  const tourRef = useRef(null);

  const dataSourceName = useSelector(
    (state) => state.dataSourceSlice.first_chat
  );

  const navigate = useNavigate();
  const { getStoreList } = useWooCommerce();
  const dispatch = useDispatch();
  const location = useLocation();
  useOutsideClick(threeDotPopup, isPopupOpen, setIsPopupOpen);

  useEffect(() => {
    fetchData();
    getStoreInfo();
    // fetchGorrilaStatus();
  }, []);

  useEffect(() => {
    const isGorillaPayPresent = storeDetails.some(
      (item) => item.data_source_identifier === "GORILLAPAY"
    );
    setIsGorrilapayConnected(isGorillaPayPresent);
  }, [storeDetails]);

  const fetchGorrilaStatus = async () => {
    try {
      const gorrilapayStatus = await axiosInstance.post(
        `${API_URL}/chat/set_gorillapay_data_integration_id/`
      );
      setIsGorrilapayConnected(
        gorrilapayStatus?.data?.data_source_integration_id
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      // getStoreInfo();
      const OAuthUrl = await axiosInstance.post(
        `${API_URL}/chat/generate_woocommerece_auth_url/`,
        { data_source: "AUTHORIZE_NET" }
      );
      if (OAuthUrl) {
        setOauthurl(OAuthUrl?.data?.oauth_url);
      }
      if (OAuthUrl?.data?.message) {
        setOauthurl(OAuthUrl?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlerDataSource = () => {
    navigate("/query");
    onFileChat();
    dispatch(setFirstChat(dataSourceName));
  };

  useEffect(() => {
    if (selectedFile && selectedFile.length > 0) {
      uplaodFiles();
    }
  }, [selectedFile]);

  const uplaodFiles = async () => {
    setIsFileUploading(true);
    for (let file of selectedFile) {
      await uploadFile(file);
    }
    setAreFilesUploaded(true);
    const requestBody = selectedFile.map(
      ({ name, size, extension, s3uri }) => ({ name, size, extension, s3uri })
    );
    const fileNames = requestBody.map((file) => file.name);
    dispatch(setUploadFiles(fileNames));
    try {
      const fileResponse = await saveFiles(requestBody);
      const file_Id = fileResponse?.data?.file_ids;
      dispatch(setFileIds(file_Id));
      setFileId(file_Id);
      setIsFileUploading(true);
      setIsButtonEnabled(true);
    } catch (error) {
      setOpen(true);
    }
  };

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const allowedExtensions = ["pdf", "csv", "xlsx", "xls"];
      const fileExtension = file.name.split(".")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setFileErrMsg(
          "Unsupported file format. Files supported with the following extensions: .pdf, .csv, .xlsx, .xls."
        );
        setOpen(true);
      } else {
        setOpen(false);
      }
      file.extension = fileExtension;
      const reader = new FileReader();
      const options = {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          setUploadProgress((progressEvent.loaded * 100) / progressEvent.total);
        },
      };
      reader.onloadend = async (event) => {
        const fileData = event.target.result;
        try {
          const resp = await getPresignedFileUploadUrl(fileExtension);
          if (resp.status === 200) {
            const { presigned_url, s3_url } = resp.data;
            const uploadResponse = await axios.put(
              presigned_url,
              fileData,
              options
            );
            if (uploadResponse.status === 200) {
              file.s3uri = s3_url;
              resolve();
            }
          }
        } catch {
          reject();
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const allowedExtensions = ["pdf", "csv", "xlsx", "xls"];
      const invalidFiles = Array.from(files).filter((file) => {
        const fileExtension = file.name.split(".")[1];
        return !allowedExtensions.includes(fileExtension);
      });
      if (invalidFiles.length > 0) {
        setFileErrMsg(
          "Unsupported file format. Files supported with the following extensions: .pdf, .csv, .xlsx, .xls."
        );
        setOpen(true);
        return;
      }
      // dispatch(addFile(files[0]?.name));
      dispatch(setDataSourceName("FILES"));
      dispatch(setUploadFiles([...files]));
      setSelectedFile([...files]);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    handleFileChange(acceptedFiles);
  }, []);

  const handleFileClick = () => {
    if (selectFilesRef.current) {
      selectFilesRef.current.click();
    }
  };  
  const getStoreInfo = async () => {
    try {
      const response = await getStoreList();
      if (response === "error") {
        setErrMsg(
          "Unexpected error occurred. Please try again after sometime."
        );
        setOpen(true);
      } else {
        setStoreDetails(response);
        setIsLoading(false);
        setOpen(false);
      }
    } catch (error) {}
  };

  const onChatClick = (item) => {
    dispatch(setFirstChat(item));
    if (item?.data_source_identifier) {
      switch (item?.data_source_identifier) {
        case "GORILLAPAY":
          return navigate(`/gorrilapay/${item?.integration_id}`);
          // setShow(true);
          // setModalContent(<NewUserModal handleClose={handleClose}/>);
          return;
        case "WOOCOMMERCE":
          return navigate(`/woocommerce-sync/${item?.integration_id}`);
        case "AUTHORIZE_NET":
          return navigate(`/authorizedotne/${item?.integration_id}`);
        default:
          return;
      }
    }
  };

  const removeSelectedFile = async (fileId) => {
    try {
      await deleteFiles(fileId);
      dispatch(setUploadFiles([]));
      setSelectedFile([]);
      setUploadProgress(0);
      setIsButtonEnabled(false);
      setDeleteSuccessMessage("File removed successfully.");
    } catch (error) {
      setOpen(true);
    }
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleWoocommercePopup = async () => {
    const isAlredyConnected = storeDetails?.find(
      (item) => item?.data_source_identifier === "WOOCOMMERCE"
    );
    if (isAlredyConnected) {
      setDataSourceError("Datasource is alreay connected");
    } else {
      setIsWooCommerseInActive(true);
    }
  };
  const handleopenModal = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(!show);
    setModalContent(null);
  };
  const handleCloseAuthorizeModal = () => {
    navigate("/dashboard");
  };
  // const handleAuthorizeModal = () => {

  //   navigate('/dataSources/authorizedotnet')

  // }
  const handleAuthorizeDotNetClick = () => {
    const isAlredyConnected = storeDetails?.find(
      (item) => item?.data_source_identifier === "AUTHORIZE_NET"
    );    
    if (isAlredyConnected) {
      setDataSourceError("Datasource is already connected");
    } else if (Oauthurl === "Store is already integrated") {
      setDataSourceError("Datasource is already connected");
    } else if (Oauthurl) {
      window.location.href = Oauthurl;
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf, .csv, .xlsx, .xls",
    noClick: true,
  });

  return (
    <>
      {show ? (
        <GpModal
          show={show}
          handleClose={handleClose}
          merchantOnboarded={merchantOnboarded}
        >
          {merchantOnboarded === "true" ? (
            <NewUserModal handleClose={handleClose} />
          ) : (
            <ExistingUser />
          )}
        </GpModal>
      ) : (
        ""
      )}
      {/* {

        location?.pathname === '/dataSources/authorizedotnet' ? <GpModal show={location?.pathname === '/dataSources/authorizedotnet'} handleClose={handleCloseAuthorizeModal} merchantOnboarded={location?.pathname === '/dataSources/authorizedotnet'}><AuthorizeDotNet show={location?.pathname === '/dataSources/authorizedotnet'}/></GpModal> : ''
      } */}
      {location?.pathname === "/dataSources/authorizedotnet" ? (
        <AuthorizeDotNet
          show={location?.pathname === "/dataSources/authorizedotnet"}
          handleCloseModal={handleCloseAuthorizeModal}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
        />
      ) : (
        ""
      )}

      <div className="source-selection">
        <div className="landing-page-container">
          <div className="header-text">Start chat by linking data</div>
          <div className="sources-container">
            <div className="gor-container">
              {!isGorrilapayConnected && (
                <div className="gor-text">Connect with GorillaPay</div>
              )}
              <img
                style={{ cursor: "pointer", width: "100%" }}
                src={
                  !isGorrilapayConnected
                    ? IMGGorillaBanner
                    : IMGGorillaConnectBanner
                }
                onClick={handleopenModal}
              />
            </div>
          </div>
          {isLoading ? (
            <div style={{ width: "95%" }}>
              <ShimmerSimpleGallery card imageHeight={80} row={1} col={4} />
            </div>
          ) : (
            <>
              {storeDetails &&
                Array.isArray(storeDetails) &&
                storeDetails.length > 0 && (
                  <>
                    <div className="or-breaker">OR</div>
                    <div className="data-source-container">
                      <div className="container-datasource">
                        <div className="container-header">
                          Recently linked data sources
                          {storeDetails?.length > 0  ? <div className="datasource-count">
                            {storeDetails?.length}
                          </div> : '' }
                        </div>
                        <div className="data-source-content">
                          <div className="data-source-connected-list">
                            {storeDetails.map((storeDetail, index) => (
                              <div
                                className="container"
                                key={index}
                                // onClick={() => onChatClick(storeDetail)}
                              >
                                <img
                                  src={
                                    dataSources[
                                      storeDetail.data_source_identifier
                                    ]?.imgSrc
                                  }
                                  alt={storeDetail.data_source_identifier}
                                  onClick={() => onChatClick(storeDetail)}
                                />
                                <img
                                  className="three-dot-classes"
                                  src={IMGThreeDotIcon}
                                  alt=""
                                  onClick={() => setIsPopupOpen(index + 1)}
                                />
                                {isPopupOpen === index + 1 && (
                                  <div
                                    className="three-dot-popup"
                                    ref={threeDotPopup}
                                  >
                                    <div
                                      className="delete-text-classes"
                                      onClick={() => {
                                        setActiveDataSource(storeDetail);
                                        setIsDeletePopupOpen(true);
                                      }}
                                    >
                                      Delete
                                    </div>
                                  </div>
                                )}
                                {isDeletePopupOpen && (
                                  <DeletePopup
                                    activeDataSource={activeDataSource}
                                    setIsDeletePopupOpen={setIsDeletePopupOpen}
                                    fetchData={fetchData}
                                    getStoreInfo={getStoreInfo}
                                  />
                                )}
                                <div
                                  className="hover-content"
                                  onClick={() => onChatClick(storeDetail)}
                                >
                                  {
                                    dataSources[
                                      storeDetail.data_source_identifier
                                    ]?.title
                                  }
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </>
          )}
          <div className="or-breaker">OR</div>
          <div className="data-source-container">
            <div className="container-datasource">
              <div className="container-header">Link a new data source</div>
              {/* <div className="search-container">
                <input
                  type="text"
                  placeholder="Search for data sources..."
                  class="search-input"
                  value={searchText}
                  onChange={handleInputChange}
                />
                <img src={IMGSearchIcon} />
              </div> */}
              <div className="data-source-content">
                <div className="data-source-list">
                  <div
                    className="container active-datasource"
                    onClick={handleWoocommercePopup}
                  >
                    <img src={ImgWooLogo} />
                    <div className="hover-content">Woocommerce</div>
                  </div>
                  <div
                    className="container active-datasource"
                    onClick={handleAuthorizeDotNetClick}
                  >
                    <img src={IMGAuthorizeNetLogo} />
                    <div className="hover-content">Authorize.Net</div>
                  </div>
                  <div className="container">
                    <img src={IMGIntuitQuickbooks} />
                    <div className="hover-content">Intuit Quickbooks</div>
                    <div className="coming-soon-container">
                      <div className="coming-soon">Coming soon</div>
                    </div>
                  </div>
                  <div className="container">
                    <img src={IMGShippingBob} />
                    <div className="hover-content">Ship Bob</div>
                    <div className="coming-soon-container">
                      <div className="coming-soon">Coming soon</div>
                    </div>
                  </div>
                </div>
                <div className="data-source-list">
                  <div className="container">
                    <img src={IMGInflow} />
                    <div className="hover-content">InFlow Inventory</div>
                    <div className="coming-soon-container">
                      <div className="coming-soon">Coming soon</div>
                    </div>
                  </div>
                  <div className="container">
                    <img src={IMGSalesForce} />
                    <div className="hover-content">Salesforce</div>
                    <div className="coming-soon-container">
                      <div className="coming-soon">Coming soon</div>
                    </div>
                  </div>
                  <div className="container">
                    <img src={IMGZohoInventory} />
                    <div className="hover-content">Zoho Inventory</div>
                    <div className="coming-soon-container">
                      <div className="coming-soon">Coming soon</div>
                    </div>
                  </div>
                  <div className="container">
                    <img src={IMGFreedomPay} />
                    <div className="hover-content">FreedomPay</div>
                    <div className="coming-soon-container">
                      <div className="coming-soon">Coming soon</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {currentStepIndex === 0 && (
            <div id="dataSourceContainer" style={{position:'absolute',top:'430px',right:'375px'}}>
            <DashboardTour
              currentStepInfo={dataSourceTourScreen}
              currentStepIndex={currentStepIndex}
              setCurrentStepIndex={setCurrentStepIndex}
              scrollToElement="#dataSourceContainer"
              steps={steps}
              flowName={PRODUCT_TOUR_KEY.show_product_tour}
            />
            </div>
          )}
          </div>
          <div className="or-breaker">OR</div>
          {currentStepIndex === 1 && (
            <div id="localFilesContainer" style={{position:'absolute',top:'205px',right:'400px'}}>
            <DashboardTour
              currentStepInfo={localFilesTourScreen}
              currentStepIndex={currentStepIndex}
              setCurrentStepIndex={setCurrentStepIndex}
              scrollToElement="#localFilesContainer"
              steps={steps}
              flowName={PRODUCT_TOUR_KEY.show_product_tour}
            />
            </div>
          )}
          <div className="file-upload-section">
            <div className="upload-container">
              <div className="upload-text">Link local files</div>
              <div
                className="upload-container"
                {...getRootProps()}
                onClick={handleFileClick}
                style={{
                  cursor: "pointer",
                  border: isDragActive
                    ? "1px dashed #AFAFAF"
                    : "1px dashed #AFAFAF",
                }}
              >
                {selectedFile.length > 0 ? (
                  <ProgressBarComponent
                    progressPercent={uploadProgress}
                    selectedFile={selectedFile}
                    setIsFileUploading={setIsFileUploading}
                    removeSelectedFile={removeSelectedFile}
                    fileId={fileId}
                  />
                ) : (
                  <div className="browse-container-content">
                    <img
                      src={IMGDriveFolderUploadIcon}
                      style={{ marginRight: "12px" }}
                    />
                    <div className="browse-text-content">
                      <div>
                        <span className="drag-text">Drag and Drop&nbsp;</span>
                        <span className="or-text">or&nbsp;</span>
                        <span className="browse-text">browse</span>
                      </div>
                      <div>
                        <span className="supported-text">
                          supported .pdf, .csv, .xlsx, .xls
                        </span>
                      </div>
                    </div>
                    <input
                      {...getInputProps()}
                      ref={selectFilesRef}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e.target.files)}
                      accept={"text, .pdf, .csv, .xlsx, .xls"}
                    />
                  </div>
                )}
                {isButtonEnabled && (
                  <button
                    style={{ display: "flex" }}
                    onClick={() => {
                      handlerDataSource();
                    }}
                  >
                    Start Chat
                  </button>
                )}
              </div>
            </div>
          </div>
          {isWooCommerseInActive && (
            <ConnectWooCommerceUrl
              setIsWooCommerseInActive={setIsWooCommerseInActive}
              isWooCommerseInActive={isWooCommerseInActive}
            />
          )}
        </div>
      </div>
      {currentStepIndex === -1 && (
        <div id="localFilesContainer" style={{position:'absolute',top:'-40px',right:'588px'}}>
        <DashboardTour
          currentStepInfo={introTourScreen}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          steps={3}
          flowName={PRODUCT_TOUR_KEY.show_product_tour}
        />
        </div>
      )}
      <Snackbar
        open={dataSourceError !== ""}
        autoHideDuration={6000}
        onClose={() => setDataSourceError("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setDataSourceError("")}
          severity='success'
          variant="filled"
          sx={{
            width: "100%",
            bgcolor: "#D4ECDA",
            color: "#3B3939",
            "& .MuiAlert-icon": {
              color: "#4A998A",
            },
            fontFamily: "Chivo",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {dataSourceError}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errMsg !== ""}
        autoHideDuration={6000}
        onClose={() => setErrMsg("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setErrMsg("")}
          severity="error"
          variant="filled"
          sx={{
            width: "100%",
            bgcolor: "#FFECEF",
            color: "#3B3939",
            "& .MuiAlert-icon": {
              color: "#CA6069",
            },
            fontFamily: "Chivo",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleteSuccessMessage !== ""}
        autoHideDuration={6000}
        onClose={() => setDeleteSuccessMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setDeleteSuccessMessage("")}
          severity="success"
          variant="filled"
          sx={{
            width: "100%",
            bgcolor: "#D4ECDA",
            color: "#3B3939",
            "& .MuiAlert-icon": {
              color: "#4A998A",
            },
            fontFamily: "Chivo",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {deleteSuccessMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={fileErrMsg !== ""}
        autoHideDuration={6000}
        onClose={() => setFileErrMsg("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFileErrMsg("")}
          severity="error"
          variant="filled"
          sx={{
            width: "100%",
            bgcolor: "#FFECEF",
            color: "#3B3939",
            "& .MuiAlert-icon": {
              color: "#CA6069",
            },
            fontFamily: "Chivo",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {fileErrMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LandingPage;
