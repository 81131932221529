import { useState } from "react";
import axios from "axios";
import { API_URL, SSO_API_URL, STORAGE_KEY, USER_CREDENTIALS } from "../config";
import { useNavigate } from "react-router";
// import { signIn } from "../routes/routes";
import axiosIntance from "./axiosConfig";

const useLogout = () => {
  const [logoutStatus, setLogoutStatus] = useState(null);
  const navigate = useNavigate();

  const logout = async (token) => {
    try {
      const response = await axiosIntance.post(
        // `${API_URL}/auth/user/logout/`
        `${SSO_API_URL}/api/v1/logout/`
      );
      // localStorage.clear();

      //clearing local storage unnecesary things

      localStorage.setItem(
        STORAGE_KEY.access_token,
        JSON.stringify('')
      );
      localStorage.setItem(
        STORAGE_KEY.refresh_token,
        JSON.stringify('') 
      );
      localStorage.setItem(
        STORAGE_KEY.is_merchant_exist,
        JSON.stringify('') 
      );
      localStorage.setItem(
        STORAGE_KEY.user_role,
        JSON.stringify('') 
      );
      localStorage.setItem(USER_CREDENTIALS.USER_EMAIL, '');

      if (response.status === 401) {
        throw new Error("Failed to logout");
      }
      navigate("signIn");
      setLogoutStatus("success");
    } catch (error) {
      console.error("Error logging out:", error);

      setLogoutStatus("failure");
    }
  };

  return { logout, logoutStatus };
};

export default useLogout;
