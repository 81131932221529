import React, { useEffect, useState } from "react";
import AuthWrapper from "./authWrapper/AuthWrapper";
import "./NewVerificationComponent.scss";
import useSignUp from "../../hooks/useSignUp";
import { useSearchParams } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
const NewVerificationComponent = () => {
  const { resentAccountActivationEmail } = useSignUp();
  const [searchParams] = useSearchParams();
  const [canResend, setCanResend] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity , setSeverity] = useState('');

  const handleResendEmailVerification = () => {
    if (canResend) {
      resentAccountActivationEmail(searchParams.get("email"))
        .then(() => {
          setCanResend(false);
          setTimeLeft(30);
          setSnackbarMessage(
            "Verification email sent. You can resend after 30 seconds."
          );
          setSeverity('success')
          setSnackbarOpen(true);
          
        })
        .catch((error) => {
          setSeverity('error')
          setSnackbarMessage(
            "Failed to send verification email. Please try again."
          );
          setSnackbarOpen(true);
        });
    } else {
      setSeverity('error')
      setSnackbarMessage(`Please wait ${timeLeft} seconds before resending.`);
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0 && !canResend) {
      setCanResend(true);
      setSnackbarMessage("You can now resend the verification email.");
      setSnackbarOpen(true);
    }
  }, [timeLeft, canResend]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <AuthWrapper>
      <div className="verification-screen-container-new">
        <div className="verification-verification-text">
          Confirm your e-mail
        </div>
        <p className="enter-verification-text">
          We have sent you a link for verification over the mail. In case you do
          not find it, check your spam folder.
        </p>
        <p className="resend-text">
          Can’t Find It? &nbsp;
          <span
            className="hyperlink-reset-text"
            onClick={handleResendEmailVerification}
          >
            Click here to Resend the Email
          </span>
        </p>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </AuthWrapper>
  );
};

export default NewVerificationComponent;
