import React, { useEffect } from 'react'
import AuthWrapper from './authWrapper/AuthWrapper'
import './NewAccountVerificationComponent.scss'
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { IMGonboarding } from '../../images';
import { DOCUMENT_ROOT } from '../../config';
import { resendVerification } from '../../routes/routes';

const NewAccountVerificationComponent = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { activateUserAccount, isLoading, success, error } = useAuth();

    useEffect(() => {
        activateUserAccount(token);
    }, [token]);
    return (
        <AuthWrapper>
            <div >
                {!isLoading
                    && <div >
                        {success
                            ? <><div className="verification-screen-container-new">

                                <div className="verification-verification-text">
                                    Verification Successful
                                </div>
                                <p className="enter-verification-text">
                                    You can use your credentials to login to your account. Click below to log in.
                                </p>
                                <button className="button" onClick={() => navigate('/')}>
                                    Login
                                </button>
                            </div>
                            </>
                            : <> <div className="verification-screen-container-new">

                                <div className="verification-verification-text">
                                    Verification Failed
                                </div>
                                <p className="enter-verification-text">
                                    Account verification failed. Please click the button below to resend the link                                </p>
                                <button onClick={() => navigate(resendVerification)} className="button">
                                    Resend Link
                                </button>
                            </div></>}
                    </div>}
            </div>
        </AuthWrapper>
    )
}

export default NewAccountVerificationComponent