import React, { useEffect, useState } from "react";
import "./dateTimeSelectComponent.scss";
import {
  IMGTick,
  ImgConnectingToWooCoomerce,
  ImgWooLogo,
  IMGConnect,
  IMGbackArrow,
  IMGErrorIcon,
} from "../../images";
import BlueProgressBar from "../../commonComponent/blueProgrssBar";
import { GpDropdownComponent } from "../../components/gPayDropdownComponent";
import useWooCommerce from "../../hooks/useWooCommerce";
import { useNavigate, useParams } from "react-router-dom";
import { query } from "../../routes/routes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { update } from "../../utils/itemSlice";
import { useDispatch } from "react-redux";

const DateTimeSelectComponent = ({ syncDays = null, setSyncDays }) => {
  // const currentDate = new Date();
  // const year = currentDate.getFullYear();
  // const month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Add leading zero and slice last two characters
  // const day = ("0" + currentDate.getDate()).slice(-2); // Add leading zero and slice last two characters
  // const formattedDate = `${year}-${month}-${day}`;

  const [isConnecting, setIsConnecting] = useState("");
  const [isConnected, setIsConnected] = useState("");
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [dataIntegrationId, setDataIntegrationId] = useState("");
  const [daysCount, setDaysCount] = useState("");
  const [isDataSync, setIsDataSync] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [dataOptionError, setDataOptionError] = useState(false);
  const [timeOptionError, setTimeOptionError] = useState(false);
  const [isInitialRender, setInitialRender] = useState(true);
  const [checkboxValues, setCheckboxValues] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
  });
  const { dateDataHandler } = useWooCommerce();
  const { storeurl } = useParams();
  const navigate = useNavigate();
  const { getDataIntigrationId } = useWooCommerce();
  const dispatch = useDispatch();

  useEffect(() => {
    getDataIntegrationIdHelper();
    setInitialRender(false);
  }, []);

  useEffect(() => {
    if (!startDate && !isInitialRender) {
      console.log(!startDate);
      setStartDateError(true);
    } else {
      setTimeOptionError(false);
      setStartDateError(false);
    }
  }, [startDate]);
  useEffect(() => {
    if (!endDate && !isInitialRender) {
      setEndDateError(true);
    } else {
      setTimeOptionError(false);
      setEndDateError(false);
    }
  }, [endDate]);

  useEffect(() => {
    if (!selectedRadio && !isInitialRender) {
      setTimeOptionError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
      setTimeOptionError(false);
    }
  }, [selectedRadio]);

  const getDataIntegrationIdHelper = async () => {
    const dataId = await getDataIntigrationId(storeurl);
    console.log(dataId);
    setDataIntegrationId(dataId);
    console.log(storeurl);
    if (storeurl.length < 5) {
      setIsDataSync(true);
      setDataIntegrationId(storeurl);
    }
  };

  const handleSelect = (option) => {
    const currentDate = new Date();
    let subtractedDate = null;

    switch (option) {
      case "Last 7 Days":
        subtractedDate = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        setSyncDays(7);
        break;
      case "Last 15 Days":
        subtractedDate = new Date(
          currentDate.setDate(currentDate.getDate() - 15)
        );
        setSyncDays(15);
        break;
      case "Last 30 Days":
        subtractedDate = new Date(
          currentDate.setDate(currentDate.getDate() - 30)
        );
        setSyncDays(30);
        break;
      case "Last 3 months":
        subtractedDate = new Date(
          currentDate.setMonth(currentDate.getMonth() - 3)
        );
        setSyncDays(90);
        break;
      case "Last 6 months":
        subtractedDate = new Date(
          currentDate.setMonth(currentDate.getMonth() - 6)
        );
        setSyncDays(180);
        break;
      case "Last 1 Year":
        subtractedDate = new Date(
          currentDate.setFullYear(currentDate.getFullYear() - 1)
        );
        setSyncDays(365);
        break;
      default:
        break;
    }
    const date = subtractedDate.toISOString().slice(0, 10); // Convert date to string format
    // setEndDate(date);
    return date;
  };

  const onFieldSelect = (item, listIdentifier) => {
    if (listIdentifier === "timeOptions") {
      setSelectedRadio(handleSelect(item.value));
    }
    const daysPart = item.value.substring(item.value.indexOf("Last ") + 5);
    setDaysCount(daysPart);
    setStartDate("");
    setEndDate("");
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const handleCancelClick = () => {};

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "checkbox4") {
      setCheckboxValues({
        checkbox1: checked,
        checkbox2: checked,
        checkbox3: checked,
        checkbox4: checked,
      });
    } else {
      setCheckboxValues((prevValues) => {
        const updatedValues = {
          ...prevValues,
          [name]: checked,
        };

        // If any of the first 3 checkboxes are unchecked, also uncheck "I want to analyze all data"
        if (!checked) {
          updatedValues.checkbox4 = false;
        }

        // If all first 3 checkboxes are checked, also check "I want to analyze all data"
        if (updatedValues.checkbox1 && updatedValues.checkbox2 && updatedValues.checkbox3) {
          updatedValues.checkbox4 = true;
        }
        return updatedValues;
      });
    }
  };

  const handleConnectClick = async () => {
    const isAtleastOneOptionSelected = Object.values(checkboxValues).some(
      (value) => value
    );
    const isStartDateEmpty = startDate === "";
    const isEndDateEmpty = endDate === "";
    if (
      !selectedRadio &&
      isStartDateEmpty &&
      isEndDateEmpty &&
      isAtleastOneOptionSelected === false
    ) {
      setTimeOptionError(true);
      setStartDateError(true);
      setEndDateError(true);
      setDataOptionError(true);
      return;
    } else if (selectedRadio) {
      if (startDate && !endDate && !isAtleastOneOptionSelected) {
        setEndDateError(true);
        setDataOptionError(true);
        return;
      }
      if (!startDate && endDate && !isAtleastOneOptionSelected) {
        setStartDateError(true);
        setDataOptionError(true);
        return;
      }
      if (!isAtleastOneOptionSelected) {
        setDataOptionError(true);
        return;
      }
    } else if (!selectedRadio && !startDate && !endDate) {
      setTimeOptionError(true);
      setStartDateError(true);
      setEndDateError(true);
      return;
    } else if (isAtleastOneOptionSelected) {
      if (isStartDateEmpty && endDate) {
        setStartDateError(true);
        return;
      } else if (isEndDateEmpty && startDate) {
        setEndDateError(true);
        return;
      }
    } else if (!isAtleastOneOptionSelected) {
      if (!startDate) {
        setStartDateError(true);
        setDataOptionError(true);
        return;
      } else if (!endDate) {
        setEndDateError(true);
        setDataOptionError(true);
        return;
      } else {
        setDataOptionError(true);
        return;
      }
    } else {
      return;
    }
    setIsConnecting(true);
    let response;
    if (!isDataSync) {
      response = await dateDataHandler({
        checkboxValues,
        selectedRadio,
        dataIntegrationId,
        startDate,
        endDate,
        syncDays,
      });
    }
    const date1 = new Date(endDate);
    const date2 = new Date(startDate);
    // Calculate the difference in milliseconds
    const diffInMilliseconds = date2 - date1;

    // Convert the difference from milliseconds to days
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const stringDate = Math.abs(diffInDays).toString();
    // if (syncDays === "") {
    //   setSyncDays(stringDate + " Days");
    // }

    if (isDataSync) {
      setDataIntegrationId(storeurl);
      handleAnalyzing();
    }
    const userFilterInfo = {
      start_date: startDate,
      end_date: endDate,
      duration: syncDays,
      data_intigration_id: dataIntegrationId,
    };

    dispatch(update(userFilterInfo));
    setTimeout(() => {
      setIsConnected(true);
    }, 10000);
  };

  const handleConnectedClick = () => {
    setIsConnected(true);
  };

  const timeOptions = [
    "Last 7 Days",
    "Last 15 Days",
    "Last 30 Days",
    "Last 3 months",
    "Last 6 months",
    "Last 1 Year",
  ];

  const handleAnalyzing = () => {
    navigate(`/queries/${dataIntegrationId}`);
    setShowChat(true);
  };

  const handleDateChange = (value) => {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based months
    const day = String(date.getDate()).padStart(2, "0");
    setSyncDays(null);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const handleDataOptionsBlur = () => {
    const isAtLeastOneOptionSelected = Object.values(checkboxValues).some(
      (value) => value
    );
    if (isAtLeastOneOptionSelected) {
      setDataOptionError(false);
    }
  };

  return (
    <>
      {!isConnecting && (
        <div className="date-time-container">
          <img
            style={{ marginLeft: "16px", cursor: "pointer" }}
            src={IMGbackArrow}
            alt="back-arrow"
            onClick={() => {
              window.history.back();
            }}
          />
          <div className="overall-container">
            <img
              className="img"
              src={!isDataSync ? IMGConnect : ImgWooLogo}
              style={{ marginTop: "-16px" }}
            />
            {!isDataSync ? (
              <div className="heading-styles">Connect With Woocommerce</div>
            ) : (
              <div className="heading-styles">Woocommerce</div>
            )}
            <div className="option-lable">
              <div className="sub-heading-styles">Select Time </div>

              <GpDropdownComponent
                listItems={timeOptions}
                placeholder={"Select Number of Days"}
                onSelectHandler={(item) => onFieldSelect(item, "timeOptions")}
                defaultValue={""}
              />
              {timeOptionError && (
                <div
                  style={{ marginTop: "-25px" }}
                  className="error-text-field"
                >
                  <img src={IMGErrorIcon} alt="" /> Please select time
                </div>
              )}
              <div
                style={{
                  textAlign: "center",
                  marginTop: "-15px",
                  marginBottom: "15px",
                }}
              >
                OR
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <DatePicker
                      inputFormat="yyyy-MM-dd"
                      label="Start Date"
                      value={dayjs(startDate)}
                      onChange={(newValue) =>
                        setStartDate(handleDateChange(newValue))
                      }
                      disableFuture={true}
                      maxDate={dayjs(endDate)}
                      sx={{ height: "50%", borderRadius: "10px" }}
                    />
                    {startDateError && (
                      <div
                        style={{ marginTop: "15px" }}
                        className="error-text-field"
                      >
                        <img src={IMGErrorIcon} alt="" /> Enter start date
                      </div>
                    )}
                  </div>
                  <div>
                    <DatePicker
                      inputFormat="yyyy-MM-dd"
                      label="End Date"
                      value={dayjs(endDate)}
                      onChange={(newValue) =>
                        setEndDate(handleDateChange(newValue))
                      }
                      disableFuture={true}
                      minDate={dayjs(startDate)}
                      sx={{ height: "50%", borderRadius: "10px" }}
                    />
                    {endDateError && (
                      <div
                        style={{ marginTop: "15px" }}
                        className="error-text-field"
                      >
                        <img src={IMGErrorIcon} alt="" /> Enter end date
                      </div>
                    )}
                  </div>
                </div>
              </LocalizationProvider>

              {/* <div className="lable-classes">
                <input
                  type="radio"
                  value="option1"
                  checked={selectedRadio === "option1"}
                  onChange={handleRadioChange}
                />
                <span className="lable-classes">Last 7 Days</span>
              </div> */}
              {/* <div className="lable-classes">
                <input
                  type="radio"
                  value="option2"
                  checked={selectedRadio === "option2"}
                  onChange={handleRadioChange}
                />
                <span className="lable-classes">Last 15 Days</span>
              </div> */}
              {/* <div className="lable-classes">
                <input
                  type="radio"
                  value="option3"
                  checked={selectedRadio === "option3"}
                  onChange={handleRadioChange}
                />
                <span className="lable-classes">Last 30 Days</span>
              </div> */}

              <div className="sub-heading-styles">Select Data Option</div>
              <div
                className="data-lable-container"
                onBlur={handleDataOptionsBlur}
              >
                <span className="lable-classes">
                  <input
                    type="checkbox"
                    name="checkbox1"
                    checked={checkboxValues.checkbox1}
                    onChange={handleCheckboxChange}
                  />
                  <span className="lable-classes">
                    I want to analyze orders data
                  </span>
                </span>
                <span className="lable-classes">
                  <input
                    type="checkbox"
                    name="checkbox2"
                    checked={checkboxValues.checkbox2}
                    onChange={handleCheckboxChange}
                  />
                  <span className="lable-classes">
                    I want to analyze products review
                  </span>
                </span>
                <span className="lable-classes">
                  <input
                    type="checkbox"
                    name="checkbox3"
                    checked={checkboxValues.checkbox3}
                    onChange={handleCheckboxChange}
                  />
                  <span className="lable-classes">
                    I want to analyze sales report
                  </span>
                </span>
                <span className="lable-classes">
                  <input
                    type="checkbox"
                    name="checkbox4"
                    checked={checkboxValues.checkbox4}
                    onChange={handleCheckboxChange}
                  />
                  <span className="lable-classes">
                    I want to analyze all data
                  </span>
                </span>
                {dataOptionError && (
                  <div className="error-text-field">
                    <img src={IMGErrorIcon} alt="" /> Please select at least one
                    data option
                  </div>
                )}
              </div>
              <button
                style={isDataSync ? { width: "200px" } : {}}
                className="connect-button"
                onClick={handleConnectClick}
              >
                {" "}
                {!isDataSync ? "Next" : "Start Analyzing"}
              </button>
            </div>
          </div>
        </div>
      )}
      {isConnecting && !isConnected && !isDataSync && (
        <div className="connecting-style-container">
          <div className="overall-container">
            <span className="heading-styles">
              <img
                style={{ marginRight: "51px" }}
                src={IMGbackArrow}
                alt="back-arrow"
              />
              Connect With Woocommerce
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "36px",
                marginBottom: "48px",
              }}
            >
              <img src={ImgConnectingToWooCoomerce} alt="connecting" />
            </div>
            <div className="circular-progressbar-container">
              {daysCount ? (
                <div className="sub-heading-styles">
                  Past {daysCount} of Data
                </div>
              ) : (
                <div className="sub-heading-styles">Connecting</div>
              )}
            </div>
            {/* <BlueProgressBar progressPercent={40} /> */}
            <div className="circular-progressbar-container">
              <CircularProgress />
            </div>
          </div>
          <div className="circular-progressbar-container">
            <button className="cancel-button" onClick={handleCancelClick}>
              {" "}
              Cancel
            </button>
          </div>
        </div>
      )}
      {isConnected && !isDataSync && (
        <div className="status-container">
          <div className="heading-styles">
            You are now connected with Woocommerce
            {daysCount && <span> with Past {daysCount} of Data </span>}.
          </div>
          <div className="image-container">
            <img src={IMGTick} alt="success" />
          </div>
          <div className="status-box">
            <div className="image-container">
              <img
                style={{ width: "111px", height: "66px", marginTop: "46px" }}
                src={ImgWooLogo}
                alt="success"
              />
            </div>
            <div className="image-container">
              <button onClick={handleAnalyzing} className="button">
                Start Analyzing
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DateTimeSelectComponent;
