import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DOCUMENT_ROOT, PUBLIC_PATHS, STORAGE_KEY } from '../config';
import { dashboard, dashboardWithoutStoreUrl, query, signIn } from './routes';
import { getToken } from '../utils/sessionUtils';

export const SecureRoute = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const pathsWithoutRefreshToken = PUBLIC_PATHS;

    useEffect(() => {
        const isAuthed = getToken(STORAGE_KEY.access_token);
        const publicPath = pathsWithoutRefreshToken.findIndex(
            (item) =>
              item === location.pathname ||
              location.pathname.startsWith("/verify-email/") ||
              location.pathname.startsWith("/reset/")||
              location.pathname.startsWith("/resend-verification")
             
          );
        if (isAuthed) {
            if (publicPath !== -1) {
                navigate(dashboardWithoutStoreUrl);
            }
        } else {
            if (publicPath === -1) {
                navigate(DOCUMENT_ROOT);
            }
        }
    }, [location, pathsWithoutRefreshToken]);
}
