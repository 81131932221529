import React, { useEffect, useState } from "react";
import "./dateFilterModal.scss";
import { ImgCrossIconSm } from "../../images";
import WooCommerce from "./wooCommerce";
import AuthorizeNetFilter from "./authorizeNetFilter";
import GorillaPayFilter from "./gorillaPayFilter";
import { useSelector } from "react-redux";

const DateFilterModal = ({ handleClose }) => {
  const dataSourceDetails = useSelector(
    (state) => state.dataSourceSlice.all_chat
  );
  const dataSourceName = useSelector(
    (state) => state.dataSourceSlice.first_chat
  );

  const {
    no_of_days = "",
    datasource_identifier = "",
    start_date = "",
    end_date = "",
  } = dataSourceName || {};

  const getDataSourceContent = () => {
    switch (
      dataSourceDetails?.datasource_identifier ||
      dataSourceName?.data_source_identifier ||
      datasource_identifier
    ) {
      case "WOOCOMMERCE":
        return <WooCommerce handleClose={handleClose} />;
      case "AUTHORIZE_NET":
        return <AuthorizeNetFilter handleClose={handleClose} />;
      case "GORILLAPAY":
        return <GorillaPayFilter handleClose={handleClose} />;
      default:
        return <></>;
    }
  };
  return (
    <div className="date-filter-container">
      <div className="sync-woo-commerce-Container">
        <img
          className="cross-icon-classes"
          src={ImgCrossIconSm}
          alt=""
          onClick={handleClose}
        />
        {/* <WooCommerce handleClose={handleClose}/> */}
        {/* <AuthorizeNetFilter handleClose={handleClose} /> */}
        {/* <GorillaPayFilter handleClose={handleClose} /> */}
        {getDataSourceContent()}
      </div>
    </div>
  );
};

export default DateFilterModal;
