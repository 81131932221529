import { ECAHRTS_BAR, ECAHRTS_PIE, ECAHRTS_SCATTER } from "../config/echartsConfig";

export function getChartOptions({ ArgumentsForPlot, df, ApacheOptionObject }) {
    try {
        return ApacheOptionObject;
    } catch {
        try {
            let str = ArgumentsForPlot;
            let config = {}
            if (/^\.(\w+)\([\w\W]+/.test(str)) {
                const matches = str.matchAll(/^\.(\w+)\([\w\W]+/g)
                let plotType = '';
                for (let match of matches) {
                    plotType = match[1]
                }
                switch (plotType) {
                    case 'pie':
                    case '.pie(':
                        const option = ECAHRTS_PIE;
                        option.title.text = config.title;
                        option.series[0].name = Object.keys(df[0])[0]
                        option.series[0].data = df.map(item => {
                            const objKeys = Object.keys(item);
                            return { name: item[objKeys[0]], value: item[objKeys[1]] };
                        })
                        if (config['autopct']) {
                            option.series[0].radius = ['40%', '70%']
                        } else {
                            option.series[0].radius = '70%'
                        }

                        return option;

                    case 'bar':
                    case '.bar(':
                        const bar_option = ECAHRTS_BAR;
                        bar_option.series[0]['data'] = df.map(item => {
                            const objKeys = Object.keys(item);
                            return item[objKeys[1]];
                        });
                        bar_option.xAxis['data'] = df.map(item => {
                            const objKeys = Object.keys(item);
                            return item[objKeys[0]];
                        })
                        // option.xAxis['type'] = Object.keys(df[0])[0]

                        // console.log(Object.keys(df[0])[0])
                        return bar_option;

                    case 'scatter':
                    case '.scatter(':
                        const scatter_option = ECAHRTS_SCATTER;
                        const data = [df.map(item => {
                            return [item[config.x], item[config.y]]
                        })];
                        scatter_option.series[0]['data'] = data;
                        return scatter_option;

                    default:
                        return {};
                }
            }
            if (str) {
                str = str.replace("(", "").replace(")", "").split(",")

                for (let i of str) {
                    const temp = i.trim().split("=")
                    try { config[temp[0]] = temp[1].replaceAll("'", "") } catch { }

                }

                switch (config.kind) {
                    case 'pie':
                    case '.pie()':
                        const option = ECAHRTS_PIE;
                        option.title.text = config.title;
                        option.series[0].name = Object.keys(df[0])[0]
                        option.series[0].data = df.map(item => {
                            const objKeys = Object.keys(item);
                            return { name: item[objKeys[0]], value: item[objKeys[1]] };
                        })
                        if (config['autopct']) {
                            option.series[0].radius = ['40%', '70%']
                        } else {
                            option.series[0].radius = '70%'
                        }

                        return option;

                    case 'bar':
                    case '.bar()':
                        const bar_option = ECAHRTS_BAR;
                        bar_option.series[0]['data'] = df.map(item => {
                            const objKeys = Object.keys(item);
                            return item[objKeys[1]];
                        });
                        bar_option.xAxis['data'] = df.map(item => {
                            const objKeys = Object.keys(item);
                            return item[objKeys[0]];
                        })
                        // option.xAxis['type'] = Object.keys(df[0])[0]

                        // console.log(Object.keys(df[0])[0])
                        return bar_option;

                    case 'scatter':
                    case '.scatter()':
                        const scatter_option = ECAHRTS_SCATTER;
                        const data = [df.map(item => {
                            return [item[config.x], item[config.y]]
                        })];
                        scatter_option.series[0]['data'] = data;
                        return scatter_option;

                    default:
                        return {};
                }


            }
        } catch {
            return {};
        }
    }
}