import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    uploadFiles: [],
    fileIds: [],
    fileList:[],
};

const fileUploadSlice = createSlice({
    name: "uploadFile",
    initialState,
    reducers: {
        setUploadFiles : (state, action) => {
            state.uploadFiles = action.payload.map(fileName => ({ name: fileName }));
        },
        setFileIds: (state, action) => {
            state.fileIds = action.payload;
        },
        setFileList: (state, action) => {
            state.fileList = action.payload;
        },
    }
});

export const { setUploadFiles , setFileIds,setFileList } = fileUploadSlice.actions;
export const uploadFileSelector = (state) => state.uploadFiles;
export default fileUploadSlice.reducer; 