import React, { useEffect, useState } from "react";
import "./authorizeNetFilter.scss";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../utils/itemSlice";
import { setAllChat, setDataSourceName, setFirstChat } from "../../utils/dataSourceSlice";
import axiosInstance from "../../hooks/axiosConfig";
import { API_URL, STORAGE_KEY } from "../../config";
import { IMGAuthorizeNetLogo } from "../../images";
import { TextField } from "@mui/material";
import { sessionStorageGetItem } from "../../utils/sessionStorageUtils";
import useChatAction from "../../hooks/useChatAction";

const AuthorizeNetFilter = ({ handleClose }) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState({
    startDateError: "",
    endDateError: "",
  });
  const [intIdError, setIntIdError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(null);
  const [chatId, setChatId] = useState("");
  const [dataSourceIntegrationId, setDataSourceIntegrationId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Total_chat = useSelector((state) => state.dataSourceSlice.total_chat);
  const Authorisedotbnet_integration_id = useSelector(
    (state) => state.dataSourceSlice.authorise_net_integration_id
  );
  const filters = ["1W", "1M", "3M", "6M", "1Y", "Custom-Range"];
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");

  const location = useLocation();
  const routeParams = useParams();
  const { updateChatDate } = useChatAction();
  const dataSourceDetails = useSelector(
    (state) => state.dataSourceSlice.all_chat
  );

  const getChatTitle = async () => {
    let chatId = sessionStorageGetItem(STORAGE_KEY.chat_id);
    if (chatId) {
      setChatId(chatId);
    } else if (routeParams["chatId"]) {
      chatId = routeParams["chatId"];
      setChatId(chatId);
    } else {
      setChatId("");
    }
  };

  useEffect(() => {
    getChatTitle();
  }, [location]);

  const handleFilterClick = (filter) => {
    if (selectedFilter === filter) {
      setSelectedFilter("");
      setStartDate("");
      setEndDate("");
      setError({
        startDateError: "",
        endDateError: "",
      });
    } else {
      if (filter !== "Custom-Range") {
        setStartDate("");
        setEndDate("");
        setError({
          startDateError: "",
          endDateError: "",
        });
      }else {
        setDays("")
      }
      setSelectedFilter(filter);
    }
  };

  const validateDate = (input) => {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    return dateRegex.test(input);
  };

  const handleDateChange = (e, setDate, errorName, compareDate) => {
    let inputDate = e.target.value;
    const inputDateLength = e.target.defaultValue.length; 
    const isDeletion = inputDate.length < inputDateLength;
    if ((inputDate.length === 2 || inputDate.length === 5) && !isDeletion) {
        inputDate += '-';
    }
    setDate(inputDate);
    const [month, day, year] = inputDate.split('-');
    const currentDate = dayjs();
    const parsedDate = dayjs(inputDate, "MM-DD-YYYY");
    const currentYear = currentDate.year();
    const previousYear = currentYear - 1;
    const isValidMonth = month >= 1 && month <= 12;
    const isValidDay = day >= 1 && day <= 31;
    const isValidYear = year == currentYear || year == previousYear;

    if (validateDate(inputDate)) {
      if (!isValidMonth) {
        setError((prevError) => ({
            ...prevError,
            [errorName]: "Please enter a valid month",
        }));
      }
      else if (!isValidDay) {
          setError((prevError) => ({
              ...prevError,
              [errorName]: "Please enter a valid date",
          }));
      }
      else if (!isValidYear) {
          setError((prevError) => ({
              ...prevError,
              [errorName]: "Please enter a valid year",
          }));
      }
      else if (parsedDate.isAfter(currentDate)) {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "Date cannot be in the future",
        }));
      } 
      else if (errorName === "startDateError" && compareDate && parsedDate.isAfter(dayjs(compareDate))) {
        setError((prevError) => ({
          ...prevError,
          startDateError: "Start date cannot be after end date",
        }));
      } else if (errorName === "endDateError" && compareDate && parsedDate.isBefore(dayjs(compareDate))) {
        setError((prevError) => ({
          ...prevError,
          endDateError: "End date cannot be before start date",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          [errorName]: "",
        }));
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        [errorName]: "Please enter a date in the format MM-DD-YYYY",
      }));
    }
  };

  const convertToBackendFormat = (date) => {
    if(!date){
      return "";
    }
    const [month, day, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };

  const handleStartChat = async () => {
    if (error.startDateError || error.endDateError) {
      return;
    }
    let formattedStartDate= "";
    let formattedEndDate= "" ;

    if(startDate && endDate){
      formattedStartDate = convertToBackendFormat(startDate);
      formattedEndDate = convertToBackendFormat(endDate);
    }
    dispatch(
      update({
        start_date: formattedStartDate || "",
        end_date: formattedEndDate || "",
        duration: days,
        data_intigration_id: dataSourceIntegrationId,
      })
    );
    dispatch(setDataSourceName("AUTHORIZE_NET"));
    const dataSource = {
      datasource_identifier: "AUTHORIZE_NET",
      start_date: formattedStartDate || "",
      end_date: formattedEndDate || "",
      no_of_days: days,
      data_intigration_id: dataSourceIntegrationId,
    };
    dispatch(setFirstChat(dataSource));
    let response = {
      ...dataSourceDetails,
      start_date: formattedStartDate || "",
      end_date: formattedEndDate || "",
      no_of_days: days,
    };
    dispatch(setAllChat(response));
    try {
      updateChatDate(chatId, days, formattedStartDate,formattedEndDate);
    } catch (e) {}
    // if (code) {
    //   try {
    //     setLoading(true);
    //     const response = await axiosInstance.post(
    //       `${API_URL}/auth-net-oauth/retrieve_auth_net_token/`,
    //       {
    //         code: code,
    //       }
    //     );
    //     if (response?.data?.integration_id) {
    //       const syncResponse = await axiosInstance.get(
    //         `${API_URL}/chat/sync-auth-net-data/`
    //       );
    //       if (syncResponse?.data?.message) {
    //         setDataSourceIntegrationId(response?.data?.integration_id);
    //         setLoading(false);
    //         navigate(`/queries/${dataSourceIntegrationId}`);
    //       }
    //     }
    //   } catch (error) {
    //     setLoading(false);
    //     setIntIdError("Something went wrong please try again later");
    //     console.log(error);
    //   }
    // } else {
    //   setDataSourceIntegrationId(Authorisedotbnet_integration_id);
    //   if (dataSourceIntegrationId) {
    //     navigate(`/queries/${dataSourceIntegrationId}`);
    //   } else {
    //     setIntIdError("Something went wrong please try again later");
    //   }
    // }
    handleClose();
  };

  useEffect(() => {
    if (selectedFilter === "1W") {
      setDays(7);
    }
    if (selectedFilter === "1M") {
      setDays(30);
    }
    if (selectedFilter === "3M") {
      setDays(90);
    }
    if (selectedFilter === "6M") {
      setDays(180);
    }
    if (selectedFilter === "1Y") {
      setDays(365);
    }
    if (selectedFilter === "Custom-Range") {
      setDays("");
    }
  }, [selectedFilter]);
  const handleCloseAuthorizeModal = () => {
    navigate("/dashboard");
  };
  //   const handleCloseError = (event, reason) => {
  //     if (reason === "clickaway") {
  //       return;
  //     }
  //     setIntIdError(null);
  //   };
  useEffect(() => {
    if (intIdError !== null) {
      setTimeout(() => {
        handleCloseAuthorizeModal();
      }, 2000);
    }
  }, [intIdError]);

  return (
    <div className="newUerModalContainer">
      <div className="logoContainerauth">
        <img src={IMGAuthorizeNetLogo} alt="logo" />
      </div>
      <div className="time-range-filters">
        <span>Analyze with Authorize.net</span>
        <label>Select time range for analysis</label>
        <div className="filters">
          {filters.map((filter, index) => (
            <div
              key={index}
              className={`range-Container ${
                filter === "Custom-Range" ? "custom" : ""
              } ${selectedFilter === filter ? "selected" : ""}`}
              onClick={() => handleFilterClick(filter)}
            >
              {filter}
            </div>
          ))}
        </div>
      </div>
      <div className="date-range-filter">
        <div className="filter-dates">
          <p>Start Date </p>
          <TextField
            disabled={selectedFilter !== "Custom-Range"}
            value={startDate}
            placeholder="MM-DD-YYYY"
            inputProps={{ maxLength: 10 }}
            onChange={(e) =>
            handleDateChange(e, setStartDate, "startDateError", endDate)
            }
            // error={startDateError}
            sx={{
              width: "100%",
              borderRadius: "10px",
              marginTop: "10px",

              "& input": {
                "-webkit-appearance": "none",
                "-moz-appearance": "none",
                appearance: "none",
              },
            }}
          />
          {<p className="error">{error?.startDateError}</p>}
        </div>
        <div className="filter-dates-end-date">
          <p>End Date </p>
          <TextField
            // label="YYYY-MM-DD"
            value={endDate}
            disabled={selectedFilter !== "Custom-Range"}
            placeholder="MM-DD-YYYY"
            inputProps={{ maxLength: 10 }}
            onChange={(e) =>
            handleDateChange(e, setEndDate, "endDateError", startDate)
            }
            // error={endDateError}
            sx={{
              width: "100%",
              borderRadius: "10px",
              marginTop: "10px",
              "& input": {
                "-webkit-appearance": "none",
                "-moz-appearance": "none",
                appearance: "none",
              },
            }}
          />
          {<p className="error">{error?.endDateError}</p>}
        </div>
      </div>
      <div className="btn-container">
        <button
          className="start-chat-btn"
          disabled={
            !((selectedFilter && selectedFilter !== "Custom-Range") || intIdError !== null ||
              (selectedFilter === "Custom-Range" && 
                startDate && 
                endDate && 
                !error.startDateError && 
                !error.endDateError
              )
            )
          }
          onClick={handleStartChat}
        >
          Start Chat
        </button>
      </div>
    </div>
  );
};

export default AuthorizeNetFilter;
