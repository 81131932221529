import { useEffect } from "react";

const useOutsideClick = (ref, isVisible, setIsVisible) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isVisible && ref.current && !ref.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, ref, setIsVisible]);
};

export default useOutsideClick;
