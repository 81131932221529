import { useState } from "react";
import axios from "axios";
import { API_URL, SSO_API_URL, STORAGE_KEY, USER_CREDENTIALS } from "../config";
import { useNavigate } from "react-router-dom";
import { dashboard, query } from "../routes/routes";

const useSignIn = () => {
  const [error, setError] = useState();
  const [respData, setRespData] = useState();
  const navigate = useNavigate();
  let cancelTokenSource; // Define cancel token source outside the hook function

  const signIn = async (email, password) => {
    setError("");
    // Cancel the previous request if it exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled");
    }
    cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token source

    try {
      const response = await axios.post(
        // `${API_URL}/auth/user/login/`,
        `${SSO_API_URL}/api/v1/login/`,
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          cancelToken: cancelTokenSource.token, // Pass the cancellation token
        }
      );
      if (response.status !== 200) {
        throw new Error("Invalid credentials");
      }
      const data = response.data;
      // Handle token storage or session management here
      localStorage.setItem(
        STORAGE_KEY.access_token,
        JSON.stringify(data.access_token)
      );
      localStorage.setItem(
        STORAGE_KEY.refresh_token,
        JSON.stringify(data.refresh_token) 
      );
      localStorage.setItem(
        STORAGE_KEY.is_merchant_exist,
        JSON.stringify(data.is_merchant_exist) 
      );
      localStorage.setItem(
        STORAGE_KEY.user_role,
        JSON.stringify(data.role) 
      );
      localStorage.setItem(USER_CREDENTIALS.USER_EMAIL, email);
      navigate("/dashboard");
      return response.status;
    } catch (err) {
      if (!axios.isCancel(err)) {
        // Only set error if the request was not canceled
        setRespData(err.response)
        setError(err.response.data.error || "An error occurred");
      }
    }
  };

  return { signIn, error ,respData };
};

export default useSignIn;
