import React from "react";
import "./forgotPasswordComponent.scss";
import { useState } from "react";
import ForgetEmailComponent from "./forgetEmailComponent";
import { IMGForgotPassword, IMGLogo } from "../images";
import Footer from "../commonComponent/Footer";
import { useNavigate } from "react-router-dom";
import { DOCUMENT_ROOT } from "../config";
import useResetPassword from "../hooks/useResetPassword";
import AuthWrapper from "../authentication/newAuthComponents/authWrapper/AuthWrapper";
import { Alert, Snackbar } from "@mui/material";

const ForgetPasswordComponent = () => {
  const navigate = useNavigate();
  const [showEmailValid, setShowEmailValid] = useState(true);
  // const [showOtpValid, setShowOtpValid] = useState(false);
  const [showConfirmPasswordValid, setShowConfirmPasswordValid] =
    useState(false);
  const [email, setEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const { sendResetPasswordLink } = useResetPassword();

  const validateEmail = () => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValid) {
      return false;
    } else {
      return true;
    }
  };

  const handleResendClick = async () => {
    setLoading(true);
    const isEmailValid = validateEmail();
    if (isCooldown) {
      setOpenSnackbar(true);
      setMsg(
        `Please wait ${timeLeft} seconds to resend the verification email again`
      );
      return; // Prevent further execution if the cooldown is active
    }
    if (isEmailValid && !loading) {
      const response = await sendResetPasswordLink(email);
      if (response) {
        setLoading(false);
        setIsCooldown(true);
        let timer = 30;
        setTimeLeft(timer);

        const intervalId = setInterval(() => {
          timer -= 1;
          setTimeLeft(timer);

          if (timer === 0) {
            setLoading(false);
            clearInterval(intervalId);
            setIsCooldown(false);
          }
        }, 1000);
        setOpenSnackbar(true);
        setMsg("Email sent successfully.");
      } else {
        setLoading(false);
        setOpenSnackbar(true);
        setMsg("Failed to send the email. Please try again.");
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  return (
    <AuthWrapper>
      <div className="reset-password-container">
        {showEmailValid && (
          <div className="forget-email-container">
            <ForgetEmailComponent
              setShowEmailValid={setShowEmailValid}
              setShowConfirmPasswordValid={setShowConfirmPasswordValid}
              setForgetEmail={setEmail}
            />
          </div>
        )}
        {/* {showOtpValid && (
          <ForgetOtpComponent
            setShowOtpValid={setShowOtpValid}
            setShowConfirmPasswordValid={setShowConfirmPasswordValid}
          />
        )} */}
        {/* {showConfirmPasswordValid && <NewPasswordComponent/>} */}
        {showConfirmPasswordValid && (
          <div className="verification-screen-container">
            {/* <div className="email-image-container">
              <img src={IMGForgotPassword} alt="" />
            </div> */}
            <div className="verification-verification-text">
              Verification required
            </div>
            <p className="enter-verification-text">
              We have sent you a link for verification over the mail. In case
              you do not find it, check your spam folder.
            </p>
            <button className="resend-text" onClick={handleResendClick}>
              Resend
              {/* <span
                className="hyperlink-reset-text"
                onClick={handleResendClick}
              >
                Click here to Resend the Email.
              </span> */}
            </button>
          </div>
        )}
        {/* </div> */}
        {/* <Footer /> */}
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={msg === "Email sent successfully." ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
    </AuthWrapper>
  );
};

export default ForgetPasswordComponent;
