import { useState } from "react";
import axios from "axios";
import axiosInstance from "./axiosConfig";
import { API_URL } from "../config";

const useQuery = () => {
  const [promtId, setPromptID] = useState();
  const [filesList, setFilesList] = useState([]);
  const [cancelToken, setCancelToken] = useState(null); // Manage cancel token state
  const [error ,setError] = useState('')
  const createChat = async (title, datasourceId, dateFilterInfo) => {
    try {
      const requestBody = {
        title,
        files: filesList,
        datasource_integration_id: datasourceId,
        no_of_days: dateFilterInfo?.duration === "" ? null : dateFilterInfo?.duration,
        start_date: dateFilterInfo?.start_date,
        end_date: dateFilterInfo?.end_date,
      };
      return await axiosInstance.post(`${API_URL}/chat/create/`, requestBody);
    } catch (error) {
      return "error";
    }
  };

  const getAllChats = async () => {
    try {
      return await axiosInstance.get(`${API_URL}/chat/?limit=1000`);
    } catch (error) {
      return "error";
    }
  };

  const getChatById = async (chatId) => {
    try {
      return await axiosInstance.get(`${API_URL}/chat/${chatId}`);
    } catch (error) {
      return "error";
    }
  };

  const createDialogue = async (chat_id, query) => {
    try {
      const requestBody = {
        chat_id,
        query,
      };
      return await axiosInstance.post(`${API_URL}/chat/dialogue/`, requestBody);
    } catch (error) {
      return "error";
    }
  };

  const getAllDialogues = async (chat_id) => {
    try {
      return await axiosInstance.get(
        `${API_URL}/chat/dialogue/${chat_id}/?limit=50`
      );
    } catch (error) {}
  };

  const getPrompts = async (files_list, datasourceId) => {
    if (cancelToken) {
      // If there's a cancel token, cancel the previous request
      cancelToken.cancel();
    }

    try {
      const source = axios.CancelToken.source(); // Create new CancelToken source
      setCancelToken(source); // Update cancel token state

      setFilesList(files_list);
      const requestBody = {
        prompt_id: promtId,
        files_list,
        // offset: 0,
        limit: 50,
        data_source_integration_id: datasourceId,
      };
      const response = await axiosInstance.post(
        `${API_URL}/chat/prompts/`,
        requestBody,
        { cancelToken: source.token }
      );
      return response;
    } catch (error) {
      console.log(error);
      try {
        if (error.code === "ERR_CANCELED") {
          // Eating up here
        } else {
          return "error";
        }
      } catch {
        return "error";
      }
      
    }
  };

  const editChatDialogue = async (chat_id, query, dialogue_id) =>{
    try{
      const requestBody = {
       chat_id,
       query,
       dialogue_id
      }
      return await axiosInstance.post(`${API_URL}/chat/edit-dialogue/`, requestBody);
    } catch (error){
      console.error("Something went wrong", error)
    }
  }

  return {
    createChat,
    getAllChats,
    getChatById,
    createDialogue,
    getAllDialogues,
    getPrompts,
    editChatDialogue,
    error
  };
};

export default useQuery;
