import axiosInstance from "./axiosConfig";
import { API_URL } from "../config";

const useChatAction = () => {
  const editChatTitle = async (chatId, newTitle) => {
    try {
      return await axiosInstance.post(`${API_URL}/chat/edit-chat-title/`, {
        chat_id: chatId,
        new_title: newTitle,
      });
    } catch (error) {
      throw error;
    }
  };

  const downloadChat = async (chatId) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/chat/download-chat?chat_id=${chatId}`,
        {
          chat_id: chatId,
        }
      );
      const data = response?.data;
      if (response?.status === 200) {
        window.location.href = data?.file_url;
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  const deleteChat = async (chatId) => {
    try {
      return await axiosInstance.post(`${API_URL}/chat/delete-chat/`, {
        chat_ids: chatId,
      });
    } catch (error) {
      throw error;
    }
  };

  const updateChatDate = async (chatId, syncDays, startDate, endDate) => {
    try {
      return await axiosInstance.patch(`${API_URL}/chat/update-chat/`, {
        chat_id: chatId,
        no_of_days: syncDays || null,
        start_date: startDate|| null,
        end_date: endDate|| null,
      });
    } catch (error) {
      throw error;
    }
  };

  const chatHistoryFilter = async (startDate, endDate,selectedFilter,dataSourceIdentifier) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/chat/?limit=20&start_date=${startDate}&end_date=${endDate}&no_of_days=${selectedFilter}&datasource_identifier=${dataSourceIdentifier}`);
      
      return response?.data?.results;
    } catch (error) {
      throw error;
    }
  };


  return {
    editChatTitle,
    downloadChat,
    deleteChat,
    chatHistoryFilter,
    updateChatDate,
  };
};

export default useChatAction;
