import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  start_date: '',
  end_date: '',
  duration: '',
  data_intigration_id:'',
  // data_Source_Name:'',
  // total_chat : [],
  // authorise_net_integration_id :''
};

const dateFilter = createSlice({
  name: 'dateFilterInfo',
  initialState: {
    syncInfo: initialState
  },
  reducers: {
    update: (state,action) => {
      state.syncInfo = action.payload;
    },
    // setDataSourceName:(state,action)=>{
    //   state.syncInfo.data_Source_Name = action.payload;
    // },
    // setTotalChat:(state,action)=>{
    //   state.syncInfo.total_chat = action.payload;
    // },
    // SetAuthorise_net_integration_id:(state,action)=>{
    //   state.syncInfo.authorise_net_integration_id = action.payload;
    // }
  },
});

export const { update
  // ,setDataSourceName,setTotalChat,SetAuthorise_net_integration_id
 } = dateFilter.actions;
export default dateFilter.reducer;