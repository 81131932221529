export function sessionStorageSetItem(key, value) {
    if (typeof(Storage) !== "undefined") {
      sessionStorage.setItem(key, value);
    } else {
      console.log('No sessionStorage support');
    }
  }
  
  export function sessionStorageGetItem(key) {
  
    if (typeof(Storage) !== "undefined") {
      return sessionStorage.getItem(key);
    } else {
      console.log('No sessionStorage support');
    }
  }
  
  export function sessionStorageSetObject(key, value) {
    if (typeof(Storage) !== "undefined") {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      console.log('No sessionStorage support');
    }
  }
  
  export function sessionStorageGetObject(key) {
    if (typeof(Storage) !== "undefined") {
      let value = sessionStorage.getItem(key);
      return (value !== "undefined" ? JSON.parse(value) : null);
    } else {
      console.log('No sessionStorage support');
    }
  }
  
  export function sessionStorageRemoveItem(key) {
    if (typeof(Storage) !== "undefined") {
      sessionStorage.removeItem(key);
    } else {
      console.log('No sessionStorage support');
    }
  }
  
  export function sessionStorageClear(key) {
    if (typeof(Storage) !== "undefined") {
      sessionStorage.clear();
    } else {
      console.log('No sessionStorage support');
    }
  }
  