import React, { useEffect, useRef, useState } from 'react';
import 'echarts-gl';
import 'echarts';
import ReactECharts from 'echarts-for-react';
import * as world from 'echarts-countries-js/world-x.json';

const ChartComponent = ({option}) => {
    const [echartOptions, setEchartsOption] = useState({});

    let echartsRef = useRef();

    useEffect(() => {
        if (echartsRef?.echarts) {
            echartsRef?.echarts.registerMap('world', world);
            setEchartsOption({...option,  color: [ '#DFEFEA','#4E9386' ],});
        }
    }, [echartsRef])
    
    return option ? <ReactECharts option={echartOptions} style={{marginBottom: '20px', background:"#FFF", borderRadius: "9.8px", boxShadow: "0px 3.943px 24.645px 0px rgba(0, 0, 0, 0.10)"}} ref={(e) => { 
        echartsRef = e; 
    }} /> : <></>;
}

export default ChartComponent;
