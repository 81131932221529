import { useState } from "react";
import axios from "axios";
import { API_URL, SSO_API_URL } from "../config";
import { useNavigate } from "react-router";
import { registrationSuccess } from "../routes/routes";

const useSignUp = () => {
  const [error, setError] = useState();
  const [responseData, setResponseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false);
  const navigate = useNavigate();
  let cancelTokenSource;

  const signUp = async (
    name,
    email,
    password,
    confirmPassword,
    isChecked = false
  ) => {
    const accept_terms = isChecked;
    const confirm_password = confirmPassword;
    setError("");

    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled");
    }
    cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token source

    try {
      const response = await axios.post(
        // `${API_URL}/auth/user/register/`,
        `${SSO_API_URL}/api/v1/register/`,
        {
          name,
          confirm_password,
          password,
          email,
          accept_terms,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          cancelToken: cancelTokenSource.token, // Pass the cancellation token
        }
      );

      if (response.status !== 201) {
        throw new Error("Signup failed");
      }
      const data = response?.data;
      setResponseData(response)
      console.log("Signup successful:", data);
      navigate(`${registrationSuccess}?email=${encodeURIComponent(email)}`);
    } catch (resp) {
      try {
        if (resp.response.data["non_field_errors"]) {
          setError(resp.response.data["non_field_errors"][0]);
        } else if (resp.response.data["error"]) {
          setError(resp.response.data["error"]);
        } else {
          setError("Some error occured!");
        }
      } catch {
        setError("Some error occured!");
      }
    }
  };

  const resentAccountActivationEmail = async (email) => {
    try {
      const response = await axios.post(
        `${SSO_API_URL}/api/v1/resend-verification-email/`,
        {
          email,
        }
      );
      if (response.status !== 201) {
        setError("Resend verification email failed")
        throw new Error("Resend verification email failed");
      }
      setResponseData(response)
      setIsVerificationEmailSent(true);
      return response ;
    } catch (err) {
      setError(err.response.data.error);
     return err;
      
    }
  };

  return {
    signUp,
    resentAccountActivationEmail,
    error,
    responseData,
    isVerificationEmailSent,
  };
};

export default useSignUp;
