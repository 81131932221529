import React, { useEffect, useRef, useState } from "react";
import "./chatHistory.scss";
import moment from "moment";
import { Alert, Snackbar } from "@mui/material";
import {
  IMGAuthorizeNetMenuIcon,
  IMGChatHistoryFileIcon,
  IMGEnabledDeleteIcon,
  IMGEnabledDownloadIcon,
  IMGFilterIcon,
  IMGGorillaMenuIcon,
  IMGGreenDeleteIcon,
  IMGGreenDownloadIcon,
  IMGGreyDeleteIcon,
  IMGGreyDownloadIcon,
  IMGSearchIcon,
  IMGGreenShareIcon,
  IMGThreeDotIcon,
  ImgWooSm,
  IMGGreenFilterIcon,
  IMGGorillaIconWithoutSpace,
} from "../images";
import useOutsideClick from "../hooks/useOutsideClick";
import ChatHistoryFilterComponent from "./chatHistoryFilterComponent";
import useChatAction from "../hooks/useChatAction";
import { useDispatch, useSelector } from "react-redux";
import { updateChatTitle } from "../utils/dataSourceSlice";
import { ShimmerTable } from "react-shimmer-effects";
import { chatHistoryFilterTourScreen, chatHistoryOptionsTourScreen, PRODUCT_TOUR_KEY } from "../config";
import DashboardTour from "../dashboard/dashboardTour";

const INITIAL_LIMIT = 15; // Number of items to show initially
const LOAD_MORE_LIMIT = 15;

const ChatHistory = ({isChatsDropdownOpen , setIsChatsDropdownOpen,currentStepForChatHistory,setCurrentStepForChatHistory,steps}) => {
  const [checked, setChecked] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [chatFile, setChatFile] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [listData, setListData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const isAnyCheckboxChecked = (listData || []).some((file) => file?.isChecked);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(listData);
  const [indexToDelete, setIndexToDelete] = useState(null);
  const [filterApplied, setFilterApplied] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dataSourceIdentifier, setDataSourceIdentifier] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [successSnackbarMsg, setSuccessSnakbarMsg] = useState("");
  const [errorSnackbarMsg, setErrorSnackbarMsg] = useState("");
  const [snackbarSuccessSeverity, setSnackbarSuccessSeverity] =
    useState("success");
  const [snackbarErrorSeverity, setSnackbarErrorSeverity] = useState("error");
  const [displayedData, setDisplayedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { deleteChat, downloadChat } = useChatAction();
  const selectFilesRef = useRef(null);
  const optionRef = useRef(null);
  const filterRef = useRef(null);
  const chatList = useSelector((state) => state.dataSourceSlice.total_chat);
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    setListData(chatList || []);
    if(chatList?.length === 0){
      setIsChatsDropdownOpen(false);
    }
  }, [chatList,isChatsDropdownOpen]);

  useOutsideClick(optionRef, showOption, setShowOption);
  useOutsideClick(filterRef, filterOpen, setFilterOpen);

  useEffect(() => {
    setDisplayedData(listData);
    if (listData?.length > 0) {
      if (searchTerm) {
        const filteredChats = listData.filter((item) =>
          item?.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filteredChats);
        setDisplayedData(filteredChats.slice(0, INITIAL_LIMIT));
      } else {
        setFilteredData(listData);
        setDisplayedData(listData.slice(0, INITIAL_LIMIT));
        setIsLoading(false);
      }
    }
  }, [searchTerm, listData]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleInfiniteScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleInfiniteScroll);
      }
    };
  },[filteredData, displayedData])

  const handleInfiniteScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollContainer;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      moreItemsOnScroll();
    }
  };

  const moreItemsOnScroll = () => {
    const currentLength = displayedData?.length;
    const moreData = filteredData.slice(
      currentLength,
      currentLength + LOAD_MORE_LIMIT
    );
    if (moreData.length > 0) {
      setDisplayedData((prevItems) => [...prevItems, ...moreData]);
    }
  };

  const handleApplyFilter = (
    filter,
    option,
    startDate,
    endDate,
    dataSourceIdentifier
  ) => {
    setSelectedFilter(filter);
    setSelectedOption(option);
    setStartDate(startDate);
    setEndDate(endDate);
    setDataSourceIdentifier(dataSourceIdentifier);
  };

  const handleResetState = () => {
    setSelectedFilter("");
    setSelectedOption("");
    setStartDate("");
    setEndDate("");
    setDataSourceIdentifier("");
    setFilterApplied(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setListData((prevListData) =>
      prevListData.map((item) => ({
        ...item,
        isChecked: event.target.checked,
      }))
    );
  };

  const handleCheckboxChange = (index) => {
    setListData((prevListData) => {
      const updatedList = [...prevListData];
      const searchTermList = searchTerm ? displayedData : updatedList;
      
      if (searchTermList?.length > 0 && searchTermList[index]) {
        searchTermList[index] = {
          ...searchTermList[index],
          isChecked: !searchTermList[index]?.isChecked,
        };
      }
      const allChecked = searchTermList?.length > 0 && searchTermList.every((file) => file?.isChecked);
      setChecked(allChecked);
      return searchTerm ? displayedData : updatedList;
    });
  };

  const handleFileChange = (e) => {
    const { chatList } = e.target;
    setSelectedFile([...selectedFile, ...chatList]);
    setChatFile(true);
  };

  const handleToggleOption = (index) => {
    setShowOption((prevOptions) => ({
      ...prevOptions,
      [index]: !prevOptions[index],
    }));
  };

  const getSuperScriptIcon = (item) => {
    try {
      if (item?.datasource_identifier) {
        switch (item?.datasource_identifier) {
          case "GORILLAPAY":
            return (
              <img
                src={IMGGorillaIconWithoutSpace}
                style={{
                  marginRight: "6px",
                  marginLeft: "4px",
                }}
                alt=""
              />
            );
          case "AUTHORIZE_NET":
            return (
              <img
                src={IMGAuthorizeNetMenuIcon}
                style={{ top: "6px", left: "3px" }}
                alt=""
              />
            );
          case "WOOCOMMERCE":
            return (
              <img
                src={ImgWooSm}
                style={{
                  top: "14px",
                  height: "19px",
                  width: "28px",
                  left: "5px",
                }}
                alt=""
              />
            );
          default:
            return "";
        }
      } else {
        return (
          <img
            src={IMGChatHistoryFileIcon}
            style={{ top: "6px", left: "3px" }}
            alt=""
          />
        );
      }
    } catch {}
  };

  // const handleDownloadChat = async (chatId) => {
  //   if (!isAnyCheckboxChecked) {
  //     showSnackbar("Please select at least one checkbox.", "error");
  //     return;
  //   }
  //   try {
  //     const response = await downloadChat(chatId);
  //     showSnackbar("Chat downloaded successfully!", "success");
  //   } catch (error) {
  //     showSnackbar("Unexpected error occured. Please try again after sometime.", "error");
  //   }
  // };

  const showSuccessSnackbar = (message, severity) => {
    setSuccessSnakbarMsg(message);
    setSnackbarSuccessSeverity(severity);
    setOpenSuccessSnackbar(true);
  };

  const showErrorSnackbar = (message, severity) => {
    setErrorSnackbarMsg(message);
    setSnackbarErrorSeverity(severity);
    setOpenErrorSnackbar(true);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessSnackbar(false);
  };

  const handleDeleteClick = () => {
    if (isAnyCheckboxChecked) {
      setShowConfirmationDialog(true);
    } else {
      showErrorSnackbar("Please select at least one checkbox.", "error");
    }
  };

  const performDeletion = async (chatIds) => {
    try {
      await deleteChat(chatIds);
      const updatedChatList = chatList.filter(
        (item) => !chatIds.includes(item?.id)
      );
      dispatch(updateChatTitle(updatedChatList));
      setListData((prevListData) =>
        prevListData.filter((chats) => !chatIds.includes(chats?.id))
      );
      setDisplayedData((prevDisplayedData) =>
        prevDisplayedData.filter((chats) => !chatIds.includes(chats?.id))  //Removing search results from displayedData
      );
      showSuccessSnackbar("Deleted successfully!", "success");
    } catch (error) {
      showErrorSnackbar(
        "Unexpected error occurred. Please try again after sometime.",
        "error"
      );
    } finally {
      setShowConfirmationDialog(false);
      setIndexToDelete(null);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const chatIdsToDelete =
        indexToDelete !== null
          ? [listData[indexToDelete]?.id]
          : searchTerm  // Check if a search term exists
            ? displayedData  // If there's a search term, delete only the filtered results (displayedData)
                .filter((chats) => chats?.isChecked)
                .map((chats) => chats?.id)
            : listData  // If no search term, delete all chats in listData
                .filter((chats) => chats?.isChecked)
                .map((chats) => chats?.id);
      await performDeletion(chatIdsToDelete);
    } catch (error) {
      showErrorSnackbar(
        "Unexpected error occurred. Please try again after sometime.",
        "error"
      );
    }
  };

  const highlightText = (text, searchTerm) => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    let parts = text.split(regex);

    return parts.map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <strong key={i} style={{ color: "black" }}>
          {part}
        </strong>
      ) : (
        part
      )
    );
  };

  return (
    <>
      <div className="chat-history-container">
        <div className="chat-history-header">Chat History</div>
        <div className="chat-history-item-container">
          <div className="chat-icon-container">
            <div className="search-container">
              <img className="search-icon" src={IMGSearchIcon} alt="" />
              <input
                className="searchbar"
                type="text"
                placeholder="Search for data sources..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <img
                className="filter-icon"
                src={filterApplied ? IMGGreenFilterIcon : IMGFilterIcon}
                alt=""
                onClick={() => setFilterOpen(true)}
              />
              {filterOpen && (
                <>
                <ChatHistoryFilterComponent
                  filterRef={filterRef}
                  setListData={setListData}
                  setFilterOpen={setFilterOpen}
                  setFilterApplied={setFilterApplied}
                  selectedFilter={selectedFilter}
                  selectedOption={selectedOption}
                  startDate={startDate}
                  endDate={endDate}
                  dataSourceIdentifier={dataSourceIdentifier}
                  onApplyFilter={handleApplyFilter}
                  onReset={handleResetState}
                />
                {(currentStepForChatHistory === 1) && (
                  <div ref={filterRef} style={{ position: 'absolute', left: '700px' }}>
                    <DashboardTour
                      currentStepInfo={chatHistoryFilterTourScreen}
                      currentStepIndex={currentStepForChatHistory}
                      setCurrentStepIndex={setCurrentStepForChatHistory}
                      steps={steps}
                      flowName={PRODUCT_TOUR_KEY.show_all_chat_tour}
                    />
                  </div>
                )}
                </>
              )}
            </div>
            <div className="download-delete-iconcontainer">
              {/* <img
                className="grey-download-icon"
                src={
                  isAnyCheckboxChecked
                    ? IMGEnabledDownloadIcon
                    : IMGGreyDownloadIcon
                }
                alt=""
              />
              <input
                type="file"
                ref={selectFilesRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              /> */}
              <img
                className="grey-delete-icon"
                src={
                  isAnyCheckboxChecked
                    ? IMGEnabledDeleteIcon
                    : IMGGreyDeleteIcon
                }
                alt=""
                onClick={handleDeleteClick}
              />
              <input
                type="file"
                ref={selectFilesRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className="overall-select-container ">
            <div className="select-text-container">
              <input
                className="select-all-input-box"
                type="checkbox"
                checked={checked}
                onChange={(event) => handleChange(event, chatList)}
              />
              <div className="select-all-text"> Select All</div>
            </div>
          </div>
          <div className="chat-info-container">
            <div
              ref={scrollContainerRef}
              className={`chat-item-container ${
                isLoading ? "no-scrollbar" : ""
              }`}
            >
              {isLoading ? (
                <div style={{ paddingLeft: "5px" }}>
                <ShimmerTable row={7} col={1} />
                </div>
              ) : (
                <>
                  {displayedData &&
                    Array.isArray(displayedData) &&
                    displayedData?.length > 0 &&
                    displayedData.map((item, index) => (
                      <div className="chat-items" key={index}>
                        <div className="chatname-input-container">
                          <input
                            type="checkbox"
                            checked={item?.isChecked}
                            onChange={() => handleCheckboxChange(index)}
                          />
                          {getSuperScriptIcon(item)}
                          <span
                            style={{ paddingLeft: "10px", color: "#6B6B6B" }}
                          >
                            {highlightText(
                              item?.title.length > 30
                                ? `${item?.title.slice(0, 30)}...`
                                : item?.title,
                              searchTerm
                            )}
                          </span>
                        </div>
                        <div className="date-container">
                          <span style={{ color: "#95B4AF" }}>created on </span>
                          <span style={{ color: "#438074", marginLeft: "5px" }}>
                            {moment(item?.creation_date).format("DD-MM-YYYY")}
                          </span>
                          <div className="three-dot-image">
                            <img
                              src={IMGThreeDotIcon}
                              style={{ cursor: "pointer" }}
                              alt=""
                              onClick={() => handleToggleOption(index)}
                            />
                            <div style={{ position: "relative" }}>
                              {showOption?.[index] && (
                                <div
                                  ref={optionRef}
                                  className="option-container"
                                >
                                  {/* <div
                                  onClick={() => {
                                    setShowOption(false);
                                  }}
                                  className="options"
                                >
                                  <img src={IMGGreenShareIcon} alt="" /> Share
                                  Chat
                                </div> */}
                                  {/* <div
                                  onClick={() => {
                                    setShowOption(false);
                                    handleDownloadChat([item?.id]);
                                  }}
                                  className="options"
                                >
                                  <img src={IMGGreenDownloadIcon} alt="" />{" "}
                                  Download Chat
                                </div> */}
                                  <div
                                    onClick={() => {
                                      setIndexToDelete(index);
                                      setShowConfirmationDialog(true);
                                    }}
                                    onChange={handleFileChange}
                                    ref={selectFilesRef}
                                    className="options"
                                  >
                                    <img src={IMGGreenDeleteIcon} alt="" />{" "}
                                    Delete Chat
                                  </div>
                                  {
                                    currentStepForChatHistory=== 0 && (
                                      <div ref={optionRef} style={{position:'absolute',right: '195px' ,bottom:'175px'}}>
                                        <DashboardTour
                                        currentStepInfo={chatHistoryOptionsTourScreen}
                                        currentStepIndex={currentStepForChatHistory}
                                        setCurrentStepIndex={setCurrentStepForChatHistory}
                                        steps={steps}
                                        flowName={PRODUCT_TOUR_KEY.show_all_chat_tour}
                                        />
                                      </div>
                                    )
                                  }
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
        {showConfirmationDialog && (
          <div className="confirm-delete-popup">
            <div className="confirm-delete-dialog">
              <div className="confirm-delete-text">
                Are you sure you want to delete this chat?
              </div>
              <div className="confirm-delete-buttons">
                <button
                  className="cancel-btn btn"
                  onClick={() => setShowConfirmationDialog(false)}
                >
                  Cancel
                </button>
                <button className="yes-btn btn" onClick={handleConfirmDelete}>
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity={snackbarErrorSeverity}
          variant="filled"
          sx={{
            width: "100%",
            bgcolor: "#FFECEF",
            color: "#3B3939",
            "& .MuiAlert-icon": {
              color: "#CA6069",
            },
            fontFamily: "Chivo",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {errorSnackbarMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSuccessSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity={snackbarSuccessSeverity}
          variant="filled"
          sx={{
            width: "100%",
            bgcolor: "#D4ECDA",
            color: "#3B3939",
            "& .MuiAlert-icon": {
              color: "#4A998A",
            },
            fontFamily: "Chivo",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          {successSnackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
};
export default ChatHistory;
