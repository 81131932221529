import React, { useState } from "react";
import StoreUrlComponent from "./storeUrlComponent";
import "./connectWithComponent.scss";
import DateTimeSelectComponent from "./dateTimeSelectComponent";

const ConnectWithComponent = ({setIsWooCommerseInActive}) => {
  const [showUrlComponent, setShowUrlComponent] = useState(true);
  const [showDateTime, setShowDateTime] = useState(false);
  return (
    <div className="connect-with-container">
      {showUrlComponent && (
        <StoreUrlComponent
          setShowUrlComponent={setShowUrlComponent}
          setShowDateTime={setShowDateTime}
          setIsWooCommerseInActive={setIsWooCommerseInActive}
        />
      )}
      {showDateTime && <DateTimeSelectComponent/>}
    </div>
  );
};

export default ConnectWithComponent;
