import React from "react";
import "./newPasswordComponent.scss";
import { useState } from "react";
import { IMGForgotPassword, IMGLogo, IMGWarning } from "../images";
import { useNavigate, useParams } from "react-router-dom";
import useResetPassword from "../hooks/useResetPassword";
import { DOCUMENT_ROOT } from "../config";
import Footer from "../commonComponent/Footer";
import AuthWrapper from "../authentication/newAuthComponents/authWrapper/AuthWrapper";
import { TextField } from "@mui/material";
import { IMGCrossedEye, IMGErrorIcon, IMGEye, IMGShowpwd } from '../images';
// import { IMGforgetpaswword } from "../images";
// import Header from "../landingPage/Header";
// import Footer from "../landingPage/Footer";
const eyeWithoutErrorStyle = {
  float: "right",
  position: "relative",
  marginTop: "-55px",
  marginRight: "20px",
  cursor: "pointer",
}
const NewPasswordComponent = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordError, setShowPasswordError] = useState("");
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState("");
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const { resetPassword, error } = useResetPassword();
  const { token } = useParams();
  const navigate = useNavigate();

  const validatePassword = () => {
    const minLength = 6;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    if (password.length < minLength) {
      return false;
    } else if (!uppercaseRegex.test(password)) {
      return false;
    } else if (!lowercaseRegex.test(password)) {
      return false;
    } else if (!numberRegex.test(password)) {
      return false;
    } else if (!specialCharRegex.test(password)) {
      return false;
    }
    return true;
  };

  const validateConfirmPassword = () => {
    if (password === confirmPassword) {
      return true;
      // console.log("true");
    } else {
      return false;
      // console.log('false');
    }
  };

  const handleSetPassword = async () => {
    const isPasswordValid = validatePassword(password);
    const isConfirmPasswordValid = validateConfirmPassword();
    console.log(isConfirmPasswordValid);
    if (!isPasswordValid) {
      setShowPasswordError("Invalid Password");
    } else {
      setShowPasswordError("");
    }
    if (!isConfirmPasswordValid) {
      setShowConfirmPasswordError("Invalid Confirm Password");
    } else {
      setShowConfirmPasswordError("");
    }
    if (isPasswordValid && isConfirmPasswordValid) {
      const response = await resetPassword(password, confirmPassword, token);
      if (response) {
        setIsPasswordReset(true);
      }
    }
  };
  const handleEyeClick = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmEyeClick = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
    return (
    <AuthWrapper>
      <div className="forget-password-overall-form-container">
        {/* <Header/> */}

        <div className="forget-password-container">
          {!isPasswordReset && (
            <div className="forget-password-form-container">
              <div className="reset-password-form">
                <div className="overall-form-container">
                  <div className="form-title">Reset Password</div>
                  {/* <div className="password-txt">Password</div>
                  <input
                    type="password"
                    className="forget-password-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder=""
                  /> */}
                  <TextField
                  type={showPassword ? 'password' : 'text'}
                    name="Password"
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder=""
                    // InputLabelProps={{ shrink: true }}
                    // className="forget-password-email-input"
                    variant="outlined"
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: showPasswordError ==='' ? 'inherit' : 'red',
                          borderWidth: showPasswordError ==='' ? '1px' : '2px', 
                        },
                        '&:hover fieldset': {
                          borderColor: showPasswordError ==='' ? 'inherit' : 'red',
                          borderWidth: showPasswordError ==='' ? '1px' : '2px', 
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: showPasswordError ==='' ? '#4E9386' : 'red',
                          borderWidth: '2px',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: showPasswordError ==='' ? 'inherit' : 'red',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: showPasswordError ==='' ? '#4E9386' : 'red',
                      },
                    }}
                  />
                  <img
                    src={showPassword ? IMGEye : IMGShowpwd}
                    style={
                      eyeWithoutErrorStyle 
                    }
                    onClick={handleEyeClick}
                  />
                  {showPasswordError && (
                    <div className="error-text">
                      <img src={IMGWarning} style={{ marginRight: "4px" }} />
                      {showPasswordError}
                    </div>
                  )}
                  {/* <div className="password-txt">Confirm Password</div>
                  <input
                    type="password"
                    className="confirm-forget-password-input"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder=""
                  /> */}
                  <TextField
                    type={showConfirmPassword ? 'password' : 'text'}
                    name="Confirm Password"
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder=""
                    variant="outlined"
                    fullWidth  
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: showConfirmPasswordError==='' ? 'inherit' : 'red',
                          borderWidth: showConfirmPasswordError==='' ? '1px' : '2px', 
                        },
                        '&:hover fieldset': {
                          borderColor: showConfirmPasswordError==='' ? 'inherit' : 'red',
                          borderWidth: showConfirmPasswordError==='' ? '1px' : '2px', 
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: showConfirmPasswordError==='' ? '#4E9386' : 'red',
                          borderWidth: '2px',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: showConfirmPasswordError==='' ? 'inherit' : 'red',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: showConfirmPasswordError==='' ? '#4E9386' : 'red',
                      },
                    }}     
                  />
                   <img
                    src={showConfirmPassword ? IMGEye : IMGShowpwd}
                    style={
                      eyeWithoutErrorStyle 
                    }
                    onClick={handleConfirmEyeClick}
                  />
                  {showConfirmPasswordError && (
                    <div className="error-text">
                      <img src={IMGWarning} style={{ marginRight: "4px" }} />
                      {showConfirmPasswordError}
                    </div>
                  )}
                  <div className="continue-button-container">
                    <button
                      className="continue-button"
                      onClick={handleSetPassword}
                      disabled= {(password === '' || confirmPassword ==='')}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isPasswordReset && (
            <div className="reset-password-form" style={{ width: "392px" }}>
              <p className="success-text">Password Reset Successful</p>
              <p className="sucess-sub-text">
                You have been logged out from all the devices. Now you can use
                your new password to login.
              </p>
              <button
                className="login-text"
                onClick={() => navigate(DOCUMENT_ROOT)}
              >
                Login
              </button>
            </div>
          )}
        </div>
        {/* <Footer /> */}
      </div>
    </AuthWrapper>
  );
};

export default NewPasswordComponent;
